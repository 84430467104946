import {useCallback, useState} from 'react';
import {AuthBox} from '../../../../../../components/auth';
import {Button} from '../../../../../../components/button';
import {Input} from '../../../../../../components/form';
import {OnboardingCompany} from '../../../../../../types';

type Props = {
  onSkip: () => void;
  onVerify: (url: string) => Promise<OnboardingCompany | undefined>;
  unverified: boolean;
};

export const ProvideCompanyUrl = ({onSkip, onVerify, unverified}: Props) => {
  const [url, setUrl] = useState('');
  const [lastVerifiedUrl, setLastVerifiedUrl] = useState<string | undefined>(undefined);
  const onVerifyCompany = useCallback(() => {
    setLastVerifiedUrl(url);
    onVerify(url);
  }, [url, onVerify]);

  const isInvalid = unverified && lastVerifiedUrl === url;

  return (
    <AuthBox headerTitle="Verify Your Company">
      <p className="text-left" data-intercom-target="company linkedin label">
        Company LinkedIn
      </p>
      <Input
        placeholder="Enter Your Company LinkedIn URL"
        onChange={e => setUrl(e.target.value)}
        isInvalid={isInvalid}
      />
      {isInvalid && (
        <p
          className="-mt-1 mb-2 text-left text-sm text-red-500"
          data-intercom-target="magical onboarding error"
        >
          We couldn't verify your company. Check if your link is correct.
        </p>
      )}
      <div className="flex flex-col gap-2">
        <Button fullWidth variant="primary" onClick={onVerifyCompany}>
          Verify
        </Button>
        <Button fullWidth onClick={onSkip} variant="secondary" disableTitleCase>
          Skip for now
        </Button>
      </div>
    </AuthBox>
  );
};

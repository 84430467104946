import {Dialog} from '@headlessui/react';
import {useCallback, useEffect, useState} from 'react';
import {useMutationStartWorkOverlapsImportTask} from '../../../api/imports';
import {useUserSessionContext} from '../../../contexts';
import {LoadingSpinner} from '../../animations';
import {notify} from '../../notifications';
import {ImportConnectionsSuccess} from '../success/ImportConnectionsSuccess';
import {ImportConnectionsWorkOverlapsForm} from './ImportConnectionsWorkOverlapsForm';
import {useBlockOverlaps} from './useBlockOverlaps';

enum ImportWorkOverlapsViews {
  Waiting,
  Form,
  Success,
}

type Props = {
  onClose: () => unknown;
};

export const ImportConnectionsWorkOverlaps = ({onClose}: Props) => {
  const {
    user: {linkedInUrl},
    refetch: refetchUserSession,
  } = useUserSessionContext(); // TODO: use useCurrentUser after refactoring in useCurrentUserSession hook

  const {blockOverlaps} = useBlockOverlaps();

  const [currentView, setCurrentView] = useState<ImportWorkOverlapsViews>(
    linkedInUrl ? ImportWorkOverlapsViews.Waiting : ImportWorkOverlapsViews.Form
  );

  useEffect(() => {
    if (linkedInUrl) {
      setCurrentView(ImportWorkOverlapsViews.Waiting);
    }
  }, [linkedInUrl]);

  const handleSuccess = useCallback(() => {
    setCurrentView(ImportWorkOverlapsViews.Success);
    blockOverlaps();
  }, [blockOverlaps]);

  const {mutate: initializeTask} = useMutationStartWorkOverlapsImportTask({
    onSuccess: handleSuccess,
    onError: () => {
      notify('Something went wrong. Please try again later.');
      onClose();
    },
  });

  switch (currentView) {
    case ImportWorkOverlapsViews.Form:
      return <ImportConnectionsWorkOverlapsForm onClose={onClose} onSuccess={refetchUserSession} />;
    case ImportWorkOverlapsViews.Success:
      return <ImportConnectionsSuccess onClose={onClose} />;
    case ImportWorkOverlapsViews.Waiting:
      return <WaitingScreen handleInitialize={initializeTask} />;
    default:
      ((_: never) => {})(currentView);
      return null;
  }
};

const WaitingScreen = ({handleInitialize}: {handleInitialize: () => unknown}) => {
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    if (!initialized) {
      handleInitialize();
      setInitialized(true);
    }
  }, [initialized, handleInitialize]);

  return (
    <>
      <Dialog.Title as="h3" className="text-3xl font-bold text-swarm-black">
        We're looking for your work overlaps
      </Dialog.Title>
      <Dialog.Description className="mb-4 mt-2 font-normal">Processing... please wait</Dialog.Description>
      <div className="my-8 grid justify-items-center">
        <LoadingSpinner size="large" />
      </div>
    </>
  );
};

import {PipelineStatus, Profile, ProfileInPipelinesSuggestion} from '../types';

export const getProfileFullName = (profileInfo?: Profile['profile_info']): string =>
  profileInfo ? profileInfo.full_name || `${profileInfo.first_name} ${profileInfo.last_name}`.trim() : '';

type CreateProfileInPipelineSuggestionStubType = {
  pipelineId: string;
  pipelineTitle?: string;
  creator: {
    id: string;
    firstName: string;
    lastName: string;
  };
  teamId: string;
};
export const createProfileInPipelineSuggestion = ({
  creator,
  pipelineId,
  pipelineTitle,
  teamId,
}: CreateProfileInPipelineSuggestionStubType): ProfileInPipelinesSuggestion => {
  return {
    id: pipelineId,
    title: pipelineTitle || '',
    status: PipelineStatus.suggested,
    private: false,
    containProfile: true,
    suggestions: [
      {
        creatorId: creator.id,
        creator: {
          id: creator.id,
          firstName: creator.firstName,
          lastName: creator.lastName,
          email: '',
        },
      },
    ],
    teamId,
  };
};

import {ReactNode} from 'react';
import Styles from './PolymorphicFilterRules.module.scss';
import {PolymorphicFilterProps} from './types';

type Props<ValueType> = {
  rulesConfiguration: PolymorphicFilterProps<ValueType>['fieldKeysRules'];
  onChangeRule: (ruleKey: string) => void;
  activeRule?: string;
};
export const PolymorphicFilterRules = function <ValueType>({
  rulesConfiguration,
  onChangeRule,
  activeRule,
}: Props<ValueType>) {
  const rules = Object.values(rulesConfiguration);
  return (
    <div className={Styles.wrapper}>
      {rules.map(({key, label}) => (
        <button key={key} onClick={() => onChangeRule(key)}>
          <input type="radio" checked={activeRule === key} readOnly className="mr-2" />
          <Label>{label}</Label>
        </button>
      ))}
    </div>
  );
};

const Label = ({children}: {children: ReactNode}) => (
  <span className="grow text-left leading-snug">{children}</span>
);

import {useCallback, useEffect} from 'react';
import {Table, UseSwarmTableProps} from '../../../components/table';
import {useProfilesTable} from '../../../components/table/useProfilesTable';
import {MyConnectionTableFilter, PipelineStatus, Profile} from '../../../types';
import {useProfilesReducer} from '../../my-connections/useProfilesReducer.hook';

type Props = {
  tableFilters: MyConnectionTableFilter;
  pagination: Required<UseSwarmTableProps>['pagination'];
  sorting: Required<UseSwarmTableProps>['sorting'];
  openIntroModal: (profileId: string) => void;
  pipelineId: string;
  pipelineProfiles: Profile[];
  visibleColumnsState: UseSwarmTableProps['visibleColumnsState'];
  columnsOrder: UseSwarmTableProps['columnsOrder'];
  availableColumns: UseSwarmTableProps['availableColumns'];
  refetchProfilesToCount: () => void;
};

export const ProfilesTable = ({
  tableFilters,
  pagination,
  sorting,
  openIntroModal,
  pipelineId,
  pipelineProfiles,
  visibleColumnsState,
  columnsOrder,
  availableColumns,
  refetchProfilesToCount,
}: Props) => {
  const {profiles, reload, assignTag, unassignTag, statusChange} = useProfilesReducer(pipelineProfiles);

  useEffect(() => {
    reload(pipelineProfiles);
  }, [pipelineProfiles, reload]);

  const handleStatusChange = useCallback(
    (profileIds: string[], pipelineId: string, status: PipelineStatus) => {
      statusChange(profileIds, pipelineId, status);
      refetchProfilesToCount();
    },
    [refetchProfilesToCount, statusChange]
  );

  const table = useProfilesTable({
    rows: profiles,
    isLoaded: true,
    teamGraph: true,
    searchQuery: '',
    tableFilters,
    pagination,
    sorting,
    onAssignTag: assignTag,
    onUnassignTag: unassignTag,
    openIntroModal,
    pipelineId,
    onStatusChange: handleStatusChange,
    visibleColumnsState,
    columnsOrder,
    availableColumns,
  });

  return (
    <div>
      <Table<Profile>
        headerGroups={table.getHeaderGroups()}
        rowModel={table.getRowModel()}
        totalWidth={table.getTotalSize()}
        rowKeyPath="original.profile_info.id"
      />
    </div>
  );
};

import {TruncateWithTooltip} from '../../truncate-with-tooltip';
import {Avatar} from '../avatar';
import Styles from './AvatarWithFullname.module.scss';

type Props = {
  fullname: string;
  avatarImage?: string;
};

export const AvatarWithFullname = ({fullname, avatarImage}: Props) => {
  return (
    <span className={Styles.avatarWithFullname}>
      <Avatar fullname={fullname} avatarImage={avatarImage} />
      <span className={Styles.fullname}>
        <TruncateWithTooltip content={fullname}>{fullname}</TruncateWithTooltip>
      </span>
    </span>
  );
};

import {useCallback} from 'react';
import {FiUserPlus as AddPersonIcon} from 'react-icons/fi';
import {useNavigate} from 'react-router-dom';
import {Button} from '../../../components/button';
import {useImportConnectionsModal} from '../../../contexts';
import {getConfig, segmentTrack} from '../../../utils';

const {grantAccessEnabled} = getConfig();

type HeaderButtonsProps = {
  teamGraph?: boolean;
  hasConnections: boolean;
};
export const HeaderButtons = ({teamGraph, hasConnections}: HeaderButtonsProps) => {
  const navigate = useNavigate();
  const {showImportModal} = useImportConnectionsModal();

  const handleManageAccessClick = useCallback(() => {
    segmentTrack('Button Clicked', {
      label: 'manage access',
      location: 'my connections',
    });
    navigate({pathname: '/grant-access'});
  }, [navigate]);

  const handleAddConnectionsClick = useCallback(() => {
    showImportModal();

    const screenName = teamGraph ? 'network graph' : 'my connections';

    segmentTrack('Button Clicked', {
      label: 'add connections',
      location: `${screenName} top bar`,
    });
  }, [showImportModal, teamGraph]);

  return (
    <>
      {grantAccessEnabled && !teamGraph && hasConnections && (
        <Button onClick={handleManageAccessClick} variant="secondary" data-intercom-target="Manage access">
          Grant access
        </Button>
      )}
      <Button
        icon={<AddPersonIcon />}
        onClick={handleAddConnectionsClick}
        variant="primary"
        data-intercom-target="Add connections"
      >
        Add connections
      </Button>
    </>
  );
};

import {useCallback, useEffect} from 'react';
import {FiUsers as UsersIcon} from 'react-icons/fi';
import {useMutationUpdateOnboardingStatus} from '../../../../api';
import {AuthBox} from '../../../../components/auth';
import {AuthBoxIcon} from '../../../../components/auth/auth-box/AuthBoxIcon';
import {Button} from '../../../../components/button';
import {useOnboardingContext} from '../../../../contexts';
import {segmentVirtualPageview} from '../../../../utils';

import {InviteMembersManuallyForm} from './InviteMembersManuallyForm';

const HeaderImage = () => (
  <AuthBoxIcon>
    <UsersIcon size={32} />
  </AuthBoxIcon>
);

export const InviteMembersManually = () => {
  const {onFinishStep} = useOnboardingContext();
  useEffect(() => {
    segmentVirtualPageview('/invite-members-manually');
  }, []);

  const mutationUpdateOnboardingStatus = useMutationUpdateOnboardingStatus({
    onSuccess: onFinishStep,
  });
  const onSkip = useCallback(
    (callback?: () => void) => {
      mutationUpdateOnboardingStatus.mutate(
        {hasSkippedCreatingSharedNetwork: true},
        {...(callback ? {onSuccess: callback} : {})}
      );
    },
    [mutationUpdateOnboardingStatus]
  );

  const onFormSuccess = useCallback(() => {
    onSkip(onFinishStep);
  }, [onSkip, onFinishStep]);

  return (
    <AuthBox headerImage={<HeaderImage />} headerTitle="Create your shared network">
      <InviteMembersManuallyForm onSuccess={onFormSuccess} />
      <Button
        variant="tertiary"
        size="lg"
        fullWidth
        onClick={() => onSkip()}
        loading={mutationUpdateOnboardingStatus.isLoading}
        className="mt-2"
        tracking={{
          label: 'skip',
          location: 'invite members manually',
        }}
      >
        <span data-intercom-target="onboarding: skip invites" className="flex">
          <span className="mr-1 font-semibold">Skip </span>{' '}
          <span className="font-normal">(not recommended)</span>
        </span>
      </Button>
    </AuthBox>
  );
};

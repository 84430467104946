import {useCallback} from 'react';
import {Helmet} from 'react-helmet';
import {FiChevronLeft as BackIcon} from 'react-icons/fi';
import {useNavigate} from 'react-router-dom';
import {Button} from '../../components/button';
import {ManageConnectionsAccess} from '../../components/manage-connections-access';
import {PageHeader} from '../../components/page-header';
import {AppLayout} from '../../layouts';

export const ManageAccessPage = () => {
  const navigate = useNavigate();

  const handleGoBack = useCallback(() => navigate({pathname: '/my-connections'}), [navigate]);

  return (
    <>
      <Helmet>
        <title>Grant Access - The Swarm</title>
      </Helmet>
      <AppLayout
        header={
          <PageHeader rightSlot={<Button onClick={handleGoBack}>Cancel</Button>}>
            <Button onClick={handleGoBack} icon={<BackIcon />} variant="tertiary" iconOnly>
              Go back
            </Button>
            <h2 className="ml-2 flex items-center text-2xl font-bold">Grant Access</h2>
          </PageHeader>
        }
      >
        <div className="mx-auto mt-10 w-[750px]">
          <ManageConnectionsAccess goBack={handleGoBack} />
        </div>
      </AppLayout>
    </>
  );
};

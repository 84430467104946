import {useSortConnections} from '../../hooks';
import {BffProfilesRowConnection, ScoreValue} from '../../types';
import {getUserFullName} from '../../utils';
import {AvatarWithFullname} from '../avatar';
import {Tooltip} from '../tooltip';
import {Score} from './Score';
import Styles from './Score.module.scss';

type MultiScoreProps = {
  connections?: BffProfilesRowConnection[];
  className?: string;
  limit?: number;
};

export const BffMultiScore = ({connections = [], className, limit}: MultiScoreProps) => {
  const sortedConnections = useSortConnections(connections);
  const connectionsCount = sortedConnections.length;

  if (connectionsCount === 0) {
    return (
      <div className={className}>
        <Score />
      </div>
    );
  }

  const scores = sortedConnections.map(connection => connection.strength);
  const highestScore = scores.length ? (Math.max(...scores) as ScoreValue) : undefined;
  const limitOverflow = limit ? connectionsCount - limit : 0;
  const detailsConnections = sortedConnections.slice(0, limitOverflow ? limit : connectionsCount);

  return (
    <Tooltip
      content={<BffMultiScoreDetails connections={detailsConnections} limitOverflow={limitOverflow} />}
    >
      <div className={className}>
        <Score value={highestScore} />
      </div>
    </Tooltip>
  );
};

type BffMultiScoreDetailsProps = {
  connections: BffProfilesRowConnection[];
  limitOverflow: number;
};
const BffMultiScoreDetails = ({connections, limitOverflow}: BffMultiScoreDetailsProps) => {
  return (
    <div className={Styles.scoreDetailsWrapper}>
      {connections.map(
        connection =>
          connection.user.id && (
            <div className={Styles.scoreDetails} key={connection.user.id}>
              <AvatarWithFullname fullname={getUserFullName(connection.user)} />
              <div className="w-24">
                <Score value={connection.strength} showLabel />
              </div>
            </div>
          )
      )}
      {limitOverflow > 0 && (
        <span className={Styles.moreConnections}>
          +{limitOverflow} more {limitOverflow > 1 ? 'connections' : 'connection'}
        </span>
      )}
    </div>
  );
};

import {useCallback, useEffect, useMemo} from 'react';
import {useQueryProfiles} from '../../../api';
import {BulkAddToParticularPipelineButton} from '../../../components/add-to-pipeline';
import {LoadingSpinner} from '../../../components/animations';
import {NoResults} from '../../../components/no-results';
import {Table, UseSwarmTableProps} from '../../../components/table';
import {prepareQueryProfilesInput} from '../../../components/table/prepareProfilesQueryInput';
import {useProfilesTable} from '../../../components/table/useProfilesTable';
import {useCurrentTeam} from '../../../hooks';
import {MyConnectionTableFilter, Profile} from '../../../types';
import {pluralizedConnection} from '../../../utils';
import {useProfilesReducer} from '../../my-connections/useProfilesReducer.hook';

type Props = {
  tableFilters: MyConnectionTableFilter;
  pagination: Required<UseSwarmTableProps>['pagination'];
  sorting: Required<UseSwarmTableProps>['sorting'];
  openIntroModal: (profileId: string) => void;
  pipelineId: string;
  pipelineName: string;
  visibleColumnsState: UseSwarmTableProps['visibleColumnsState'];
  columnsOrder: UseSwarmTableProps['columnsOrder'];
  availableColumns: UseSwarmTableProps['availableColumns'];
};

export const SearchedProfiles = ({
  tableFilters,
  pagination,
  sorting,
  openIntroModal,
  pipelineId,
  pipelineName,
  visibleColumnsState,
  columnsOrder,
  availableColumns,
}: Props) => {
  const {id: teamId} = useCurrentTeam();
  const {paginationState, totalCount, setMeta} = pagination;
  const {sortingState} = sorting;
  const getProfilesInput = prepareQueryProfilesInput({
    teamGraph: true,
    filters: tableFilters.filters,
    ...paginationState,
    sorting: sortingState,
  });

  const {
    data: connections,
    isFetchedAfterMount,
    isLoading,
  } = useQueryProfiles(teamId, getProfilesInput, {
    keepPreviousData: true,
    onSuccess: ({meta}) => setMeta(meta),
    refetchOnWindowFocus: false, // TODO: remove this when we have a better default sorting for the received data
  });

  const {profiles, reload, assignTag, unassignTag} = useProfilesReducer(connections?.data || []);

  useEffect(() => {
    const connectionsData = connections?.data || [];
    reload(connectionsData);
  }, [connections?.data, reload]);

  const removeFromList = useCallback(
    (profileIds: string[]) =>
      reload(profiles.filter(profile => !profileIds.includes(profile.profile_info.id))),
    [profiles, reload]
  );

  const table = useProfilesTable({
    rows: profiles,
    isLoaded: isFetchedAfterMount,
    teamGraph: true,
    searchQuery: '',
    tableFilters,
    pagination,
    sorting,
    onAddToPipeline: profileIds => removeFromList(profileIds),
    onAssignTag: assignTag,
    onUnassignTag: unassignTag,
    openIntroModal,
    pipelineId: pipelineId,
    visibleColumnsState,
    columnsOrder,
    availableColumns,
  });

  const selectedRows = table.getSelectedRowModel().rows;
  const selectedProfileIDs = useMemo(
    () => selectedRows.map(row => row.original.profile_info.id),
    [selectedRows]
  );
  const selectedProfilesCount = selectedProfileIDs.length;
  const shouldShowBulkActions = selectedProfilesCount > 0;

  const selectedRowsInfo = useMemo(
    () => `${selectedProfilesCount} ${pluralizedConnection(selectedProfilesCount)} selected`,
    [selectedProfilesCount]
  );

  if (isLoading) {
    return <LoadingSpinner size="small" color="black" centered />;
  }

  return (
    <div>
      <Table<Profile>
        headerGroups={table.getHeaderGroups()}
        rowModel={table.getRowModel()}
        totalWidth={table.getTotalSize()}
        bulkActionsSlot={
          shouldShowBulkActions && (
            <>
              <BulkAddToParticularPipelineButton
                profileIDs={selectedProfileIDs}
                pipelineId={pipelineId}
                pipelineName={pipelineName}
                onSuggestionsChangeSuccess={() => {
                  removeFromList(selectedProfileIDs);
                  table.toggleAllRowsSelected(false);
                }}
              />
              <span>{selectedRowsInfo}</span>
            </>
          )
        }
        rowKeyPath="original.profile_info.id"
      />

      {isFetchedAfterMount && totalCount === 0 && (
        <NoResults
          heading="No more profiles matching your search."
          subheading="All fitting connections have been added to list."
          page="results"
        ></NoResults>
      )}
    </div>
  );
};

import {BriefcaseMetal as CompanyIcon} from 'phosphor-react';
import {ReactNode, useCallback, useEffect, useMemo, useState} from 'react';
import {FiUsers as ConnectionsIcon} from 'react-icons/fi';
import {useQueryCrmIntegrations, useQueryTagsSimple} from '../../api';
import {
  FiltersWrapper,
  getCrmSourceOptions,
  isFilterEmpty,
  PolymorphicMultipleOptionsFilter,
  ProfileSearchMultipleOptionsFilter,
  TokenizedListFilter,
} from '../../components/filters';
import {PermissionChecker} from '../../components/permission';
import {PremiumFeatureWrapper} from '../../components/premium-feature-wrapper';
import {Score} from '../../components/score';
import {EmptyTagsListInfo, Tag} from '../../components/tags';
import {useCheckPermission, useCurrentUser, usePermissionWithReason} from '../../hooks';
import {
  FilterOptionsItem,
  MyConnectionsFilter,
  MyConnectionsFilterKeys,
  MyConnectionTableFilter,
  Permission,
  TagSimple,
} from '../../types';

type Props = {
  teamGraph: boolean;
  tableFilters: MyConnectionTableFilter;
  noClear?: boolean;
};

const getTagsOptions = (filterOptions: TagSimple[]): FilterOptionsItem[] => {
  return filterOptions.map(option => ({
    value: option.id,
    label: option.name,
    enhancedLabel: <Tag content={option.name} color={option.color} />,
  }));
};

export const Filters = ({teamGraph, tableFilters, noClear}: Props) => {
  const [isProfileTagsFilterOpen, setIsProfileTagsFilterOpen] = useState(false);
  const {filters, hasFiltersSet, clearFilter, clearFilters, setFilter, getFilterValue} = tableFilters;
  const {id: currentUserId} = useCurrentUser();
  const companyPermissionWithReason = usePermissionWithReason(Permission.Company);
  const shouldShowCompanyLevelFilters =
    teamGraph && (companyPermissionWithReason.hasPermission || companyPermissionWithReason.reason === 'plan');
  const hasCrmPermission = useCheckPermission(Permission.CRM);

  const {data: tags, refetch: refetchTags} = useQueryTagsSimple();
  const {data: crms} = useQueryCrmIntegrations({enabled: hasCrmPermission});

  const crmSourceOptions = useMemo(() => getCrmSourceOptions(crms?.integrations || []), [crms?.integrations]);

  useEffect(() => {
    if (isProfileTagsFilterOpen) {
      refetchTags();
    }
  }, [isProfileTagsFilterOpen, refetchTags]);

  const onChangeFilter = useCallback(
    <Key extends MyConnectionsFilterKeys>(filterName: Key) =>
      (filterValue: MyConnectionsFilter[Key]) => {
        if (isFilterEmpty(filterValue)) {
          clearFilter(filterName);
        } else {
          setFilter(filterName, filterValue);
        }
      },
    [setFilter, clearFilter]
  );

  const filterProps = <Key extends MyConnectionsFilterKeys>(fieldKey: Key) => ({
    fieldKey: fieldKey,
    onChange: onChangeFilter(fieldKey),
    value: getFilterValue(fieldKey),
    teamGraph: teamGraph,
    filters: filters,
  });

  const decorateConnectedTo = useCallback(
    (options: FilterOptionsItem[]) =>
      options.reduce((acc: FilterOptionsItem[], item: FilterOptionsItem) => {
        if (item.value === currentUserId) {
          return [{...item, label: `${item.label} (you)`}, ...acc];
        }
        return [...acc, item];
      }, [] satisfies FilterOptionsItem[]),
    [currentUserId]
  );

  const tagsOptions = useMemo(() => getTagsOptions(tags?.items || []), [tags?.items]);

  return (
    <>
      <FiltersWrapper
        // "Clear Filters" button added to the first filters row only on My Connections page
        {...(!shouldShowCompanyLevelFilters && {
          clearFilters,
          hasFiltersSet,
        })}
        label={
          teamGraph ? (
            <>
              <ConnectionsIcon /> Connections:
            </>
          ) : (
            <>Filter by:</>
          )
        }
      >
        <PolymorphicMultipleOptionsFilter
          fieldKeysRules={{
            [MyConnectionsFilterKeys.CurrentJobTitle]: {
              key: MyConnectionsFilterKeys.CurrentJobTitle,
              label: 'Current',
              value: getFilterValue(MyConnectionsFilterKeys.CurrentJobTitle),
              onChange: onChangeFilter(MyConnectionsFilterKeys.CurrentJobTitle),
            },
            [MyConnectionsFilterKeys.RecentJobTitle]: {
              key: MyConnectionsFilterKeys.RecentJobTitle,
              label: 'Recent',
              value: getFilterValue(MyConnectionsFilterKeys.RecentJobTitle),
              onChange: onChangeFilter(MyConnectionsFilterKeys.RecentJobTitle),
              hideOccurrences: true,
            },
            [MyConnectionsFilterKeys.AllTimeJobTitle]: {
              key: MyConnectionsFilterKeys.AllTimeJobTitle,
              label: 'All time',
              value: getFilterValue(MyConnectionsFilterKeys.AllTimeJobTitle),
              onChange: onChangeFilter(MyConnectionsFilterKeys.AllTimeJobTitle),
              hideOccurrences: true,
            },
          }}
          title="Title"
          searchable
          searchPlaceholder='ex: Chief, "Head of", Rev, etc.'
          teamGraph={teamGraph}
          exactMatchFiltering
          filters={filters}
        />
        {!teamGraph && (
          <ProfileSearchMultipleOptionsFilter
            title="Company"
            searchable
            exactMatchAutocompletionsSearch
            exactMatchFiltering
            {...filterProps(MyConnectionsFilterKeys.CurrentJobCompanyName)}
          />
        )}
        <ProfileSearchMultipleOptionsFilter
          title="Location"
          searchable
          exactMatchAutocompletionsSearch
          exactMatchFiltering
          {...filterProps(MyConnectionsFilterKeys.Location)}
        />
        {teamGraph && (
          <>
            <ProfileSearchMultipleOptionsFilter
              title="Connected to"
              alphabeticalOrder
              clientSideSearchable
              decorateOptions={decorateConnectedTo}
              {...filterProps(MyConnectionsFilterKeys.ConnectedTo)}
            />
            <PremiumFeatureWrapper
              permission={Permission.Tag}
              featureName="Tags feature"
              fallbackProps={{disabled: true}}
              tooltipPlacement="bottom-center"
              location="tags filter"
            >
              <ProfileSearchMultipleOptionsFilter
                title="Profile Tags"
                {...filterProps(MyConnectionsFilterKeys.ProfileTags)}
                clientSideSearchable
                directItems={tagsOptions}
                emptyOptionsFallback={<EmptyTagsListInfo />}
                externalIsOpen={isProfileTagsFilterOpen}
                setExternalIsOpen={setIsProfileTagsFilterOpen}
              />
            </PremiumFeatureWrapper>
          </>
        )}
        <PremiumFeatureWrapper
          permission={Permission.ConnectionStrength}
          featureName="Connection Strength"
          fallbackProps={{disabled: true}}
          tooltipPlacement="bottom-center"
          location="connection score filter"
        >
          <ProfileSearchMultipleOptionsFilter
            title="Strength"
            {...filterProps(MyConnectionsFilterKeys.ConnectionsStrength)}
            labelsMapping={{
              '0': <Score value={0} showLabel />,
              '1': <Score value={1} showLabel />,
              '2': <Score value={2} showLabel />,
              '3': <Score value={3} showLabel />,
            }}
            forceOrder={['3', '2', '1', '0']}
          />
        </PremiumFeatureWrapper>
        {!teamGraph && (
          <ProfileSearchMultipleOptionsFilter
            title="Source"
            labelsMapping={{
              plugin: 'LinkedIn Connections',
              csv: 'LinkedIn Connections (CSV)',
              google: 'Google Contacts',
              'google-calendar': 'Google Calendar',
              overlaps: 'Work Overlaps',
            }}
            hideOccurrences
            {...filterProps(MyConnectionsFilterKeys.ConnectionsSource)}
          />
        )}
        <PermissionChecker permission={Permission.CRM}>
          <ProfileSearchMultipleOptionsFilter
            title="CRM Source"
            {...filterProps(MyConnectionsFilterKeys.CRMSource)}
            directItems={crmSourceOptions}
            emptyOptionsFallback={
              <div className="flex flex-col items-center justify-center gap-2 py-4">
                No integrations found
              </div>
            }
          />
        </PermissionChecker>
      </FiltersWrapper>
      {shouldShowCompanyLevelFilters && (
        <FiltersWrapper
          {...(!noClear && {clearFilters: clearFilters})}
          hasFiltersSet={hasFiltersSet}
          label={
            <>
              <CompanyIcon /> Companies:
            </>
          }
          className="pt-0"
        >
          <CompanyLevelPaywall>
            <ProfileSearchMultipleOptionsFilter
              title="Size"
              labelsMapping={{
                '1-10': '1 - 10',
                '11-50': '11 - 50',
                '51-200': '51 - 200',
                '201-500': '201 - 500',
                '501-1000': '501 - 1,000',
                '1001-5000': '1,001 - 5,000',
                '5001-10000': '5,001 - 10,000',
                '10001+': '10,000+',
              }}
              forceOrder={[
                '1-10',
                '11-50',
                '51-200',
                '201-500',
                '501-1000',
                '1001-5000',
                '5001-10000',
                '10001+',
              ]}
              {...filterProps(MyConnectionsFilterKeys.CurrentJobCompanySize)}
            />
          </CompanyLevelPaywall>
          <CompanyLevelPaywall>
            <ProfileSearchMultipleOptionsFilter
              title="Industry"
              searchable
              {...filterProps(MyConnectionsFilterKeys.CurrentJobCompanyIndustry)}
            />
          </CompanyLevelPaywall>
          <CompanyLevelPaywall>
            <ProfileSearchMultipleOptionsFilter
              title="Headquarters"
              searchable
              exactMatchAutocompletionsSearch
              exactMatchFiltering
              {...filterProps(MyConnectionsFilterKeys.CurrentJobCompanyLocationName)}
            />
          </CompanyLevelPaywall>
          <CompanyLevelPaywall>
            <ProfileSearchMultipleOptionsFilter
              title="Name"
              exactMatchAutocompletionsSearch
              exactMatchFiltering
              searchable
              {...filterProps(MyConnectionsFilterKeys.CurrentJobCompanyName)}
            />
          </CompanyLevelPaywall>
          <CompanyLevelPaywall>
            <TokenizedListFilter
              title="List"
              modalTitle="Company list"
              modalDescription="Enter a list of companies to find connections currently working there."
              promptMessage="Enter company names below (separated by commas or line breaks)"
              exactMatchDescription="Look for names that may include additional words such as Capital, Corp, or Inc."
              tokensLabel="Recognized company names"
              minItemLength={2}
              {...filterProps(MyConnectionsFilterKeys.CompanyList)}
            />
          </CompanyLevelPaywall>
        </FiltersWrapper>
      )}
    </>
  );
};

const CompanyLevelPaywall = ({children}: {children: ReactNode}) => (
  <PremiumFeatureWrapper
    featureName="company-level filters"
    permission={Permission.Company}
    fallbackProps={{disabled: true}}
    tooltipPlacement="bottom-center"
    location="connection company filters"
  >
    {children}
  </PremiumFeatureWrapper>
);

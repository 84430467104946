import {AuthBox} from '../../../../../../components/auth';
import {Button} from '../../../../../../components/button';

type Props = {
  onSkip: () => void;
  onConfirm: () => void;
};

export const AIMapperEnable = ({onSkip, onConfirm}: Props) => {
  return (
    <AuthBox headerImage={<img className="h-[283px] w-full" src="/images/ai-network-mapper.svg" />}>
      <h2 className="py-2 text-3xl font-bold" data-intercom-target="ai network mapper header">
        Enable Advanced <span className="relative inline-block whitespace-nowrap">AI Network Mapper</span>
      </h2>
      <p className="p-4 text-swarm-gray-700">
        Our system will automatically map your professional network and organisation based on work history,
        education, investments, and public data.
      </p>
      <div className="flex flex-col gap-2">
        <Button fullWidth variant="primary" onClick={onConfirm}>
          Enable
        </Button>
        <Button fullWidth onClick={onSkip} disableTitleCase>
          Skip for now
        </Button>
      </div>
    </AuthBox>
  );
};

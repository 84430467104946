import cs from 'classnames';
import {LottieAnimation} from '../lottie-animation';
import enrichmentProcessing from './enrichmentProcessing.json';

type Props = {
  className?: string;
};

export const EnrichmentProcessing = ({className}: Props) => (
  <LottieAnimation animationData={enrichmentProcessing} className={cs(className)} />
);

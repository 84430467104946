import {useCallback, useMemo} from 'react';
import {BulkAddToPipelineButton} from '../../components/add-to-pipeline';
import {BulkCrmExportMenu} from '../../components/crm-export-menu';
import {PermissionChecker} from '../../components/permission';
import {PremiumFeatureClickWrapper} from '../../components/premium-feature-wrapper';
import {BulkScoreDropdown} from '../../components/score';
import {Table} from '../../components/table';
import {BulkProfilesTags} from '../../components/tags';
import {Permission, ProfilesListRow} from '../../types';
import {getConfig, pluralizedCompany} from '../../utils';
import {type UseProfilesTableResult} from './useProfilesTable';

const {crmProfileExportEnabled} = getConfig();

type Props = {
  teamGraph: boolean;
  tableData: UseProfilesTableResult;
  onChangeSuccess: () => void;
};

export const ProfilesListTable = ({teamGraph, tableData, onChangeSuccess}: Props) => {
  const selectedRows = tableData.getSelectedRowModel().rows;
  const selectedProfiles = useMemo(() => selectedRows.map(row => row.original), [selectedRows]);
  const selectedProfileIds = useMemo(() => selectedProfiles.map(profile => profile.id), [selectedProfiles]);
  const selectedProfilesCount = selectedProfileIds.length;
  const shouldShowBulkActions = selectedProfilesCount > 0;

  const onBulkActionSuccess = useCallback(() => {
    onChangeSuccess();
    tableData.toggleAllRowsSelected(false);
  }, [onChangeSuccess, tableData]);

  const selectedRowsInfo = useMemo(
    () => `${selectedProfilesCount} ${pluralizedCompany(selectedProfilesCount)} selected`,
    [selectedProfilesCount]
  );

  return (
    <Table<ProfilesListRow>
      headerGroups={tableData.getHeaderGroups()}
      rowModel={tableData.getRowModel()}
      totalWidth={tableData.getTotalSize()}
      rowKeyPath="original.id"
      bulkActionsSlot={
        shouldShowBulkActions && (
          <>
            <BulkAddToPipelineButton
              profileIDs={selectedProfileIds}
              onAddToPipelineSuccess={onBulkActionSuccess}
              onCreatePipelineSuccess={onBulkActionSuccess}
            />
            {!teamGraph && (
              <PermissionChecker permission={Permission.ConnectionStrength}>
                <BulkScoreDropdown
                  profileIds={selectedProfileIds}
                  onScoreChangeSuccess={onBulkActionSuccess}
                />
              </PermissionChecker>
            )}
            {teamGraph && (
              <BulkProfilesTags
                selectedProfiles={selectedProfiles}
                onAssignSuccess={onBulkActionSuccess}
                onUnassignSuccess={onBulkActionSuccess}
              />
            )}
            {crmProfileExportEnabled && (
              <PremiumFeatureClickWrapper permission={Permission.CRMExport}>
                <BulkCrmExportMenu
                  targetIds={selectedProfileIds}
                  targetType="profile"
                  onSuccess={onBulkActionSuccess}
                />
              </PremiumFeatureClickWrapper>
            )}
            <span>{selectedRowsInfo}</span>
          </>
        )
      }
    />
  );
};

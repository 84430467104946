import {AuthBox, AuthBoxIcon} from '../../../../../components/auth';
import {Button} from '../../../../../components/button';
import {LinkedinIcon} from '../../../../../components/icons';
import {PiAddressBook as ConnectiosIcon} from 'react-icons/pi';
import {FiRefreshCw as SyncIcon} from 'react-icons/fi';
import {useEffect} from 'react';
import {getExtensionVersion} from '../../../../../utils/extensionCommunication';

type Props = {
  onSkip: () => void;
  onInstall: () => void;
  setExtensionVersion: (version: string) => void;
};

export const InitialScreen = ({onSkip, onInstall, setExtensionVersion}: Props) => {
  useEffect(() => {
    const extCheckInterval = setInterval(() => {
      getExtensionVersion().then(version => {
        setExtensionVersion(version);
      });
    }, 2000);

    return () => clearInterval(extCheckInterval);
  }, [setExtensionVersion]);

  return (
    <AuthBox
      headerImage={<HeaderImage />}
      headerTitle={
        <>
          Add your LinkedIn
          <br />
          Connections
        </>
      }
    >
      <div className="mb-4 flex gap-4">
        <div className="flex w-6 justify-center">
          <ConnectiosIcon size={28} className="-mt-0.5" />
        </div>
        <div className="text-start">
          <p className="mb-1 text-lg font-semibold">Connections will be synced automatically</p>
          <p className="text-base text-swarm-gray-700">
            Install our browser extension and log in to your Linkedin account in a separate tab.
          </p>
        </div>
      </div>
      <div className="mb-8 flex gap-4">
        <div className="flex w-6 justify-center">
          <SyncIcon size={20} />
        </div>
        <div className="text-start">
          <p className="mb-1 text-lg font-semibold">Ongoing sync with enrichment process</p>
          <p className="text-base text-swarm-gray-700">We will add all your 1st degree connections.</p>
        </div>
      </div>
      <Button
        variant="primary"
        fullWidth
        onClick={onInstall}
        tracking={{
          label: 'install extension',
          location: 'onboarding',
        }}
        className="mb-2"
      >
        Install extension
      </Button>
      <Button
        variant="tertiary"
        fullWidth
        onClick={onSkip}
        tracking={{
          label: 'skip install extension',
          location: 'onboarding',
        }}
      >
        Skip for now
      </Button>
    </AuthBox>
  );
};

const HeaderImage = () => (
  <AuthBoxIcon>
    <LinkedinIcon className="w-8" />
  </AuthBoxIcon>
);

import {ColumnDef} from '@tanstack/react-table';
import {useCallback, useMemo} from 'react';
import {Button} from '../../components/button';
import {NoResults} from '../../components/no-results';
import {notify} from '../../components/notifications';
import {Table, TableInnerHeader, useSwarmTable, useTablePagination} from '../../components/table';
import {useCheckPermission, useConfirm, useTeamMembers} from '../../hooks';
import {Permission} from '../../types';
import {humanizeDate} from '../../utils';
import {AddMembersButton} from './AddMembersButton';
import {RolesMap} from './constants';
import {MembersListItem, MembersListItemStatus} from './types';

type Props = {
  members: MembersListItem[];
};

export const InvitedMembersList = ({members}: Props) => {
  const {refetchInvites} = useTeamMembers();
  const invites = members.filter(member => member.status === MembersListItemStatus.Invited);

  const shouldDisplayOptions = useCheckPermission(Permission.TeamMemberUpdate);

  const pagination = useTablePagination();

  const columns = useMemo<ColumnDef<MembersListItem>[]>(
    () => [
      {
        accessorKey: 'email',
        header: () => <TableInnerHeader label="Email" />,
        cell: ({row}) => row.original.email,
        size: 300,
        meta: {
          truncate: true,
        },
      },
      {
        accessorKey: 'inviteSentDate',
        header: () => <TableInnerHeader label="Invite sent date" />,
        cell: ({row}) => humanizeDate(row.original.inviteSentDate || ''),
        size: 200,
        meta: {
          truncate: true,
        },
      },
      {
        accessorKey: 'role',
        header: () => <TableInnerHeader label="Role" />,
        cell: ({row}) => (
          <span data-intercom-target={`role ${RolesMap[row.original.role]}`}>
            {RolesMap[row.original.role]}
          </span>
        ),
        size: 140,
      },
      ...(shouldDisplayOptions
        ? ([
            {
              accessorKey: 'actions',
              header: () => <TableInnerHeader label="Actions" hideLabel />,
              cell: ({row}) => <InvitationActions invite={row.original} />,
              size: 200,
              enableResizing: false,
            },
          ] satisfies ColumnDef<MembersListItem>[])
        : []),
    ],
    [shouldDisplayOptions]
  );

  const table = useSwarmTable<MembersListItem>({
    uniqueName: 'invitedMembers',
    rows: invites,
    columns,
    defaultColumnSize: 250,
    defaultColumnMinSize: 50,
    isLoaded: true,
    pagination,
  });

  if (!invites.length) {
    return (
      <NoResults page="pipeline" heading="No pending invites." subheading="Add members to grow your Swarm.">
        <AddMembersButton onSuccess={refetchInvites} />
      </NoResults>
    );
  }

  return (
    <Table
      headerGroups={table.getHeaderGroups()}
      rowModel={table.getRowModel()}
      totalWidth={table.getTotalSize()}
      cellHeight={60}
    />
  );
};

type InvitationActionsProps = {
  invite: MembersListItem;
};
const InvitationActions = ({invite}: InvitationActionsProps) => {
  const {isConfirmed} = useConfirm();
  const {deleteInvitation, resendInvitation} = useTeamMembers();

  // Revoke invite button
  const onClickRevoke = useCallback(async () => {
    const agreed = await isConfirmed('Are you sure you want to revoke this invitation?');
    if (agreed) {
      deleteInvitation(invite.id).then(() => {
        notify(`Invite to ${invite.email} has been revoked.`);
      });
    }
  }, [isConfirmed, deleteInvitation, invite.id, invite.email]);

  const onClickResend = useCallback(() => {
    resendInvitation(invite.id).then(() => {
      notify(`Invite to ${invite.email} has been resent.`);
    });
  }, [resendInvitation, invite.id, invite.email]);

  return (
    <div className="gap-2">
      <Button size="xs" variant="tertiary" outline onClick={onClickResend} tracking={{label: 'resend'}}>
        Resend
      </Button>
      <Button size="xs" variant="tertiary" outline onClick={onClickRevoke} tracking={{label: 'revoke'}}>
        Revoke
      </Button>
    </div>
  );
};

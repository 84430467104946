import cs from 'classnames';
import {Mention, MentionsInput, SuggestionDataItem} from 'react-mentions';
import {ValidationProps} from '../types';
import {Message} from '../utils';
import {mentionStyle, mentionsWrapperStyle} from './styles';

type Props = ValidationProps & {
  value?: string;
  onChange: (value: string) => void;
  placeholder?: string;
  autocompletions: string[];
  rows?: number;
  singleLine?: boolean;
  className?: string;
};

const getAutocompletions = (keys: string[]): SuggestionDataItem[] =>
  keys.map(key => ({
    id: key,
    display: key,
  }));

export const TemplateEditor = ({
  onChange,
  value,
  placeholder,
  autocompletions,
  rows,
  isInvalid, // TODO: red border when isInvalid
  message,
  singleLine,
  className,
}: Props) => {
  return (
    <>
      <MentionsInput
        value={value}
        onChange={e => onChange(e.target.value)}
        style={mentionsWrapperStyle}
        placeholder={placeholder}
        rows={rows}
        singleLine={singleLine}
        className={className}
      >
        <Mention
          trigger="/"
          markup="{__display__}"
          data={getAutocompletions(autocompletions)}
          renderSuggestion={(suggestion, search, highlightedDisplay, index, focused) => (
            <div className={cs({['focused']: focused})}>{highlightedDisplay}</div>
          )}
          style={mentionStyle}
          displayTransform={(id, display) => `{${display}}`}
        />
      </MentionsInput>
      {message && <Message message={message} hasError={isInvalid} />}
    </>
  );
};

import {uniqBy} from 'lodash';
import {useCallback, useReducer} from 'react';
import {useCurrentTeam, useCurrentUser} from '../../hooks';
import {PipelineStatus, Profile, ScoreValue, TagSimple} from '../../types';
import {createProfileInPipelineSuggestion} from '../../utils';

type ProfileAction =
  | {type: 'RELOAD'; payload: Profile[]}
  | {type: 'UPDATE_SCORE'; payload: {id: string; score: ScoreValue}[]}
  | {type: 'ASSIGN_TAG'; payload: {id: string; tag: TagSimple}[]}
  | {type: 'UNASSIGN_TAG'; payload: {id: string; tagId: TagSimple['id']}[]}
  | {type: 'ADD_TO_PIPELINE'; payload: {id: string; pipelineId: string; pipelineTitle?: string}[]}
  | {type: 'STATUS_CHANGE'; payload: {id: string; pipelineId: string; status: PipelineStatus}[]};

export const useProfilesReducer = (initialData: Profile[]) => {
  const {id: currentUserId, firstName, lastName} = useCurrentUser();
  const {id: teamId} = useCurrentTeam();

  const profilesReducer = (state: Profile[], action: ProfileAction): Profile[] => {
    switch (action.type) {
      case 'RELOAD':
        return action.payload;
      case 'UPDATE_SCORE':
        return state.map(profile => {
          const newScore = action.payload.find(p => p.id === profile.profile_info.id)?.score;

          if (newScore === undefined) {
            return profile;
          }

          return {
            ...profile,
            connections: profile.connections.map(connection =>
              connection.user.id === currentUserId ? {...connection, strength: newScore} : connection
            ),
          };
        });
      case 'ASSIGN_TAG':
        return state.map(profile => {
          const newTag = action.payload.find(p => p.id === profile.profile_info.id)?.tag;

          if (!newTag) {
            return profile;
          }

          return {
            ...profile,
            tags: uniqBy([...profile.tags, newTag], 'id'),
          };
        });
      case 'UNASSIGN_TAG':
        return state.map(profile => {
          const tagId = action.payload.find(p => p.id === profile.profile_info.id)?.tagId;

          if (!tagId) {
            return profile;
          }

          return {
            ...profile,
            tags: profile.tags.filter(tag => tag.id !== tagId),
          };
        });
      case 'ADD_TO_PIPELINE':
        return state.map(profile => {
          const newSuggestion = action.payload.find(p => p.id === profile.profile_info.id);

          if (!newSuggestion) {
            return profile;
          }

          const pipelineToUpdate = profile.pipelines.find(
            pipeline => pipeline.id === newSuggestion.pipelineId
          );

          return {
            ...profile,
            pipelines: pipelineToUpdate
              ? profile.pipelines.map(pipeline => {
                  if (pipeline.id === newSuggestion.pipelineId) {
                    const newSuggestions = uniqBy(
                      [
                        ...pipeline.suggestions,
                        {
                          creatorId: currentUserId,
                          creator: {id: currentUserId, firstName, lastName, email: ''},
                        },
                      ],
                      'creatorId'
                    );

                    return {
                      ...pipeline,
                      containProfile: true,
                      status: pipeline.status || PipelineStatus.suggested,
                      suggestions: newSuggestions,
                    };
                  }
                  return pipeline;
                })
              : [
                  ...profile.pipelines,
                  createProfileInPipelineSuggestion({
                    creator: {id: currentUserId, firstName, lastName},
                    pipelineId: newSuggestion.pipelineId,
                    pipelineTitle: newSuggestion.pipelineTitle,
                    teamId,
                  }),
                ],
          };
        });
      case 'STATUS_CHANGE':
        return state.map(profile => {
          const statusChange = action.payload.find(p => p.id === profile.profile_info.id);

          if (!statusChange) {
            return profile;
          }

          return {
            ...profile,
            pipelines: profile.pipelines.map(pipeline => {
              if (pipeline.id === statusChange.pipelineId) {
                return {
                  ...pipeline,
                  status: statusChange.status,
                };
              }
              return pipeline;
            }),
          };
        });
      default:
        throw new Error('Invalid action type');
    }
  };

  const [state, dispatch] = useReducer(profilesReducer, initialData);

  const reload = useCallback((profiles: Profile[]) => {
    dispatch({type: 'RELOAD', payload: profiles});
  }, []);

  const updateScore = useCallback((profileIds: string[], score: ScoreValue) => {
    dispatch({type: 'UPDATE_SCORE', payload: profileIds.map(id => ({id, score}))});
  }, []);

  const assignTag = useCallback((profileIds: string[], tag: TagSimple) => {
    dispatch({type: 'ASSIGN_TAG', payload: profileIds.map(id => ({id, tag}))});
  }, []);

  const unassignTag = useCallback((profileIds: string[], tagId: TagSimple['id']) => {
    dispatch({type: 'UNASSIGN_TAG', payload: profileIds.map(id => ({id, tagId}))});
  }, []);

  const addToPipeline = useCallback((profileIds: string[], pipelineId: string, pipelineTitle?: string) => {
    dispatch({type: 'ADD_TO_PIPELINE', payload: profileIds.map(id => ({id, pipelineId, pipelineTitle}))});
  }, []);

  const statusChange = useCallback((profileIds: string[], pipelineId: string, status: PipelineStatus) => {
    dispatch({type: 'STATUS_CHANGE', payload: profileIds.map(id => ({id, pipelineId, status}))});
  }, []);

  return {
    profiles: state,
    reload,
    updateScore,
    assignTag,
    unassignTag,
    addToPipeline,
    statusChange,
  };
};

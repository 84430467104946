import {createContext, useContext} from 'react';
import {UseQueryOptions, UseQueryResult} from 'react-query';
import {BffAggregateRequest, BffAggregateResponse} from '../../types';
import {FiltersState} from './types';

export type FiltersContextType = {
  filtersState: FiltersState;
  useFetchOptions: (
    getAggregateInput: BffAggregateRequest,
    options?: UseQueryOptions<BffAggregateResponse>
  ) => UseQueryResult<BffAggregateResponse, unknown>;
  mainSearchQuery?: string;
};

export const FiltersContext = createContext<FiltersContextType>({} as FiltersContextType);
FiltersContext.displayName = 'SwarmFiltersContext';

type Props = FiltersContextType & {
  children: React.ReactNode;
};
export const FiltersContextProvider = ({filtersState, useFetchOptions, mainSearchQuery, children}: Props) => {
  return (
    <FiltersContext.Provider value={{filtersState, useFetchOptions, mainSearchQuery}}>
      {children}
    </FiltersContext.Provider>
  );
};

export const useFiltersContext = () => {
  const contextValue = useContext(FiltersContext);

  return contextValue;
};

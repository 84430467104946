import {useState} from 'react';
import {AvatarWithFullname} from '../../../components/avatar';
import {ExpandButton} from '../../../components/expand-button';
import {PremiumFeatureWrapper} from '../../../components/premium-feature-wrapper';
import {Score} from '../../../components/score';
import {BffConnection, Permission} from '../../../types';
import {getUserFullName} from '../../../utils';
import Styles from './ConnectedMembers.module.scss';

type Props = {
  connections: BffConnection[];
};

const descriptions = ['Unfamiliar connector', 'Weak connector', 'Familiar connector', 'Strong connector'];
const initialMembersLimit = 2;
export const ConnectedMembers = ({connections}: Props) => {
  const [expanded, setExpanded] = useState(initialMembersLimit > connections.length);
  const connectionsToShow = expanded ? connections : connections.slice(0, initialMembersLimit);
  const amountHidden = connections.length - initialMembersLimit;

  return (
    <>
      <div className={Styles.connectedMembers}>
        {connectionsToShow.map(
          connection =>
            connection.id && (
              <div className={Styles.connectedMember} key={connection.id}>
                <AvatarWithFullname fullname={getUserFullName(connection.user)} />
                <PremiumFeatureWrapper
                  permission={Permission.ConnectionStrength}
                  featureName="Connection Strength"
                  location="profile connection score"
                  fallback={<span className="pl-2 text-sm font-semibold">⚠️ Not available</span>}
                >
                  <Score value={connection.strength} />
                  <span className={Styles.description}>{descriptions[connection.strength]}</span>
                </PremiumFeatureWrapper>
              </div>
            )
        )}
      </div>
      {amountHidden > 0 && (
        <div className={Styles.expander}>
          <ExpandButton expanded={expanded} onClick={() => setExpanded(!expanded)} inline />
          {!expanded && (
            <span className={Styles.expanderLabel}>
              + {amountHidden} other connector{amountHidden > 1 ? 's' : ''}
            </span>
          )}
        </div>
      )}
    </>
  );
};

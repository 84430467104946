import {useMemo} from 'react';
import {FiSearch as SearchIcon} from 'react-icons/fi';
import {ColumnSettingsDropdown} from '../../../components/column-settings';
import {Input} from '../../../components/form';
import {UseSwarmTableProps, useTableFilters, useTableSearch} from '../../../components/table';
import {ConnectionsColumns, labelsMap} from '../../../components/table/connectionsColumns';
import {useColumnSettings, useOnFilteringEvent, useOnTeamChanged} from '../../../hooks';
import {MyConnectionsFilter, MyConnectionsFilterKeys} from '../../../types';
import {availableColumns, PREFERENCES_KEY} from './columns';
import {ConnectionsTable} from './ConnectionsTable';
import {Filters} from './Filters';

type Props = {
  companyId: string;
  companyName: string;
  pagination: Required<UseSwarmTableProps>['pagination'];
  sorting: Required<UseSwarmTableProps>['sorting'];
  teamId: string;
};
export const CompanyConnections = ({companyId, pagination, sorting, teamId, companyName}: Props) => {
  const {columnSettings, setColumnSettings, visibleColumnsState, columnsOrder} = useColumnSettings(
    availableColumns,
    PREFERENCES_KEY
  );

  const defaultFilters = useMemo(
    () => ({
      [MyConnectionsFilterKeys.CurrentJobCompanyId]: [companyId],
    }),
    [companyId]
  );

  const tableFilters = useTableFilters<MyConnectionsFilter>(defaultFilters);
  const tableSearch = useTableSearch();

  useOnFilteringEvent({
    filters: tableFilters.filters,
    filtersToOmit: [MyConnectionsFilterKeys.CurrentJobCompanyId],
    searchQuery: tableSearch.searchQueryFetchParam,
  });

  // Cleanup filters when team changes
  useOnTeamChanged(tableFilters.clearFilters);

  return (
    <>
      <div className="flex items-start justify-between">
        <div className="flex flex-row">
          <Filters tableFilters={tableFilters} />
          <div className="w-72 pl-2">
            <Input
              icon={SearchIcon}
              type="text"
              value={tableSearch.searchQuery}
              onChange={e => tableSearch.onChangeSearchQuery(e.target.value)}
              onClear={tableSearch.onClearSearchQuery}
              className="max-w-sm"
              placeholder="Search for name, email, title..."
              intercomTarget="search"
            />
          </div>
        </div>
        <div className="m-2">
          <ColumnSettingsDropdown
            availableColumns={availableColumns}
            fixedColumns={[ConnectionsColumns.LastName]}
            columnSettings={columnSettings}
            setColumnSettings={setColumnSettings}
            labelsMap={labelsMap}
          />
        </div>
      </div>

      <ConnectionsTable
        tableFilters={tableFilters}
        pagination={pagination}
        sorting={sorting}
        teamId={teamId}
        companyName={companyName}
        searchQuery={tableSearch.searchQueryFetchParam}
        visibleColumnsState={visibleColumnsState}
        columnsOrder={columnsOrder}
        availableColumns={availableColumns}
      />
    </>
  );
};

import cs from 'classnames';
import {useCallback, useMemo, useState} from 'react';
import {FiCheck as CheckIcon} from 'react-icons/fi';
import {useMutationGenerateMagicLinkCode} from '../../api';
import {MagicLinkCode} from '../../types';
import {segmentTrack} from '../../utils';
import {copyTextToClipboard} from '../../utils/clipboard';
import {Button} from '../button';
import {notify} from '../notifications';
import Style from './InviteByLink.module.scss';
import {createMagicLink} from './utils';

type Props = {
  className?: string;
};

const onCopySuccess = () => {
  segmentTrack('Item Copied', {label: 'url', location: 'network sharing'});
  notify('Link copied to the clipboard');
};

export const InviteByLink = ({className}: Props) => {
  const [code, setCode] = useState('');

  const magicLink = useMemo(() => {
    if (!code) return '';

    return createMagicLink(code);
  }, [code]);

  const onSuccessGeneratingMagicLinkCode = useCallback(({value: code}: MagicLinkCode) => {
    setCode(code);
    copyTextToClipboard(createMagicLink(code));
    onCopySuccess();
  }, []);

  const {mutate: generateMagicLinkCode} = useMutationGenerateMagicLinkCode({
    onSuccess: onSuccessGeneratingMagicLinkCode,
    onError: () => {
      notify('There was a problem generating the code.\nPlease try again later.');
    },
  });

  const handleGenerateCode = useCallback(() => {
    generateMagicLinkCode();
  }, [generateMagicLinkCode]);

  const handleCopyExistingCode = useCallback(() => {
    copyTextToClipboard(createMagicLink(code));
    onCopySuccess();
  }, [code]);

  const GenerateBtn = (
    <Button variant="primary" onClick={handleGenerateCode}>
      Generate
    </Button>
  );

  const CopyDoneBtn = (
    <Button variant="green" onClick={handleCopyExistingCode} icon={<CheckIcon />}>
      Copied
    </Button>
  );

  return (
    <div className={cs(Style.wrapper, className)}>
      <input
        placeholder='Click "Generate" to create a link'
        disabled={!code}
        className={cs(Style.input, {[Style.inactiveInput]: !code})}
        value={magicLink}
      />

      {code ? CopyDoneBtn : GenerateBtn}
    </div>
  );
};

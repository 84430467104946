import {ReactNode} from 'react';
import {Link} from 'react-router-dom';
import {TruncateWithTooltip} from '../truncate-with-tooltip';
import Styles from './TruncateProfileLink.module.scss';

type Props = {
  href: string;
  children: ReactNode;
};

export const TruncateLink = ({href, children}: Props) => {
  return (
    <TruncateWithTooltip content={children}>
      <Link to={href}>
        <span className={Styles.nameLinkContent}>{children}</span>
      </Link>
    </TruncateWithTooltip>
  );
};

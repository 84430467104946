import {PaymentPeriod, PlanType} from '../../../types';

type PricingType = {
  [PaymentPeriod.Monthly]: number;
  [PaymentPeriod.Yearly]: number;
};

export const premiumPlanPricing: PricingType = {
  [PaymentPeriod.Monthly]: 299,
  [PaymentPeriod.Yearly]: 249,
};

export const basePlanPricing: PricingType = {
  [PaymentPeriod.Monthly]: 29,
  [PaymentPeriod.Yearly]: 24,
};

type PlanComparisonCategory = {
  label: string;
  features: PlanComparisonFeature[];
};

type PlanComparisonFeature = {
  feature: string;
  [PlanType.Free]: boolean | string;
  [PlanType.Base]: boolean | string;
  [PlanType.Premium]: boolean | string;
  comingSoon: boolean;
  biggerCell?: boolean;
};

type PlanComparisonData = {
  [key: string]: PlanComparisonCategory;
};

export const planComparison: PlanComparisonData = {
  general: {
    label: 'Features',
    features: [
      {
        feature: 'Combined networks',
        [PlanType.Free]: true,
        [PlanType.Base]: true,
        [PlanType.Premium]: true,
        comingSoon: false,
      },
      {
        feature: 'Enriched profiles',
        [PlanType.Free]: true,
        [PlanType.Base]: true,
        [PlanType.Premium]: true,
        comingSoon: false,
      },
      {
        feature: 'Individual intro requests',
        [PlanType.Free]: true,
        [PlanType.Base]: true,
        [PlanType.Premium]: true,
        comingSoon: false,
      },
      {
        feature: 'Connection strength',
        [PlanType.Free]: false,
        [PlanType.Base]: true,
        [PlanType.Premium]: true,
        comingSoon: false,
      },
      {
        feature: 'Company-level filters',
        [PlanType.Free]: false,
        [PlanType.Base]: true,
        [PlanType.Premium]: true,
        comingSoon: false,
      },
      {
        feature: 'Pipelines',
        [PlanType.Free]: false,
        [PlanType.Base]: true,
        [PlanType.Premium]: true,
        comingSoon: false,
      },
      {
        feature: 'Saved searches',
        [PlanType.Free]: false,
        [PlanType.Base]: true,
        [PlanType.Premium]: true,
        comingSoon: false,
      },
      {
        feature: 'Company tags',
        [PlanType.Free]: false,
        [PlanType.Base]: true,
        [PlanType.Premium]: true,
        comingSoon: false,
      },
      {
        feature: 'Auto-matching',
        [PlanType.Free]: false,
        [PlanType.Base]: false,
        [PlanType.Premium]: true,
        comingSoon: true,
      },
    ],
  },
  dataIntegrations: {
    label: 'DATA & INTEGRATIONS',
    features: [
      {
        feature: 'Seats',
        [PlanType.Free]: 'Up to 10 active users (x10 guests)',
        [PlanType.Base]: 'Up to 10 active users (x10 guests)',
        [PlanType.Premium]: 'Up to 500 active users and guests',
        comingSoon: false,
        biggerCell: true,
      },
      {
        feature: 'LinkedIn connections',
        [PlanType.Free]: true,
        [PlanType.Base]: true,
        [PlanType.Premium]: true,
        comingSoon: false,
      },
      {
        feature: 'Google contacts',
        [PlanType.Free]: true,
        [PlanType.Base]: true,
        [PlanType.Premium]: true,
        comingSoon: false,
      },
      {
        feature: 'Google calendar',
        [PlanType.Free]: true,
        [PlanType.Base]: true,
        [PlanType.Premium]: true,
        comingSoon: false,
      },
      {
        feature: 'Work overlaps',
        [PlanType.Free]: true,
        [PlanType.Base]: true,
        [PlanType.Premium]: true,
        comingSoon: false,
      },
      {
        feature: 'CSV export',
        [PlanType.Free]: true,
        [PlanType.Base]: true,
        [PlanType.Premium]: true,
        comingSoon: false,
      },
      {
        feature: 'LinkedIn list import',
        [PlanType.Free]: true,
        [PlanType.Base]: true,
        [PlanType.Premium]: true,
        comingSoon: true,
      },
      {
        feature: 'Full database access',
        [PlanType.Free]: false,
        [PlanType.Base]: true,
        [PlanType.Premium]: true,
        comingSoon: true,
      },
      {
        feature: 'CRM & ATS integrations',
        [PlanType.Free]: false,
        [PlanType.Base]: false,
        [PlanType.Premium]: true,
        comingSoon: true,
      },
    ],
  },
  support: {
    label: 'SUPPORT',
    features: [
      {
        feature: 'Swarm Academy',
        [PlanType.Free]: true,
        [PlanType.Base]: true,
        [PlanType.Premium]: true,
        comingSoon: false,
      },
      {
        feature: 'Chat & email support',
        [PlanType.Free]: true,
        [PlanType.Base]: true,
        [PlanType.Premium]: true,
        comingSoon: false,
      },
      {
        feature: 'Priority support',
        [PlanType.Free]: false,
        [PlanType.Base]: false,
        [PlanType.Premium]: true,
        comingSoon: false,
      },
    ],
  },
};

import {AxiosResponse} from 'axios';
import {useMutation, UseMutationOptions} from 'react-query';
import {Task} from '../types';
import {apiClient} from './apiClient';

const startWorkOverlapsImportTask = (): Promise<Task> =>
  apiClient.post<Task, AxiosResponse<Task>>('/profiles/imports/overlaps').then(res => res.data);

export const useMutationStartWorkOverlapsImportTask = (options: UseMutationOptions) =>
  useMutation<Task, Error>(() => startWorkOverlapsImportTask(), options);

type StartGoogleImportTaskInput = {
  googleAuthCode: string;
};

const startGoogleImportTask = (googleAuthCode: string) =>
  apiClient
    .post<Task, AxiosResponse<Task>, StartGoogleImportTaskInput>('/profiles/imports/google', {
      googleAuthCode,
    })
    .then(res => res.data);

export const useMutationStartGoogleImportTask = () =>
  useMutation<Task, Error, StartGoogleImportTaskInput>(({googleAuthCode}) =>
    startGoogleImportTask(googleAuthCode)
  );

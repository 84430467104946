import {Briefcase as BriefcaseIcon} from 'phosphor-react';
import {useEffect} from 'react';
import {AuthBox, AuthBoxIcon} from '../../../../components/auth';
import {CreateTeamForm} from '../../../../components/create-team';
import {ShareNetworkInvitations} from '../../../../components/share-network-invitations';
import {ShareNetworkContextProvider} from '../../../../contexts';
import {segmentVirtualPageview} from '../../../../utils';

type Props = {
  onSuccess: () => void;
};

export const CreateTeamScreen = ({onSuccess}: Props) => {
  useEffect(() => {
    segmentVirtualPageview('/create-team');
  }, []);

  return (
    <ShareNetworkContextProvider>
      <AuthBox
        headerImage={<HeaderImage />}
        headerTitle="Name your team"
        headerDescription="We recommend using your company name."
        footer={<Footer />}
      >
        <CreateTeamForm onSuccess={onSuccess} isInOnboarding />
        <ShareNetworkInvitations />
      </AuthBox>
    </ShareNetworkContextProvider>
  );
};

const Footer = () => (
  <p className="mt-16 text-swarm-gray-700">
    Trying to join an existing team? <br />
    Contact the account owner or an admin so they invite you.
  </p>
);

const HeaderImage = () => (
  <AuthBoxIcon>
    <BriefcaseIcon size={32} />
  </AuthBoxIcon>
);

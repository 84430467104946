import {ReactNode, useCallback} from 'react';
import {FilterOptionsItem} from '../../types';
import {segmentTrack} from '../../utils';
import {Dropdown} from '../dropdown';
import {DropdownSearch} from '../dropdown-search';
import {AutocompleteFilter} from './AutocompleteFilter';
import {AutocompletionsLoading} from './AutocompletionsLoading';
import {ConditionalIsOpenProps} from './types';
import {useIsFilterOpen} from './useIsFilterOpen';

type Props = ConditionalSearchProps &
  ConditionalClientSideSearchProps &
  ConditionalIsOpenProps & {
    title: string;
    selectedOptionsValues?: string[];
    onChange: OnChange;
    labelsMapping?: Record<string, string>;
    hideOccurrences?: boolean;
    filterOptions: FilterOptionsItem[];
    exactMatchFiltering?: boolean;
    isLoading?: boolean;
    disabled?: boolean;
    emptyOptionsFallback?: ReactNode;
  };
type OnChange = (filterValue: string[]) => void;

type ConditionalSearchProps = ({searchable: true} & SearchProps) | {searchable?: false};
type ConditionalClientSideSearchProps =
  | ({clientSideSearchable: true} & SearchProps)
  | {clientSideSearchable?: false};
type SearchProps = {
  searchPhrase: string;
  searchPlaceholder?: string;
  onSearchPhraseChange: (value: string) => void;
  onChange: OnChange;
  searchSubmit?: boolean;
  clientSideSearchable?: boolean;
};
const Search = ({
  searchPhrase,
  searchPlaceholder,
  onSearchPhraseChange,
  onChange,
  searchSubmit,
  clientSideSearchable,
}: SearchProps) => {
  const onSearchSubmit = useCallback(
    (val: string) => {
      !clientSideSearchable && searchSubmit && onChange([val]);
    },
    [onChange, searchSubmit, clientSideSearchable]
  );
  return (
    <DropdownSearch
      query={clientSideSearchable ? '' : searchPhrase}
      onChange={onSearchPhraseChange}
      onSubmit={onSearchSubmit}
      placeholder={searchPlaceholder}
    />
  );
};

export const MultipleOptionsFilter = (props: Props) => {
  const {
    title,
    onChange,
    selectedOptionsValues = [],
    searchable,
    clientSideSearchable,
    hideOccurrences,
    filterOptions,
    isLoading,
    externalIsOpen,
    exactMatchFiltering,
    setExternalIsOpen,
    disabled,
    emptyOptionsFallback,
  } = props;

  const valuableFilterOptions = filterOptions.filter(item => item.value);

  const [isOpen, setIsOpen] = useIsFilterOpen({
    externalIsOpen,
    setExternalIsOpen,
    title,
  });

  const emitApplyEvent = useCallback(
    () =>
      segmentTrack('Selector Applied', {
        label: 'filter',
        filter: title,
      }),
    [title]
  );

  const onItemChange = useCallback(
    (itemValue: string) => {
      if (selectedOptionsValues.includes(itemValue)) {
        onChange(selectedOptionsValues.filter(item => item !== itemValue));
      } else {
        onChange([...selectedOptionsValues, itemValue]);
      }

      emitApplyEvent();
    },
    [onChange, selectedOptionsValues, emitApplyEvent]
  );

  const onClear = useCallback(() => {
    onChange([]);
    segmentTrack('Selector Cleared', {
      label: 'filter',
      filter: title,
    });
  }, [onChange, title]);

  const onSelectAll = useCallback(() => {
    onChange(filterOptions.map(item => (exactMatchFiltering ? `"${item.value}"` : item.value)));
    emitApplyEvent();
  }, [onChange, filterOptions, emitApplyEvent, exactMatchFiltering]);

  return (
    <AutocompleteFilter
      title={title}
      isActive={Boolean(selectedOptionsValues.length)}
      activeFiltersCount={selectedOptionsValues.length}
      onClear={onClear}
      search={searchable || clientSideSearchable ? <Search {...props} /> : undefined}
      onSelectAll={filterOptions.length ? onSelectAll : undefined}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      disabled={disabled}
    >
      {isLoading ? (
        <AutocompletionsLoading />
      ) : valuableFilterOptions.length ? (
        valuableFilterOptions.map(item => {
          const filteringValue = exactMatchFiltering ? `"${item.value}"` : item.value;
          return (
            <Dropdown.Item
              key={item.value}
              label={item.enhancedLabel || item.label}
              type={{
                name: 'checkbox',
                onClick: () => onItemChange(filteringValue),
                checked: selectedOptionsValues.includes(filteringValue),
                badge: hideOccurrences ? undefined : item.count,
              }}
            />
          );
        })
      ) : (
        emptyOptionsFallback
      )}
    </AutocompleteFilter>
  );
};

import {omit} from 'lodash';
import {useCallback, useEffect, useState} from 'react';
import {useObjectInSearchParam} from '../../hooks';

export const useTableFilters = <T extends Record<string, unknown>>(defaultFilters?: Partial<T>) => {
  type Filters = Partial<T>;
  type FilterName = keyof Filters;

  const {saveObjectAsSearchParam, getObjectFromSearchParam} = useObjectInSearchParam<Filters>({
    defaultObject: {},
    searchParamName: 'filters',
    replaceHistory: true,
  });

  const [filters, setFilters] = useState<Filters>({...defaultFilters, ...getObjectFromSearchParam()});

  const setFilter = useCallback(
    <Key extends FilterName>(filterName: Key, filterValue: T[Key], filtersToClear: Key[] = []) => {
      const newFilters = {...filters, [filterName]: filterValue};
      if (filtersToClear.length > 0) {
        filtersToClear.forEach(filterToClear => {
          delete newFilters[filterToClear];
        });
      }
      setFilters(newFilters);
    },
    [filters]
  );

  const clearFilter = useCallback(
    (filterName: FilterName) => {
      const newFilters = omit(filters, filterName) as Filters;
      setFilters(newFilters);
    },
    [filters]
  );

  const clearFilters = useCallback(() => setFilters(defaultFilters || {}), [defaultFilters]);

  const getFilterValue = useCallback(
    <Key extends FilterName>(filterName: Key): T[Key] | undefined => filters[filterName],
    [filters]
  );

  const filtersWithoutDefaults = omit(filters, Object.keys(defaultFilters || {})) as Filters;

  useEffect(() => {
    // has to be declared here, otherwise it will be stale
    const filtersWithoutDefaults = omit(filters, Object.keys(defaultFilters || {})) as Filters;
    saveObjectAsSearchParam(filtersWithoutDefaults);
  }, [filters, defaultFilters, saveObjectAsSearchParam]);

  const hasFiltersSet = Object.keys(filtersWithoutDefaults).length > 0;

  return {
    filters,
    filtersWithoutDefaults,
    hasFiltersSet,
    clearFilter,
    clearFilters,
    setFilter,
    getFilterValue,
  };
};

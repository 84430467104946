import {ReactNode} from 'react';
import {FaFacebook as Facebook} from 'react-icons/fa';
import {FaXTwitter as X} from 'react-icons/fa6';

export const enum ConnectionSourceNetworkEnum {
  twitter = 'twitter',
  facebook = 'facebook',
  linkedin = 'linkedin',
  google = 'google',
  overlap = 'overlap',
  calendar = 'calendar',
}

export const ConnectionSourceIconsMap: Record<ConnectionSourceNetworkEnum, () => ReactNode> = {
  twitter: () => <X />,
  facebook: () => <Facebook />,
  linkedin: () => <img src="/icons/linkedin.svg" alt="linkedin icon" />,
  google: () => <img src="/icons/google.svg" alt="google icon" />,
  overlap: () => <img src="/icons/work-overlaps.svg" alt="overlap icon" />,
  calendar: () => <img src="/icons/calendar.svg" alt="calendar icon" />,
};

import {flatten, intersection, uniq} from 'lodash';
import {useState} from 'react';
import {FiTag as TagIcon} from 'react-icons/fi';
import {assignTag, unassignTag} from '../../../api';
import {usePremiumFeatureCallback} from '../../../hooks';
import {Permission, TagSimple} from '../../../types';
import {notifyGenericError, segmentTrack, trackError} from '../../../utils';
import {Button} from '../../button';
import {TagsListDropdown} from '../tags-list-dropdown';

type Props = {
  targetType: 'company' | 'profile';
  selectedEntitiesIds: string[];
  selectedTags: string[][];
  onAssignSuccess?: (tag: TagSimple) => void;
  onUnassignSuccess?: (tagId: string) => void;
};

export const BulkTags = ({
  targetType,
  selectedEntitiesIds,
  selectedTags,
  onAssignSuccess,
  onUnassignSuccess,
}: Props) => {
  const [isTagsDropdownOpen, setIsTagsDropdownOpen] = useState(false);

  const handleCLickTrigger = usePremiumFeatureCallback({
    callback: () => setIsTagsDropdownOpen(!isTagsDropdownOpen),
    permission: Permission.TagAssign,
  });

  const usedTags = uniq(flatten(selectedTags));
  const assignedTags = intersection(...selectedTags);

  return (
    <TagsListDropdown
      isOpen={isTagsDropdownOpen}
      setIsOpen={handleCLickTrigger}
      trigger={
        <Button
          size="sm"
          variant="tertiary"
          outline
          chevron="down"
          icon={<TagIcon />}
          onClick={handleCLickTrigger}
        >
          Edit tags
        </Button>
      }
      assignedTags={assignedTags}
      indeterminatedTags={usedTags}
      onAssign={(tagId: string) =>
        assignTag(tagId, {
          targetIds: selectedEntitiesIds,
          targetType: targetType,
        })
          .then(() => {
            segmentTrack('Bulk Action Applied', {
              label: 'assign tag',
              location: targetType === 'company' ? 'companies' : 'connections',
              profile_count: selectedEntitiesIds.length,
              tag: tagId,
            });
          })
          .catch(e => {
            notifyGenericError();
            trackError(e);
          })
      }
      onUnassign={(tagId: string) =>
        unassignTag(tagId, {
          targetIds: selectedEntitiesIds,
          targetType: targetType,
        })
          .then(() => {
            segmentTrack('Bulk Action Applied', {
              label: 'remove tag',
              location: targetType === 'company' ? 'companies' : 'connections',
              profile_count: selectedEntitiesIds.length,
              tag: tagId,
            });
          })
          .catch(e => {
            notifyGenericError();
            trackError(e);
          })
      }
      onAssignSuccess={onAssignSuccess}
      onUnassignSuccess={onUnassignSuccess}
    />
  );
};

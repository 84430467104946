import cs from 'classnames';
import {useCallback} from 'react';
import {useCurrentUser, useTeamMembers} from '../../hooks';
import {Role, User} from '../../types';
import {segmentTrack} from '../../utils';
import {Button} from '../button';
import {onRowHoverVisible} from '../table';
import Styles from './RequestIntro.module.scss';
import {RequestIntroButtonProps} from './types';

const RequestIntroIcon = () => <img src="/icons/request-intro.svg" className="h-4" />;

const isNotAnonymizedUser = (user: User) => !user.isAnonymized;

export const RequestIntroButton = (props: RequestIntroButtonProps) => {
  const {showModal, inTable, connectors = [], profileId, pipelineId} = props;
  const currentUser = useCurrentUser();
  const {members} = useTeamMembers();

  const isNotConnectorRole = useCallback(
    (user: User) => {
      const connectorsMembersIds = members
        .filter(member => member.role === Role.Connector)
        .map(member => member.userId);
      return !connectorsMembersIds.includes(user.id);
    },
    [members]
  );

  const profileConnectors = connectors
    .filter(connector => connector.id !== currentUser.id)
    .filter(isNotAnonymizedUser)
    /* from list of people connecting the profile with current user (connectors)
       we're filtering out people who have a role of 'connector' (Connectors) */
    .filter(isNotConnectorRole);

  const handleOnClick = useCallback(() => {
    showModal();

    segmentTrack('Button Clicked', {
      label: 'intro request',
      profile_id: profileId,
      ...(pipelineId ? {pipeline_id: pipelineId} : {}),
    });
  }, [pipelineId, profileId, showModal]);

  return profileConnectors.length === 0 ? null : (
    <Button
      variant="tertiary"
      outline
      icon={<RequestIntroIcon />}
      onClick={handleOnClick}
      className={cs(Styles.requestIntroButton, {[Styles.inTable]: inTable, [onRowHoverVisible]: inTable})}
      iconOnly={inTable}
      rounded={inTable}
      size={inTable ? 'xs' : 'md'}
    >
      Request intro
    </Button>
  );
};

import {useEffect, useState} from 'react';
import {useQueryProfiles} from '../../../api';
import {prepareQueryProfilesInput} from '../../../components/table/prepareProfilesQueryInput';
import {useCurrentTeam} from '../../../hooks';
import {Profile} from '../../../types';

export const useProfilesToCount = (pipelineId: string) => {
  const {id: teamId} = useCurrentTeam();
  const [profilesToCount, setProfilesToCount] = useState<Profile[]>([]);

  const defaultFilters = {in_pipeline: [pipelineId]};

  const getProfilesInput = prepareQueryProfilesInput({
    pageIndex: 1,
    teamGraph: true,
    filters: defaultFilters,
  });

  const {data: pipelineProfiles, refetch} = useQueryProfiles(teamId, getProfilesInput, {
    keepPreviousData: true,
  });

  useEffect(() => {
    pipelineProfiles && setProfilesToCount(pipelineProfiles.data);
  }, [pipelineProfiles]);

  return {
    profilesToCount,
    refetchProfilesToCount: refetch,
  };
};

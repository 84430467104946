import cs from 'classnames';
import {forwardRef} from 'react';
import {FiChevronDown as ChevronDown, FiChevronUp as ChevronUp} from 'react-icons/fi';
import {useLayoutContext} from '../../../contexts';
import {PulsingDot} from '../../animations';
import {Avatar} from '../../avatar';
import {TruncateWithTooltip} from '../../truncate-with-tooltip';
import Styles from './MenuTrigger.module.scss';

type Props = {
  label: string;
  dropdownOpen: boolean;
  showAlert?: boolean;
  bottomPlacement?: boolean;
};

const MenuTrigger = forwardRef<HTMLDivElement, Props>(
  ({label, dropdownOpen, showAlert, bottomPlacement}, ref) => {
    const {sidebarCollapsed} = useLayoutContext();

    const Chevron = bottomPlacement ? ChevronUp : ChevronDown;
    return (
      <div
        className={cs(Styles.menuTrigger, {
          [Styles.menuTriggerOpen]: dropdownOpen,
          [Styles.iconOnly]: sidebarCollapsed,
        })}
        ref={ref}
      >
        <div className={Styles.labelWrapper}>
          <div className={Styles.label}>
            <Avatar fullname={label} size="md" />
            <TruncateWithTooltip content={label} placement="right-center">
              {label}
            </TruncateWithTooltip>
            {showAlert && <PulsingDot className={Styles.alert} />}
          </div>
        </div>
        {!sidebarCollapsed && <Chevron size={18} className={Styles.triggerChevron} />}
      </div>
    );
  }
);

// below code is added according to:
// https://stackoverflow.com/questions/67992894/component-definition-is-missing-display-name-for-forwardref

MenuTrigger.displayName = 'MenuTrigger';
export {MenuTrigger};

import {getRequestOffset, PathParams, prepareSearchQuery, sortingStateToSort} from '../../utils';

export const prepareQueryProfilesInput = ({
  pageIndex,
  pageSize = 50,
  query,
  filters = {},
  teamGraph = false,
  sorting = [],
}: PathParams) => {
  const offset = getRequestOffset(pageSize, pageIndex);
  const sort = sortingStateToSort([
    ...sorting,
    {id: 'last_name', desc: false},
    {id: 'first_name', desc: false},
  ]);
  const mergedFilters = {...filters, query: query ? [prepareSearchQuery(query)] : []};

  return {
    team_graph: teamGraph,
    offset,
    limit: pageSize,
    sort,
    filters: mergedFilters,
  };
};

import {useCallback, useEffect} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {LoadingSpinner} from '../../components/animations';
import {useMagicLinkCode} from '../../hooks';
import {AuthLayout} from '../../layouts';

// this page can be accessed by unauthenticated users
export const MagicLinkLandingPage = () => {
  const {code = ''} = useParams();
  const navigate = useNavigate();
  const {saveCode} = useMagicLinkCode();

  const goToCreateTeam = useCallback(() => navigate('/create-team'), [navigate]);

  useEffect(() => {
    saveCode(code);
    goToCreateTeam();
  }, [code, goToCreateTeam, saveCode]);

  return (
    <AuthLayout>
      <div className="mx-auto my-6 w-16">
        <LoadingSpinner />
      </div>
    </AuthLayout>
  );
};

import {useEffect, useMemo, useState} from 'react';
import {Label} from '../../form';
import {TagsCloud} from '../../tags-cloud';
import {TokenizedListEditorProps} from './types';

export const TokenizedListEditor = ({
  value,
  label,
  onChange,
  tokensLabel = 'Recognized tokens',
  minItemLength = 1,
}: TokenizedListEditorProps) => {
  const [inputText, setInputText] = useState(value.join(', '));
  const tokens = useMemo(() => {
    const newTokens = inputText.split(/[,]+|\n+/).filter(token => token.trim().length >= minItemLength);
    return newTokens.map(token => token.trim());
  }, [inputText, minItemLength]);

  useEffect(() => {
    onChange(tokens);
  }, [tokens, onChange]);

  return (
    <div className="flex flex-col gap-4">
      <Label>{label}</Label>
      <textarea
        rows={8}
        onChange={event => setInputText(event.target.value)}
        className="w-full"
        defaultValue={inputText}
      />
      {value.length ? (
        <span className="text-sm">
          {tokensLabel} ({value.length}):
        </span>
      ) : null}
      <TagsCloud items={tokens} initialAmount={8} className="pt-0" />
    </div>
  );
};

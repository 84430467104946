import {MouseEvent, useEffect} from 'react';
import {useIntercom} from 'react-use-intercom';
import {Role} from '../../../types';
import {segmentTrack} from '../../../utils';
import {FormWrapper} from '../../form-wrapper';
import {RoleIcon} from '../../icons';
import Styles from './AddMembersModal.module.scss';
import illustration from './illustration.png';

const LEARN_MORE_ARTICLE_ID = 7065679;

type Props = {
  goBack: () => void;
  onSuccess: () => void;
};
export const AddConnectorsStep = ({goBack, onSuccess}: Props) => {
  const {showArticle} = useIntercom();
  const openLearnMore = (e: MouseEvent<HTMLAnchorElement>) => {
    e.stopPropagation();
    showArticle(LEARN_MORE_ARTICLE_ID);
  };

  useEffect(() => {
    segmentTrack('Modal Viewed', {
      label: 'add connectors',
      location: 'add members modal',
    });
  }, []);

  return (
    <>
      <h4 className={Styles.header}>
        <RoleIcon role={Role.Connector} className={Styles.headerIcon} />
        Add Connectors
      </h4>

      <hr className={Styles.divider} />

      <FormWrapper
        skipButton={{label: 'Back', onClick: goBack}}
        actionButton={{
          label: 'Request Adding Connectors',
          onClick: onSuccess,
          intercomTarget: 'Request Adding Connectors',
          tracking: {label: 'request adding connectors', location: 'add members modal'},
        }}
        reverseButtonsOrder
        onModal
        fullWidth
      >
        <img src={illustration} alt="" className={Styles.illustration} height={232} />
        <p className={Styles.paragraph}>
          We use our AI Network Mapper® to automatically find people you may know based on their work
          experience and overlaps.
        </p>

        <p className={Styles.paragraph}>
          Provide us a list of LinkedIn URLs for the profiles you would like to map. Connectors don’t get
          notified nor invited.
        </p>

        <p className={Styles.learnMore}>
          <a
            className="cursor-pointer text-swarm-gray-800 underline hover:text-swarm-black"
            onClick={openLearnMore}
          >
            Learn more
          </a>{' '}
          about Connectors.
        </p>
        <hr className={Styles.divider} />
      </FormWrapper>
    </>
  );
};

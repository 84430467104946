import {FiShare as ShareIcon} from 'react-icons/fi';
import {Crm} from '../../types';
import {Button} from '../button';
import {CrmExportDropdown} from './CrmExportDropdown';

type Props = {
  targetIds: string[];
  targetType: 'profile' | 'company';
  onSuccess: (crm: Crm) => void;
};

export const BulkCrmExportMenu = ({targetIds, targetType, onSuccess}: Props) => (
  <CrmExportDropdown
    targetIds={targetIds}
    targetType={targetType}
    trigger={
      <Button size="sm" variant="tertiary" outline chevron="down" icon={<ShareIcon />}>
        Export
      </Button>
    }
    onExportSuccess={onSuccess}
  />
);

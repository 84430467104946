import {useCallback, useEffect, useMemo, useState} from 'react';
import {FiSearch as SearchIcon} from 'react-icons/fi';
import {useQueryTagsSimple} from '../../../api';
import {FiltersWrapper, isFilterEmpty, ProfileSearchMultipleOptionsFilter} from '../../../components/filters';
import {Input} from '../../../components/form';
import {Score} from '../../../components/score';
import {EmptyTagsListInfo, Tag} from '../../../components/tags';
import {useCurrentUser} from '../../../hooks';
import {
  FilterOptionsItem,
  MyConnectionsFilter,
  MyConnectionsFilterKeys,
  MyConnectionTableFilter,
  Profile,
  ScopeFilterOptions,
  TagSimple,
} from '../../../types';
import {StatusFilter} from './StatusFilter';

type Props = {
  tableFilters: MyConnectionTableFilter;
  profilesToCount?: Profile[];
  totalCount: number;
  pipelineId: string;
  searchQuery: string;
  onChangeSearchQuery: (value: string) => void;
  onClearSearchQuery: () => void;
};

const getTagsOptions = (filterOptions: TagSimple[]): FilterOptionsItem[] => {
  return filterOptions.map(option => ({
    value: option.id,
    label: option.name,
    enhancedLabel: <Tag content={option.name} color={option.color} />,
  }));
};

export const Filters = ({
  tableFilters,
  profilesToCount,
  totalCount,
  pipelineId,
  searchQuery,
  onChangeSearchQuery,
  onClearSearchQuery,
}: Props) => {
  const [isProfileTagsFilterOpen, setIsProfileTagsFilterOpen] = useState(false);
  const [showStatusFilterCounts] = useState(totalCount <= 50);
  const {filters, hasFiltersSet, clearFilter, clearFilters, setFilter, getFilterValue} = tableFilters;
  const {id: currentUserId} = useCurrentUser();
  const {data: tags, refetch: refetchTags} = useQueryTagsSimple();

  useEffect(() => {
    if (isProfileTagsFilterOpen) {
      refetchTags();
    }
  }, [isProfileTagsFilterOpen, refetchTags]);

  const onChangeFilter = useCallback(
    <Key extends MyConnectionsFilterKeys>(filterName: Key) =>
      (filterValue: MyConnectionsFilter[Key]) => {
        if (isFilterEmpty(filterValue)) {
          clearFilter(filterName);
        } else {
          setFilter(filterName, filterValue);
        }
      },
    [setFilter, clearFilter]
  );

  const filterProps = <Key extends MyConnectionsFilterKeys>(fieldKey: Key) => ({
    fieldKey: fieldKey,
    onChange: onChangeFilter(fieldKey),
    value: getFilterValue(fieldKey),
    teamGraph: true,
    filters: filters,
  });

  const decorateConnectedTo = useCallback(
    (options: FilterOptionsItem[]) =>
      options.reduce((acc: FilterOptionsItem[], item: FilterOptionsItem) => {
        if (item.value === currentUserId) {
          return [{...item, label: `${item.label} (you)`}, ...acc];
        }
        return [...acc, item];
      }, [] satisfies FilterOptionsItem[]),
    [currentUserId]
  );

  const tagsOptions = useMemo(() => getTagsOptions(tags?.items || []), [tags?.items]);

  return (
    <div className="w-full">
      <StatusFilter
        onChange={onChangeFilter(MyConnectionsFilterKeys.PipelineStatus)}
        value={getFilterValue(MyConnectionsFilterKeys.PipelineStatus)}
        profilesToCount={profilesToCount}
        showCounts={showStatusFilterCounts}
        pipelineId={pipelineId}
      />
      <FiltersWrapper clearFilters={clearFilters} hasFiltersSet={hasFiltersSet} label={<>Filter by:</>}>
        <ProfileSearchMultipleOptionsFilter
          title="Connected to"
          alphabeticalOrder
          clientSideSearchable={
            filters[MyConnectionsFilterKeys.ConnectedTo] &&
            filters[MyConnectionsFilterKeys.ConnectedTo].length > 3
          }
          decorateOptions={decorateConnectedTo}
          {...filterProps(MyConnectionsFilterKeys.ConnectedTo)}
        />
        <ProfileSearchMultipleOptionsFilter
          title="Profile Tags"
          {...filterProps(MyConnectionsFilterKeys.ProfileTags)}
          clientSideSearchable={tagsOptions.length > 3}
          directItems={tagsOptions}
          emptyOptionsFallback={<EmptyTagsListInfo />}
          externalIsOpen={isProfileTagsFilterOpen}
          setExternalIsOpen={setIsProfileTagsFilterOpen}
        />
        <ProfileSearchMultipleOptionsFilter
          title="Strength"
          {...filterProps(MyConnectionsFilterKeys.ConnectionsStrength)}
          labelsMapping={{
            '0': <Score value={0} showLabel />,
            '1': <Score value={1} showLabel />,
            '2': <Score value={2} showLabel />,
            '3': <Score value={3} showLabel />,
          }}
          forceOrder={['3', '2', '1', '0']}
        />
        <ProfileSearchMultipleOptionsFilter
          {...filterProps(MyConnectionsFilterKeys.Scope)}
          title="Network Status"
          directItems={[
            {value: ScopeFilterOptions.InNetwork, label: 'In-network'},
            {value: ScopeFilterOptions.OutOfNetwork, label: 'Out-of-network'},
          ]}
        />
        <div className="w-72">
          <Input
            icon={SearchIcon}
            type="text"
            value={searchQuery}
            onChange={e => onChangeSearchQuery(e.target.value)}
            onClear={onClearSearchQuery}
            className="max-w-sm"
            placeholder="Search for name, email, title..."
            intercomTarget="search"
          />
        </div>
      </FiltersWrapper>
    </div>
  );
};

import {ColumnDef} from '@tanstack/react-table';
import cs from 'classnames';
import {isEqual} from 'lodash';
import {useCallback, useEffect, useMemo} from 'react';
import {FiPlus as PlusIcon} from 'react-icons/fi';
import {useLayoutContext} from '../../contexts';
import {useCheckPermission, useCurrentUser, usePrevious} from '../../hooks';
import {
  MyConnectionTableFilter,
  Permission,
  PipelineStatus,
  Profile,
  ScoreValue,
  TagSimple,
} from '../../types';
import {getProfileFullName, integrationNameMap, segmentTrack} from '../../utils';
import {AddToParticularPipelineButton, AddToPipelineButton} from '../add-to-pipeline';
import {Button} from '../button';
import {CompanyLink} from '../company-link';
import {CrmIcon} from '../crm-icon';
import {LinkedinProfileLink} from '../linkedin-profile-link';
import {notify} from '../notifications';
import {PermissionChecker} from '../permission';
import {PremiumFeatureWrapper} from '../premium-feature-wrapper';
import {ProfileLink} from '../profile-link';
import {RequestIntroButton} from '../request-intro';
import {MultiScore, ScoreDropdown} from '../score';
import {StatusSwitch} from '../status-switch';
import {PipelineSuggestionsIndicator} from '../suggestions-indicator';
import {ConnectedMembersCellContent} from '../table-cells-content';
import {ProfileTagsList, TagsTableCell} from '../tags';
import {Tooltip} from '../tooltip';
import {ConnectionsColumns, labelsMap} from './connectionsColumns';
import {TableInnerHeader} from './TableInnerHeader';
import {UseSwarmTableProps} from './types';
import {useSwarmTable} from './useSwarmTable';

export type UseProfilesTableProps = {
  teamGraph: boolean;
  rows: Profile[];
  isLoaded: boolean;
  tableFilters: MyConnectionTableFilter;
  pagination: Required<UseSwarmTableProps>['pagination'];
  sorting: Required<UseSwarmTableProps>['sorting'];
  searchQuery: string;
  onModifyStrength?: (profileIds: string[], newScore: ScoreValue) => void;
  onAssignTag?: (profileIds: string[], tag: TagSimple) => void;
  onUnassignTag?: (profileIds: string[], tagId: string) => void;
  onAddToPipeline?: (profileIds: string[], pipelineId: string, pipelineTitle?: string) => void;
  onStatusChange?: (profileIds: string[], pipelineId: string, status: PipelineStatus) => void;
  openIntroModal: (profileId: string) => void;
  pipelineId?: string;
  companyName?: string;
  visibleColumnsState: UseSwarmTableProps['visibleColumnsState'];
  columnsOrder: UseSwarmTableProps['columnsOrder'];
  availableColumns?: UseSwarmTableProps['availableColumns'];
  refetchProfiles?: () => void;
};

type ColumnDefWithAccessorKey = ColumnDef<Profile> & {accessorKey: ConnectionsColumns};

export const useProfilesTable = ({
  rows,
  isLoaded,
  tableFilters,
  teamGraph,
  pagination,
  sorting,
  searchQuery,
  onModifyStrength,
  onAssignTag,
  onUnassignTag,
  onAddToPipeline,
  onStatusChange,
  openIntroModal,
  pipelineId,
  companyName,
  visibleColumnsState,
  columnsOrder,
  availableColumns,
  refetchProfiles,
}: UseProfilesTableProps) => {
  const {scrollContentToTop} = useLayoutContext();
  const {filters} = tableFilters;
  const {id: currentUserId} = useCurrentUser();
  const canConnectionStrength = useCheckPermission(Permission.ConnectionStrength);

  const columns: ColumnDefWithAccessorKey[] = useMemo<ColumnDefWithAccessorKey[]>(
    () => [
      {
        accessorKey: ConnectionsColumns.LastName,
        header: () => <TableInnerHeader label={labelsMap[ConnectionsColumns.LastName]} />,
        cell: info => {
          const {profile_info: profileInfo, crms, pipelines = [], connectedMembers} = info.row.original;
          const {id: profileId, linkedin_url: linkedinUrl} = profileInfo;

          return (
            <div>
              <ProfileLink profileId={profileId} profileName={getProfileFullName(profileInfo)} />
              {linkedinUrl && <LinkedinProfileLink linkedinUrl={linkedinUrl} />}
              <PipelineSuggestionsIndicator pipelines={pipelines} showPipelineTitle={true} />
              <PermissionChecker permission={Permission.CRM}>
                <div className={cs('flex shrink-0 flex-row gap-1', {'pr-1.5': crms.length})}>
                  {crms.map(({crm}, index) => (
                    <Tooltip content={`Synchronized with ${integrationNameMap[crm]} CRM`} key={index}>
                      <CrmIcon crm={crm} className="!size-3.5" />
                    </Tooltip>
                  ))}
                </div>
              </PermissionChecker>
              <div className="grow" />
              {!pipelineId && (
                <AddToPipelineButton
                  profileId={profileId}
                  onAddToPipelineSuccess={(pipelineId, pipelineTitle) => {
                    onAddToPipeline?.([profileId], pipelineId, pipelineTitle);
                    notify(`Connection added to ${pipelineTitle}.`);
                  }}
                  profilePipelines={pipelines}
                  onCreatePipelineSuccess={refetchProfiles}
                >
                  <Tooltip content="Add to list" hideHovered>
                    <Button
                      size="xs"
                      variant="tertiary"
                      outline
                      iconOnly
                      rounded
                      icon={<PlusIcon size={16} />}
                    />
                  </Tooltip>
                </AddToPipelineButton>
              )}
              {teamGraph && (
                <Tooltip content="Send intro request">
                  <RequestIntroButton
                    inTable
                    showModal={() => openIntroModal(profileId)}
                    connectors={connectedMembers}
                    profileId={profileId}
                  />
                </Tooltip>
              )}
            </div>
          );
        },
        size: 260,
      },
      {
        accessorKey: ConnectionsColumns.Status,
        header: () => <TableInnerHeader label={labelsMap[ConnectionsColumns.Status]} />,
        cell: info => {
          const {pipelines, profile_info: profileInfo} = info.row.original;
          const status = pipelines.find(pipeline => pipeline.id === pipelineId)?.status;
          return status && pipelineId ? (
            <StatusSwitch
              currentStatus={status}
              profileId={profileInfo.id}
              pipelineId={pipelineId}
              onChangeSuccess={(pipelineId: string, newStatus: PipelineStatus) =>
                onStatusChange?.([profileInfo.id], pipelineId, newStatus)
              }
            />
          ) : null;
        },
        size: 150,
        minSize: 150,
      },
      {
        accessorKey: ConnectionsColumns.AddToPipeline,
        header: () => <TableInnerHeader label={labelsMap[ConnectionsColumns.AddToPipeline]} />,
        enableSorting: false,
        cell: info => {
          const {profile_info: profileInfo} = info.row.original;
          return pipelineId ? (
            <AddToParticularPipelineButton
              pipelineId={pipelineId}
              profileId={profileInfo.id}
              onAddToPipelineSuccess={() => onAddToPipeline?.([profileInfo.id], pipelineId)}
            />
          ) : null;
        },
        size: 44,
        minSize: 44,
      },
      {
        accessorKey: ConnectionsColumns.JobTitle,
        header: () => <TableInnerHeader label={labelsMap[ConnectionsColumns.JobTitle]} />,
        cell: info => info.row.original.profile_info.job_title || '',
        meta: {
          truncate: true,
        },
        size: 240,
      },
      {
        accessorKey: ConnectionsColumns.JobCompanyName,
        header: () => <TableInnerHeader label={labelsMap[ConnectionsColumns.JobCompanyName]} />,
        cell: info => {
          const profileInfo = info.row.original.profile_info;
          const {
            job_company_id: companyId,
            job_company_name: companyName,
            job_company_logo_url: logoUrl,
          } = profileInfo;
          return (
            <CompanyLink
              companyName={companyName || ''}
              companyId={companyId}
              logoUrl={logoUrl}
              dataIntercomTarget="company-link"
            />
          );
        },
        size: 240,
      },
      {
        accessorKey: ConnectionsColumns.LocationName,
        enableSorting: false,
        header: () => <TableInnerHeader label={labelsMap[ConnectionsColumns.LocationName]} />,
        cell: info => info.row.original.profile_info.location_name || '',
        size: 150,
        meta: {
          truncate: true,
        },
      },
      {
        accessorKey: ConnectionsColumns.StartDate,
        enableSorting: false,
        header: () => <TableInnerHeader label={labelsMap[ConnectionsColumns.StartDate]} />,
        cell: info =>
          info.row.original.profile_info.experience?.find(
            experience => experience.company?.name === companyName
          )?.start_date || '',
        size: 150,
        meta: {
          truncate: true,
        },
      },
      {
        accessorKey: ConnectionsColumns.EndDate,
        enableSorting: false,
        header: () => <TableInnerHeader label={labelsMap[ConnectionsColumns.EndDate]} />,
        cell: info =>
          info.row.original.profile_info.experience?.find(
            experience => experience.company?.name === companyName
          )?.end_date || 'Current',
        size: 150,
        meta: {
          truncate: true,
        },
      },
      {
        accessorKey: ConnectionsColumns.Tags,
        enableSorting: false,
        header: () => <TableInnerHeader label={labelsMap[ConnectionsColumns.Tags]} />,
        cell: info => {
          const {profile_info: profileInfo, tags: assignedTags} = info.row.original;
          const {id: profileId} = profileInfo;
          const tagsDropdown = (
            <TagsTableCell
              targetId={profileId}
              assignedTags={assignedTags}
              targetType="profile"
              onAssignSuccess={tag => onAssignTag?.([profileId], tag)}
              onUnassignSuccess={tagId => onUnassignTag?.([profileId], tagId)}
            />
          );

          return (
            <PermissionChecker
              permission={Permission.TagAssign}
              fallback={<ProfileTagsList targetId={profileId} targetType="profile" tags={assignedTags} />}
              missingPlanFallback={tagsDropdown}
            >
              {tagsDropdown}
            </PermissionChecker>
          );
        },
        meta: {
          truncate: false,
        },
        size: 160,
        minSize: 80,
      },
      {
        accessorKey: ConnectionsColumns.ConnectionsStrength,
        header: () => <TableInnerHeader label={labelsMap[ConnectionsColumns.ConnectionsStrength]} />,
        enableSorting: canConnectionStrength,
        cell: info => {
          const connections = info.row.original.connections;
          const currentUserConnection = connections.find(connection => connection.user.id === currentUserId);
          const profileInfo = info.row.original.profile_info;
          const profileId = profileInfo.id;

          return teamGraph ? (
            <div className="flex justify-center">
              <PremiumFeatureWrapper
                fallback="⚠️"
                permission={Permission.ConnectionStrength}
                featureName="Connection Strength"
                location="connection score"
              >
                <ProfileLink profileId={profileId} hash="connection-strength">
                  <MultiScore connections={connections} limit={4} />
                </ProfileLink>
              </PremiumFeatureWrapper>
            </div>
          ) : currentUserConnection ? (
            <div className="flex justify-center">
              <PremiumFeatureWrapper
                fallback="⚠️"
                permission={Permission.ConnectionStrength}
                featureName="Connection Strength"
                location="connection score"
              >
                <ScoreDropdown
                  profileId={info.row.original.profile_info.id}
                  connection={currentUserConnection}
                  onChange={newValue => onModifyStrength?.([info.row.original.profile_info.id], newValue)}
                />
              </PremiumFeatureWrapper>
            </div>
          ) : null;
        },
        size: 90,
        minSize: 54,
      },
      {
        accessorKey: ConnectionsColumns.ConnectedMembersCount,
        header: () => <TableInnerHeader label={labelsMap[ConnectionsColumns.ConnectedMembersCount]} />,
        cell: info => {
          const connectedMembers = info.row.original.connectedMembers;
          return (
            <div>
              <ConnectedMembersCellContent connectedMembers={connectedMembers} />
            </div>
          );
        },
        size: 250,
        minSize: 110,
      },
    ],
    [
      pipelineId,
      teamGraph,
      canConnectionStrength,
      onAddToPipeline,
      openIntroModal,
      currentUserId,
      onModifyStrength,
      onAssignTag,
      onUnassignTag,
      onStatusChange,
      refetchProfiles,
      companyName,
    ]
  );

  const availableTableColumns = useMemo(() => {
    if (!availableColumns) {
      return columns;
    }
    return columns.filter(column => availableColumns.includes(column.accessorKey));
  }, [availableColumns, columns]);

  const table = useSwarmTable<Profile>({
    uniqueName: teamGraph ? 'networkGraph' : 'myConnections',
    rows,
    isLoaded,
    selectable: true,
    columns: availableTableColumns,
    pagination,
    sorting,
    visibleColumnsState,
    columnsOrder,
    onSelectionChange: ({checked, count, row}) => {
      segmentTrack(checked ? 'Item Selected' : 'Selection Removed', {
        label: 'profile',
        count,
        ...(row ? {profile_id: row.profile_info.id} : {}),
      });
    },
  });

  useEffect(() => table.resetRowSelection(), [searchQuery, table]);

  const returnToFirstPage = useCallback(() => table.setPageIndex(1), [table]);
  const prevSearchQueryFetchParam = usePrevious(searchQuery);
  useEffect(() => {
    if (prevSearchQueryFetchParam && !isEqual(prevSearchQueryFetchParam, searchQuery)) {
      returnToFirstPage();
    }
  }, [prevSearchQueryFetchParam, returnToFirstPage, searchQuery]);

  const prevFilter = usePrevious(filters);
  useEffect(() => {
    if (prevFilter && !isEqual(prevFilter, filters)) {
      returnToFirstPage();
    }
  }, [filters, prevFilter, returnToFirstPage]);

  useEffect(() => scrollContentToTop(), [filters, searchQuery, scrollContentToTop]);
  return table;
};

import {AuthBox, AuthBoxIcon} from '../../../../../components/auth';
import {Button} from '../../../../../components/button';
import {LinkedinIcon, SwarmLogoIcon} from '../../../../../components/icons';
import {useCallback, useEffect} from 'react';
import {getIsBlueAuthenticated} from '../../../../../utils/extensionCommunication';

type Props = {
  onSkip: () => void;
  setBlueAuthenticated: (authenticated: boolean) => void;
};

export const LoginToLinkedinScreen = ({onSkip, setBlueAuthenticated}: Props) => {
  const onConnectToLinkedin = useCallback(() => {
    window.open('https://www.linkedin.com', '_blank');
    getIsBlueAuthenticated().then(isAuthenticated => {
      setBlueAuthenticated(isAuthenticated);
    });
  }, [setBlueAuthenticated]);

  useEffect(() => {
    const blueAuthenticatedInterval = setInterval(() => {
      getIsBlueAuthenticated().then(isAuthenticated => {
        setBlueAuthenticated(isAuthenticated);
      });
    }, 2000);

    return () => clearInterval(blueAuthenticatedInterval);
  }, [setBlueAuthenticated]);

  return (
    <AuthBox headerImage={<HeaderImage />} headerTitle="Connect your Linkedin">
      <div className="flex flex-col gap-2">
        <p className="pb-4 text-base leading-6 text-swarm-gray-700">
          Make sure you're logged into LinkedIn
          <br />
          to start adding your connections.
        </p>
        <Button
          variant="primary"
          fullWidth
          onClick={onConnectToLinkedin}
          tracking={{
            label: 'login to linkedin',
            location: 'onboarding',
          }}
        >
          Login to Linkedin
        </Button>
        <Button
          variant="tertiary"
          fullWidth
          onClick={onSkip}
          tracking={{
            label: 'skip install extension',
            location: 'onboarding',
          }}
        >
          Skip for now
        </Button>
      </div>
    </AuthBox>
  );
};

const HeaderImage = () => (
  <div className="flex items-center justify-center gap-4">
    <AuthBoxIcon>
      <LinkedinIcon className="w-8" />
    </AuthBoxIcon>
    <img className="h-6" src="/images/arrow-negative.png" alt="not connected" />
    <AuthBoxIcon className="bg-swarm-gradient">
      <SwarmLogoIcon className="w-8 pl-0.5 shadow-none" />
    </AuthBoxIcon>
  </div>
);

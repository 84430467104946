import {ReactNode} from 'react';
import {Bucket, RangeString, Sort} from './common';
import {Company} from './companySchema';
import {CrmIntegrationInProfile} from './crm';
import {ErrorResponse} from './errors';
import {PlanType} from './plan';
import {ProfileSchema} from './profileSchema';
import {TagColors} from './tag';
import {Membership} from './team';

// common User type from swarm-api
export type User = {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  isAnonymized?: boolean;
};

export type TeamUser = User & {
  role: Role;
};

export enum PipelineStatus {
  suggested = 'suggested',
  introRequested = 'introRequested',
  inContact = 'inContact',
  meetingSet = 'meetingSet',
  removed = 'removed',
}

export type GetPipelinesResponseItem = {
  pipeline: PipelineCommonResponse;
  profilesCount: number;
};

export const isPipelineType = (type: unknown): type is PipelineType =>
  Object.values(PipelineType).includes(type as PipelineType);
export enum PipelineType {
  recruiting = 'recruiting',
  sales = 'sales',
  fundraising = 'fundraising',
  other = 'other',
}
export type GetPipelinesResponse = {
  items: GetPipelinesResponseItem[];
  teamId: string;
  meta: SearchMeta;
};
export type GetPipelinesRequest = {
  sort?: Sort;
} & Page;

export type PipelineCommonResponse = {
  id: string;
  title: string;
  descriptionUrl: string;
  pipelineType: PipelineType;
  creator: User;
  teamId: string;
  createdAt: string;
  updatedAt: string;
  forwardableTemplate: string;
  private: boolean;
  savedSearch: string;
};

export type CreatePipelineRequest = {
  title: string;
  descriptionUrl: string;
  pipelineType: PipelineType;
  forwardableTemplate?: string;
  private: boolean;
  savedSearch: string;
};

export type GetProfilesRequest = {
  'filter[status]'?: string[];
} & Page;

export type NotesCount = {
  total: number;
  private: number;
  team: number;
};

export type GetProfilesResponse = {
  pipeline: PipelineCommonResponse;
  profiles: ProfileRow[];
  notesCount: NotesCount;
  meta: SearchMeta;
};

export type ProfileRow = {
  status: PipelineStatus;
  updatedAt: string;
  profileId: string;
  pipelineId: string;
  profile: ProfileSchema;
  connectedMembers: User[];
  connections?: ProfileRowConnection[];
  notesCount: NotesCount;
};

type ProfileRowConnection = {
  id: string;
  user: User;
  strength: ScoreValue;
};
// SUGGESTIONS

type Creator = {
  creatorId: string;
  creator: User;
};

export type ProfileInPipelinesSuggestion = {
  id: string;
  title: string;
  teamId: string;
  containProfile: boolean;
  status: PipelineStatus | '';
  suggestions: Creator[];
  private: boolean;
};

export type Source = {
  origin: string;
  network: string;
};

export type ScoreValue = 0 | 1 | 2 | 3;

export type Connection = {
  id: string;
  user: User & {linkedInUrl: string};
  sources: Source[];
  strength: ScoreValue;
  updated_at?: string;
  created_at?: string;
  calendar_meeting_count?: number;
};

export type UpdateProfileRequest = {
  status: PipelineStatus;
};

export type ProfileInPipelinesSuggestions = ProfileInPipelinesSuggestion[];

export type CreateBulkSuggestionsInput = {
  profileIDs: string[];
};

export type Profile = {
  profile_info: ProfileSchema;
  connectedMembers: User[];
  pipelines: ProfileInPipelinesSuggestions;
  connections: Connection[];
  tags: TagSimple[];
  crms: CrmIntegrationInProfile[];
};

export enum InviteStatus {
  Pending = 'pending',
  Cancelled = 'cancelled',
  Accepted = 'accepted',
  Rejected = 'rejected',
}

export enum Role {
  Owner = 'owner',
  Admin = 'admin',
  Contributor = 'contributor',
  Guest = 'guest',
  Connector = 'connector',
}

export type Invite = {
  created_at: string;
  creator_id: string;
  invite_email: string;
  invite_id: string;
  invite_role: Role;
  invite_status: InviteStatus;
  team_id: string;
  updated_at: string;
  team_name?: string;
  creator_name?: string;
};

export type Member = {
  id: string;
  user: TeamUser;
  teamId: string;
  connectionCount: number;
};

export type SearchMeta = Page & {
  totalCount: number;
};

export type CompaniesResponse = {
  companies: Company[];
  meta: SearchMeta;
};

export enum ScopeFilterOptions {
  InNetwork = 'NetworkScopeInNetwork',
  OutOfNetwork = 'NetworkScopeOutOfNetwork',
}

export type CompaniesFilterOptions = {
  'filters[query][]'?: string[];
  'filters[sizeClass][]'?: RangeString[];
  'filters[industry][]'?: string[];
  'filters[locationName][]'?: string[];
  'filters[scope][]'?: ScopeFilterOptions[];
  'filters[tags][]'?: string[];
  'filters[crms][]'?: string[];
};

export type CompaniesRequest = CompaniesFilterOptions &
  Page & {
    sort?: Sort;
  };

export type CompaniesAggregateResponse = {
  fieldName: string;
  buckets: Bucket[];
};

export type CompaniesAggregateRequest = CompaniesFilterOptions & {
  query: string;
  fieldName: 'industry' | 'locationName' | 'sizeClass' | 'scope';
};

export enum TaskStatus {
  StatusCreated = 'StatusCreated',
  StatusEnqueued = 'StatusEnqueued',
  StatusRunning = 'StatusRunning',
  StatusDone = 'StatusDone',
}

export enum TaskResult {
  OK = 'OK',
  Failed = 'Failed',
}

export enum TaskDataProvider {
  Google = 'Google',
  LinkedInCSV = 'LinkedInCSV',
  LinkedInPlugin = 'LinkedInPlugin',
  WorkOverlaps = 'WorkOverlaps',
  UserProfile = 'UserProfile',
}

export type Task = {
  accountId: string;
  createdAt: string;
  description: string;
  progress: {
    enriched: number;
    skipped: number;
    total: number;
  };
  result: TaskResult;
  status: TaskStatus;
  dataProvider: TaskDataProvider;
  taskId: string;
  updatedAt: string;
};

export type GetTasksResponse = Task[];

export type UserSessionData = User & {
  linkedInUrl: string;
  memberships: MembershipWithPermission[];
  intercomHash: string;
};
export enum Permission {
  HasTeamAccess = 'HasTeamAccess',
  TeamMember = 'TeamMember',
  TeamMemberUpdate = 'TeamMemberUpdate',
  Company = 'Company',
  TeamPipelineUpdate = 'TeamPipelineUpdate',
  Pipeline = 'Pipeline',
  PlanUpdate = 'PlanUpdate',
  TeamSettingsUpdate = 'TeamSettingsUpdate',
  ConnectionStrength = 'ConnectionStrength',
  ProfileContactDataRead = 'ProfileContactDataRead',
  TeamNoteDelete = 'TeamNoteDelete',
  Tag = 'Tag',
  TagAssign = 'TagAssign',
  TagUpdate = 'TagUpdate',
  TeamTagUpdate = 'TeamTagUpdate',
  CRM = 'CRM',
  CRMIntegration = 'CRMIntegration',
  CRMExport = 'CRMExport',
  ShowGettingStartedVideo = 'ShowGettingStartedVideo',
}
export type PermissionAccess = {
  permission: Permission;
  allowed: boolean;
  availableInRole: Role[];
  availableInPlan: PlanType[];
};

type MembershipWithPermission = Membership & {
  permissionAccesses: PermissionAccess[];
};

// share-network invitations
export type GetInvitationsResponse = {
  invitations: ShareNetworkInvitation[];
};
export type SendInvitationResponse = {
  invitations: ShareNetworkInvitation[];
};

export type ShareNetworkInvitation = {
  id: string;
  userId: string;
  inviteeEmail: string;
  note: string;
  validUntil: string;
  createdAt: string;
  updatedAt: string;
  user?: User;
};

/** @see {isShareNetworkInvitationWithUser} ts-auto-guard:type-guard */
export type ShareNetworkInvitationWithUser = Required<ShareNetworkInvitation>;

export type SendInvitationRequest = {
  inviteeEmails: string[];
  note: string;
};

export type SendInvitationRequestError = ErrorResponse;

export enum InviteMemberErrorCodes {
  'user_is_member' = 'user_is_member',
  'member_limit_reached' = 'member_limit_reached',
}

type InviteMemberErrorDetail = {
  code: InviteMemberErrorCodes;
};
export type InviteMemberErrorResponse = ErrorResponse<InviteMemberErrorDetail>;

type Page = {
  offset: number;
  limit: number;
};

export type MagicLinkCode = {
  createdAt: string;
  userId: string;
  valid: boolean;
  validUntil: string;
  value: string;
};

export type AcceptMagicLinkCodeInput = {
  code: string;
};
export type GetNotesResponse = {
  items: NoteResponse[];
  meta: SearchMeta;
};

export type GetNotesRequest = {
  teamId?: string;
  profileId: string;
  limit: number;
  offset: number;
};

export type GetNotesCountRequest = {
  teamId?: string;
  profileId: string;
};

export type GetNotesCountResponse = NotesCount & {
  profileId: string;
};

export type CreateNoteRequest = {
  content: string;
  profileId: string;
  teamId?: string;
};

export type EditNoteRequest = {
  content: string;
};
export type NoteResponse = {
  content: string;
  profileId: string;
  teamId?: string;
  creator: User;
  id: string;
  updatedAt: string;
  createdAt: string;
};
export type FilterOptionsItem = {
  label: string;
  enhancedLabel?: ReactNode;
  value: string;
  count?: number;
};

export type GetTagsListRequest = {
  sort?: string[];
  query?: string;
};

export type GetTagsResponse = {
  items: TagListItem[];
  meta: SearchMeta;
};

export type TagItem = {
  id: string;
  name: string;
  color: TagColors;
  description: string;
  creator: User;
  createdAt: string;
  updatedAt: string;
};

export type TagListItem = {
  tag: TagItem;
  count: {
    profile: number;
    company: number;
  };
};

export type GetTagsSimpleResponse = {
  items: TagSimple[];
};

export type TagSimple = {
  id: string;
  name: string;
  color: TagColors;
};

export type CreateTagInput = {
  name: string;
  color: string;
  description?: string;
};

export type AssignTagRequestParams = {
  targetIds: string[];
  targetType: 'company' | 'profile';
};

export const enum SocialMediaEnum {
  aboutme = 'aboutme',
  angellist = 'angellist',
  behance = 'behance',
  crunchbase = 'crunchbase',
  dribbble = 'dribbble',
  ello = 'ello',
  facebook = 'facebook',
  flickr = 'flickr',
  foursquare = 'foursquare',
  github = 'github',
  gitlab = 'gitlab',
  google = 'google',
  gravatar = 'gravatar',
  indeed = 'indeed',
  instagram = 'instagram',
  klout = 'klout',
  linkedin = 'linkedin',
  meetup = 'meetup',
  myspace = 'myspace',
  pinterest = 'pinterest',
  quora = 'quora',
  reddit = 'reddit',
  soundcloud = 'soundcloud',
  stackoverflow = 'stackoverflow',
  twitter = 'twitter',
  vimeo = 'vimeo',
  wordpress = 'wordpress',
  xing = 'xing',
  youtube = 'youtube',
}

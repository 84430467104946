import cs from 'classnames';
import {parse} from 'papaparse';
import {ChangeEvent, useCallback, useEffect, useRef, useState} from 'react';
import {SubmitHandler, useForm} from 'react-hook-form';
import {getJWT} from '../../../providers';
import {getConfig, trackError} from '../../../utils';
import {Button} from '../../button';
import {trackCancelButtonOnStrategyView, trackStartImportClick} from '../analytics.utils';
import {FilePickerContent} from '../FilePickerContent';

const {apiUrl = ''} = getConfig();

const {learnMoreCsvDefinedEnabled} = getConfig();

type Props = {
  onClose: () => void;
  goToSuccessScreen: () => void;
};

const isValidExtension = (filename: string): boolean => {
  const validExtension = '.csv';
  const fileExt = filename.substring(filename.lastIndexOf('.'));
  if (fileExt.indexOf(validExtension) < 0) {
    return false;
  }
  return true;
};

export const ImportConnectionsCSVForm = ({onClose, goToSuccessScreen}: Props) => {
  const [total, setTotal] = useState(0);
  const [error, setError] = useState(false);
  const [isDraggedOverArea, setIsDraggedOverArea] = useState(false);

  const filePickerRef = useRef<HTMLLabelElement>(null);

  const {
    register,
    handleSubmit,
    formState: {isSubmitting},
    resetField,
  } = useForm<{file: File[]}>();

  const handleDragEnter = useCallback(() => setIsDraggedOverArea(true), []);
  const handleDragLeave = useCallback(() => setIsDraggedOverArea(false), []);

  useEffect(() => {
    let filePickerElement = filePickerRef.current;
    if (filePickerRef.current) {
      filePickerElement = filePickerRef.current;
      filePickerElement.addEventListener('dragenter', handleDragEnter);
      filePickerElement.addEventListener('dragleave', handleDragLeave);
      filePickerElement.addEventListener('drop', handleDragLeave);
    }

    return () => {
      if (filePickerElement) {
        filePickerElement.removeEventListener('dragenter', handleDragEnter);
        filePickerElement.removeEventListener('dragleave', handleDragLeave);
        filePickerElement.removeEventListener('drop', handleDragLeave);
      }
    };
  }, [handleDragEnter, handleDragLeave]);

  const onSubmit: SubmitHandler<{file: File[]}> = useCallback(
    async data => {
      const formData = new FormData();
      formData.append('file', data.file[0]);

      const token = await getJWT();

      // TODO: use `apiClient` instance and make custom useQuery hook instead of fetch
      // TODO: move handler to /src/api/imports.ts
      await fetch(apiUrl + '/profiles/imports/csv', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${token}`,
        },
        mode: 'cors',
        body: formData,
      })
        .then(res => {
          if (res.ok) {
            return res.json();
          } else {
            throw new Error('Upload CSV error ocurred');
          }
        })
        .then(goToSuccessScreen)
        .catch(e => {
          setError(true);
          trackError(e);
        });
    },
    [goToSuccessScreen]
  );

  const onFileInputChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (isValidExtension(e.target.value)) {
        const file = e.target.files?.[0];
        if (file) {
          parse(file, {complete: results => setTotal(results.data.length)});
        }
      } else {
        resetField('file');
        setError(true);
      }
    },
    [resetField]
  );

  const onImportClick = useCallback(() => {
    handleSubmit(onSubmit)();
    trackStartImportClick('csv');
  }, [handleSubmit, onSubmit]);

  const onCancelButtonClick = useCallback(() => {
    onClose();
    trackCancelButtonOnStrategyView('csv');
  }, [onClose]);

  return (
    <>
      <form className="flex w-full items-center justify-center">
        <label
          ref={filePickerRef}
          htmlFor="dropzone-file"
          className={cs(
            'flex flex-col items-center justify-center',
            'h-48 w-full',
            'rounded-lg',
            'hover:bg-swarm-gray-100',
            'border-2',
            'relative',
            {
              'border-dashed border-swarm-gray-500': !total,
              'border-solid border-swarm-green': total > 1,
              'border-swarm-red': error,
              '!border-solid bg-swarm-gray-100': isDraggedOverArea,
            },
            'cursor-pointer'
          )}
        >
          <div className="flex flex-col items-center justify-center">
            <FilePickerContent isLoading={isSubmitting} hasError={error} isFileAttached={Boolean(total)} />
          </div>

          <input
            id="dropzone-file"
            accept=".csv"
            type="file"
            {...register('file', {
              required: true,
              onChange: onFileInputChange,
            })}
            className="absolute inset-0 cursor-pointer opacity-0"
          />
        </label>
      </form>
      {learnMoreCsvDefinedEnabled && (
        <p className="mt-8">
          Have questions?{' '}
          <a
            // TODO adjust link when it will be available
            href="https://community.theswarm.com/c/getting-started/how-to-import-my-connections-4c6b74"
            target="_blank"
            className="text-swarm-black underline"
            rel="noreferrer"
          >
            Learn more
          </a>{' '}
          about how to import data from CSV.
        </p>
      )}

      <div className="mt-8 flex justify-between">
        <Button onClick={onCancelButtonClick}>Cancel</Button>

        {total > 0 && (
          <Button
            onClick={onImportClick}
            variant="primary"
            type="submit"
            loading={isSubmitting}
            disabled={error}
            icon="&rarr;"
            reversedOrder
          >
            Import
          </Button>
        )}
      </div>
    </>
  );
};

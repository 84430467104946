import {AxiosError} from 'axios';
import {useMutation, useQuery, UseQueryOptions} from 'react-query';
import {UseMutationOptions} from 'react-query/types/react/types';
import {
  AcceptMagicLinkCodeInput,
  ErrorResponse,
  GetInvitationsResponse,
  MagicLinkCode,
  SendInvitationRequest,
  SendInvitationResponse,
} from '../types';
import {apiClient} from './apiClient';

const getNetworkSharingInvitations = (): Promise<GetInvitationsResponse> =>
  apiClient.get<GetInvitationsResponse>('/network-sharing/invitations').then(res => res.data);

export const useQueryNetworkSharingInvitations = (options: UseQueryOptions<GetInvitationsResponse> = {}) =>
  useQuery<GetInvitationsResponse>('shareNetworkInvitations', () => getNetworkSharingInvitations(), options);

export const sendNetworkSharingInvitation = (
  reqData: SendInvitationRequest
): Promise<SendInvitationResponse> =>
  apiClient.post<SendInvitationResponse>('/network-sharing/invitations', reqData).then(res => res.data);

export const useMutationSendNetworkSharingInvitation = (
  options: UseMutationOptions<SendInvitationResponse, AxiosError<ErrorResponse>, SendInvitationRequest>
) =>
  useMutation<SendInvitationResponse, AxiosError<ErrorResponse>, SendInvitationRequest>(
    ({inviteeEmails, note}) => sendNetworkSharingInvitation({inviteeEmails, note}),
    options
  );

export const generateMagicLinkCode = (): Promise<MagicLinkCode> =>
  apiClient.post('/network-sharing/public-codes').then(res => res.data);

export const useMutationGenerateMagicLinkCode = (
  options?: UseMutationOptions<MagicLinkCode, AxiosError<ErrorResponse>>
) => useMutation<MagicLinkCode, AxiosError<ErrorResponse>>(() => generateMagicLinkCode(), options);

export const acceptMagicLinkCode = (code: string): Promise<void> =>
  apiClient.post(`/network-sharing/public-codes/${code}/redeem`).then(res => res.data);

export const useMutationAcceptMagicLinkCode = (
  options?: UseMutationOptions<unknown, AxiosError<ErrorResponse>, AcceptMagicLinkCodeInput>
) =>
  useMutation<unknown, AxiosError<ErrorResponse>, AcceptMagicLinkCodeInput>(
    ({code}) => acceptMagicLinkCode(code),
    options
  );

import {useCallback, useEffect, useMemo, useState} from 'react';
import {useQueryCompanyAggregate, useQueryCrmIntegrations, useQueryTagsSimple} from '../../api';
import {
  FiltersWrapper,
  getCrmSourceOptions,
  getScopeOptions,
  getTagsOptions,
  mapSizeOptions,
  MultipleOptionsFilter,
} from '../../components/filters';
import {PermissionChecker} from '../../components/permission';
import {EmptyTagsListInfo} from '../../components/tags';
import {useCheckPermission, useCurrentTeam} from '../../hooks';
import {
  Bucket,
  CompaniesAggregateRequest,
  CompaniesFilterOptions,
  CompaniesRequest,
  FilterOptionsItem,
  Permission,
} from '../../types';

type Props = {
  searchParams: CompaniesRequest;
  setFilter: (key: keyof CompaniesFilterOptions, value: string[]) => unknown;
};

function bucketToFilterOption(bucket: Bucket) {
  return {
    label: bucket.key,
    value: bucket.key,
    count: bucket.count,
  };
}

type NonAggregateFieldNames = 'tags' | 'crms';
type PossibleFieldNames = CompaniesAggregateRequest['fieldName'] | NonAggregateFieldNames;

const useFilterPropsWithDirectOptions = (
  fieldName: PossibleFieldNames,
  searchParams: CompaniesRequest,
  setFilter: (key: keyof CompaniesFilterOptions, value: string[]) => unknown,
  directOptions: FilterOptionsItem[]
) => {
  const [externalIsOpen, setExternalIsOpen] = useState(false);
  const [searchPhrase, setSearchPhrase] = useState('');
  const selectedOptionsValues = searchParams[`filters[${fieldName}][]`];

  const onChange = useCallback(
    (filterValue: string[]) => setFilter(`filters[${fieldName}][]`, filterValue),
    [fieldName, setFilter]
  );

  const filterOptions = searchPhrase.trim()
    ? directOptions.filter(option => option.label.toLowerCase().includes(searchPhrase.toLowerCase()))
    : directOptions;

  return {
    filterOptions,
    onSearchPhraseChange: setSearchPhrase,
    searchPhrase,
    selectedOptionsValues,
    onChange,
    externalIsOpen,
    setExternalIsOpen,
  };
};

const useFilterProps = (
  fieldName: CompaniesAggregateRequest['fieldName'],
  searchParams: CompaniesRequest,
  setFilter: (key: keyof CompaniesFilterOptions, value: string[]) => unknown
) => {
  const [externalIsOpen, setExternalIsOpen] = useState(false);
  const [searchPhrase, setSearchPhrase] = useState('');
  const selectedOptionsValues = searchParams[`filters[${fieldName}][]`];
  const aggregatesSearchParams: CompaniesAggregateRequest = useMemo(
    () => ({...searchParams, fieldName, query: searchPhrase}),
    [fieldName, searchParams, searchPhrase]
  );
  const {id: teamId} = useCurrentTeam();

  const {data: resp} = useQueryCompanyAggregate(teamId, aggregatesSearchParams, {enabled: externalIsOpen});
  const filterOptions = useMemo(() => {
    const selectedOptions =
      selectedOptionsValues
        ?.map(value => resp?.buckets.find(bucket => bucket.key === value) || {key: value, count: 0})
        .sort((a, b) => b.count - a.count) || [];
    const buckets: Bucket[] = [...selectedOptions, ...(resp?.buckets ?? [])];
    const uniqueBuckets = [...new Map(buckets.map(item => [item.key, item])).values()];

    return uniqueBuckets.map(bucketToFilterOption);
  }, [resp?.buckets, selectedOptionsValues]);

  const onChange = useCallback(
    (filterValue: string[]) => setFilter(`filters[${fieldName}][]`, filterValue),
    [fieldName, setFilter]
  );

  return {
    filterOptions,
    onSearchPhraseChange: setSearchPhrase,
    searchPhrase,
    selectedOptionsValues,
    onChange,
    externalIsOpen,
    setExternalIsOpen,
  };
};

export const Filters = ({searchParams, setFilter}: Props) => {
  const [isProfileTagsFilterOpen, setIsProfileTagsFilterOpen] = useState(false);
  const sizeClassProps = useFilterProps('sizeClass', searchParams, setFilter);
  const industryProps = useFilterProps('industry', searchParams, setFilter);
  const locationNameProps = useFilterProps('locationName', searchParams, setFilter);
  const scopeProps = useFilterProps('scope', searchParams, setFilter);
  const hasCrmPermission = useCheckPermission(Permission.CRM);

  const {data: tags, refetch: refetchTags} = useQueryTagsSimple();
  const {data: crms} = useQueryCrmIntegrations({enabled: hasCrmPermission});

  useEffect(() => {
    if (isProfileTagsFilterOpen) {
      refetchTags();
    }
  }, [isProfileTagsFilterOpen, refetchTags]);

  const sizeClassOptions = useMemo(
    () => mapSizeOptions(sizeClassProps.filterOptions),
    [sizeClassProps.filterOptions]
  );

  const scopeOptions = useMemo(() => getScopeOptions(scopeProps.filterOptions), [scopeProps.filterOptions]);
  const tagsOptions = useMemo(() => getTagsOptions(tags?.items || []), [tags?.items]);
  const tagsProps = useFilterPropsWithDirectOptions('tags', searchParams, setFilter, tagsOptions);
  const crmSourceOptions = useMemo(() => getCrmSourceOptions(crms?.integrations || []), [crms?.integrations]);
  const crmSourceProps = useFilterPropsWithDirectOptions('crms', searchParams, setFilter, crmSourceOptions);

  return (
    <FiltersWrapper>
      <MultipleOptionsFilter title="Size" {...sizeClassProps} filterOptions={sizeClassOptions} />
      <MultipleOptionsFilter title="Industry" searchable {...industryProps} />
      <MultipleOptionsFilter title="Headquarters" searchable {...locationNameProps} />
      <MultipleOptionsFilter title="Network Status" {...scopeProps} filterOptions={scopeOptions} />
      <MultipleOptionsFilter
        title="Company Tags"
        {...tagsProps}
        clientSideSearchable
        emptyOptionsFallback={<EmptyTagsListInfo />}
        externalIsOpen={isProfileTagsFilterOpen}
        setExternalIsOpen={setIsProfileTagsFilterOpen}
      />
      <PermissionChecker permission={Permission.CRM}>
        <MultipleOptionsFilter
          title="CRM Source"
          {...crmSourceProps}
          emptyOptionsFallback={
            <div className="flex flex-col items-center justify-center gap-2 py-4">No integrations found</div>
          }
        />
      </PermissionChecker>
    </FiltersWrapper>
  );
};

import cs from 'classnames';
import {useCallback, useState} from 'react';
import {FiCheck as CheckIcon, FiChevronDown as ChevronDownIcon} from 'react-icons/fi';
import {useMutationEditConnectionStrength} from '../../api';
import {useCurrentUser} from '../../hooks';
import {BffConnection, ScoreValue} from '../../types';
import {LoadingSpinner} from '../animations';
import {Dropdown} from '../dropdown';
import {DropdownItem} from '../dropdown/DropdownItem';
import {Score, levels} from './Score';

type Props = {
  connection: BffConnection;
  profileId: string;
  big?: boolean;
  onChange?: (value: ScoreValue) => unknown;
};
export const BffScoreDropdown = (props: Props) => {
  const {profileId, connection, big, onChange} = props;
  const {id: userId} = useCurrentUser();
  const [loadingValue, setLoadingValue] = useState<ScoreValue | null>(null);
  const [isOpen, setIsOpen] = useState(false);
  const {
    id: connectionId,
    strength,
    user: {id: connectionUserId},
  } = connection;
  const {mutateAsync, isLoading} = useMutationEditConnectionStrength({});
  const onChangeValue = useCallback(
    async (strength: ScoreValue) => {
      setLoadingValue(strength);
      await mutateAsync({connectionId, strength, profileId});
      await onChange?.(strength);
      setIsOpen(false);
      setLoadingValue(null);
    },
    [connectionId, mutateAsync, onChange, profileId]
  );
  const readonly = userId !== connectionUserId;
  const score = <Score value={strength} withTooltip={!big} showLabel={big} />;

  if (readonly) {
    return score;
  }
  const possibleValues = [3, 2, 1, 0] as const;
  return (
    <Dropdown
      narrow
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      renderDropdownTrigger={() => (
        <div className="flex w-full items-center">
          {score}
          <div className={cs('flex items-center gap-1 text-sm')}>
            <ChevronDownIcon />
            {big && <div>Edit</div>}
          </div>
        </div>
      )}
    >
      {possibleValues.map(value => (
        <DropdownItem
          key={value}
          icon={<Score value={value} />}
          rightIcon={
            loadingValue === value ? (
              <div className="mx-0.5 w-6">
                <LoadingSpinner size="x-small" className="m-auto" />
              </div>
            ) : (
              <CheckIcon className={cs({'opacity-0': value !== strength})} />
            )
          }
          label={levels[value]}
          noHover={isLoading || value === strength}
          type={{
            name: 'button',
            onClick: () => onChangeValue(value),
            disabled: isLoading || value === strength,
          }}
        />
      ))}
    </Dropdown>
  );
};

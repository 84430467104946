import {isEqual} from 'lodash';
import {useEffect} from 'react';
import {useTableFilters} from '../components/table';
import {segmentTrack} from '../utils';
import {usePrevious} from './usePrevious';

type UseOnFilteringEventProps = {
  filters: ReturnType<typeof useTableFilters>['filters'];
  filtersToOmit?: string[];
  searchQuery?: string;
};
export const useOnFilteringEvent = ({filters, filtersToOmit, searchQuery}: UseOnFilteringEventProps) => {
  const previousFilters = usePrevious(filters);
  const previousSearchQuery = usePrevious(searchQuery);

  useEffect(() => {
    if (isEqual(filters, previousFilters) && isEqual(searchQuery, previousSearchQuery)) {
      return;
    }

    const eventPayload: Record<string, unknown> = {};

    if (searchQuery) {
      eventPayload.query = searchQuery;
    }

    const filtersToTrack = {...filters};
    if (filtersToOmit) {
      filtersToOmit.forEach(filter => {
        delete filtersToTrack[filter];
      });
    }

    for (const [key, value] of Object.entries(filtersToTrack)) {
      eventPayload[key] = value;
    }

    if (Object.keys(eventPayload).length > 0) {
      segmentTrack('Search Executed', eventPayload);
    }
  }, [filters, filtersToOmit, previousFilters, previousSearchQuery, searchQuery]);
};

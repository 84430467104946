import {AxiosResponse} from 'axios';
import {useQuery, UseQueryOptions} from 'react-query';
import {Invite, InviteStatus, Role} from '../types';
import {apiClient} from './apiClient';

type Invites = Invite[];
export type UserRespondedStatus = InviteStatus.Rejected | InviteStatus.Accepted;
type UpdateInput = {status: UserRespondedStatus};

export const updateInvite = async (inviteId: string, input: UpdateInput): Promise<Invite | undefined> => {
  type Output = {data: Invite} | undefined; // TODO: probably there is a bug in BE (SWRM-385) and sometimes it returns empty 200 response
  const resp = await apiClient.patch<Output, AxiosResponse<Output>, UpdateInput>(
    `/invites/${inviteId}`,
    input
  );

  return resp.data?.data;
};

type DeleteInviteParams = {
  teamId: string;
  inviteId: string;
};
export const deleteInvite = (params: DeleteInviteParams): Promise<void> =>
  apiClient.delete(`/teams/${params.teamId}/invites/${params.inviteId}`);

export const getMyInvites = async (): Promise<Invites> => {
  const resp = await apiClient.get<Invites>('/invites');

  return resp.data;
};

export const useQueryMyInvites = (options?: UseQueryOptions<Invites>) =>
  useQuery<Invites>('myInvites', getMyInvites, {
    keepPreviousData: true,
    ...options,
  });

type PostInput = {
  email: string;
  role: Role;
  note?: string;
};

export const inviteMember = async (teamId: string, input: PostInput): Promise<unknown> =>
  apiClient.post<void, AxiosResponse<void>, PostInput>(`/teams/${teamId}/invites`, input);
export const getTeamInvites = (teamId?: string) =>
  apiClient.get<Invite[]>(`/teams/${teamId}/invites`).then(res => res.data);

export const resendInvite = (teamId: string, inviteId: string) =>
  apiClient.post<Invite>(`/teams/${teamId}/invites/${inviteId}/resend`).then(res => res.data);

export const useGetTeamInvites = (teamId: string, refetchOnMount?: boolean) =>
  useQuery(['team', teamId, 'invites'], () => getTeamInvites(teamId), {
    enabled: Boolean(teamId),
    refetchOnMount,
  });

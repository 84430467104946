import {StringListFilterValue} from '../../../components/filters';
import {StatusTag} from '../../../components/status-tag';
import {Profile} from '../../../types';
import {statuses} from '../../../utils';

type Props = {
  onChange: (filterValue: StringListFilterValue | undefined) => void;
  value: StringListFilterValue | undefined;
  profilesToCount?: Profile[];
  pipelineId: string;
  showCounts: boolean;
};

export const StatusFilter = ({onChange, value, profilesToCount, pipelineId, showCounts}: Props) => {
  const onFilterChange = (optionValue: string, isChecked: boolean) => {
    const updatedValue = isChecked
      ? [...(value || []), optionValue]
      : (value || []).filter(val => val !== optionValue);
    onChange(updatedValue.length ? updatedValue : undefined);
  };

  const statusesWithCounts = statuses.map(status => {
    const count = profilesToCount?.filter(
      profile => profile.pipelines.find(pipeline => pipeline.id === pipelineId)?.status === status.value
    ).length;
    return {
      value: status.value,
      count,
    };
  });

  return (
    <div className="flex items-center gap-2 pt-2">
      {statusesWithCounts.map((option, index) => (
        <label htmlFor={option.value} key={index}>
          <div className="flex cursor-pointer items-center gap-2 rounded border border-swarm-gray-400 p-2">
            <input
              type="checkbox"
              value={option.value}
              checked={(value || []).includes(option.value)}
              onChange={e => onFilterChange(option.value, e.target.checked)}
              id={option.value}
            />
            <StatusTag value={option.value} />
            {showCounts && <span className="text-sm font-semibold text-swarm-black">{option.count}</span>}
          </div>
        </label>
      ))}
    </div>
  );
};

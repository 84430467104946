import cs from 'classnames';
import {cloneElement, ReactElement} from 'react';
import {FiX as RemoveIcon} from 'react-icons/fi';
import {Pill} from '../../pill';
import {Tooltip} from '../../tooltip';
import {FilterState, FilterType} from '../types';
import Styles from './AppliedFilter.module.scss';

type Props = {
  config: FilterType;
  onRemove?: () => void;
  isOpen?: boolean;
  filterState: FilterState;
};

export const AppliedFilter = ({config, filterState, onRemove, isOpen}: Props) => {
  const {operator, value, selectedOptions} = filterState;
  const {title, icon} = config;
  const emptyValue = isOpen ? '...' : 'missing value';

  const iconElement = cloneElement(icon?.() as ReactElement, {className: 'size-4'});

  const labels = selectedOptions?.map(option => option.label) || value;

  const valueElement =
    value.length === 0 ? (
      emptyValue
    ) : value.length > 2 ? (
      <Tooltip content={labels.join(', ')} hideHovered>
        <Pill size="small">{value.length}</Pill>
      </Tooltip>
    ) : (
      labels.map((label, index) => (
        <Pill size="small" key={index}>
          {label}
        </Pill>
      ))
    );

  return (
    <div
      className={cs(Styles.appliedFilter, {
        [Styles.openFilter]: isOpen,
        [Styles.emptyValueAlert]: !isOpen && value.length === 0,
      })}
    >
      {iconElement}
      <span className={Styles.title}>{title}</span>
      <span>{operator}</span>
      {valueElement}
      {onRemove && (
        <div className="ml-2">
          <Tooltip content="Clear filter" offset={1}>
            <div className={Styles.removeIconWrapper} onClick={onRemove}>
              <RemoveIcon className={Styles.removeIcon} />
            </div>
          </Tooltip>
        </div>
      )}
    </div>
  );
};

import cs from 'classnames';
import {ReactNode} from 'react';
import {capitalizeString, segmentTrack} from '../../utils';
import Styles from './Dropdown.module.scss';

export type DropdownItemProps = {
  label: ReactNode;
  type:
    | {name: 'button'; onClick: () => void; disabled?: boolean; variant?: 'danger' | 'success'}
    | {name: 'checkbox'; checked: boolean; onClick: () => unknown; badge?: number}
    | {name: 'radio'; checked: boolean; onClick: () => unknown; badge?: number}
    | {name: 'a'; href: string}
    | {
        name: 'custom';
        onClick: () => unknown;
        disabled?: boolean;
        element: ReactNode;
      };
  noHover?: boolean;
  icon?: ReactNode;
  rightIcon?: ReactNode;
  info?: string;
  className?: string;
  dataIntercomTarget?: string;
  tracking?: {
    label: string;
    location?: string;
  };
};

export const DropdownItem = ({
  label,
  type,
  noHover,
  icon,
  rightIcon,
  info,
  className,
  dataIntercomTarget,
  tracking,
}: DropdownItemProps) => {
  const handleOnClick = () => {
    if (tracking) {
      const {label, location} = tracking;
      segmentTrack('Button Clicked', {
        label,
        location,
      });
    }
    if (type.name !== 'a') {
      type.onClick();
    }
  };
  const renderDropdownItem = (item: DropdownItemProps['type']) => {
    switch (item.name) {
      case 'button': {
        const Content = () => (
          <>
            {icon && <span className={Styles.dropdownItemIcon}>{icon}</span>}
            <Label>{label}</Label>
            {rightIcon}
          </>
        );
        return (
          <button
            onClick={handleOnClick}
            className={cs(
              Styles[`dropdownItemButton${info ? 'WithInfo' : ''}`],
              Styles[item.variant || ''],
              {[Styles.disabled]: item.disabled},
              className
            )}
            disabled={item.disabled}
            data-intercom-target={dataIntercomTarget || label}
          >
            {info ? (
              <>
                <div className={Styles.buttonContent}>
                  <Content />
                </div>
                <div className={Styles.additionalInfo}>{info}</div>
              </>
            ) : (
              <Content />
            )}
          </button>
        );
      }
      case 'checkbox':
        return (
          <button
            onClick={handleOnClick}
            className={cs(
              Styles.dropdownItemCheckboxButton,
              {[Styles.dropdownItemCheckboxButtonChecked]: item.checked},
              className
            )}
          >
            <input type="checkbox" checked={item.checked} readOnly className="mr-2" />
            <Label>{label}</Label>
            {typeof item.badge !== 'undefined' && <Badge value={item.badge} />}
          </button>
        );
      case 'radio':
        return (
          <button
            onClick={handleOnClick}
            className={cs(
              Styles.dropdownItemRadioButton,
              {[Styles.dropdownItemRadioButtonChecked]: item.checked},
              className
            )}
          >
            <input type="radio" checked={item.checked} readOnly className="mr-2" />
            <Label>{label}</Label>
            {item.badge && <Badge value={item.badge} />}
          </button>
        );
      case 'a':
        return (
          <a href={item.href} className={cs(Styles.dropdownItemButton, className)}>
            {icon && <span className={Styles.dropdownItemIcon}>{icon}</span>}
            {label}
            {rightIcon}
          </a>
        );
      case 'custom':
        return (
          <button
            onClick={handleOnClick}
            className={cs(Styles.dropdownItemButton, className, {[Styles.disabled]: item.disabled})}
            disabled={item.disabled}
            data-intercom-target={dataIntercomTarget || label}
          >
            {icon && <span className={Styles.dropdownItemIcon}>{icon}</span>}
            {item.element}
            {rightIcon}
          </button>
        );
      default:
        return null;
    }
  };

  const itemTypeClass = `dropdownItem${capitalizeString(type.name)}`;
  return (
    <div className={cs(Styles.dropdownItem, Styles[itemTypeClass], {[Styles.dropdownItemNoHover]: noHover})}>
      {renderDropdownItem(type)}
    </div>
  );
};

const Label = ({children}: {children: ReactNode}) => (
  <span className="grow text-left leading-snug">{children}</span>
);

const Badge = ({value}: {value: number}) => (
  <span className={Styles.badge}>{value.toLocaleString('en-US')}</span>
);

import cs from 'classnames';
import {ReactNode, useCallback} from 'react';
import {FiX as ClearIcon} from 'react-icons/fi';
import {segmentTrack} from '../../utils';
import {Button} from '../button';
import Styles from './FiltersWrapper.module.scss';

type Props = {
  children: ReactNode;
  clearFilters?: () => void;
  hasFiltersSet?: boolean;
  label?: ReactNode;
  className?: string;
};

const DEFAULT_LABEL = 'Filter by:';

export const FiltersWrapper = ({
  label = DEFAULT_LABEL,
  children,
  className,
  hasFiltersSet,
  clearFilters,
}: Props) => {
  const shouldShowClearBtn = Boolean(clearFilters) && hasFiltersSet;

  const handleClearFilters = useCallback(() => {
    clearFilters?.();
    segmentTrack('Button Clicked', {
      label: 'clear filters',
    });
  }, [clearFilters]);

  return (
    <div className={cs(Styles.wrapper, className)}>
      <span className={Styles.label}>{label}</span>
      {children}
      {shouldShowClearBtn && (
        <Button onClick={handleClearFilters} variant="tertiary" icon={<ClearIcon />}>
          Clear filters
        </Button>
      )}
    </div>
  );
};

import {isEqual, without} from 'lodash';
import {useCallback} from 'react';
import {FiltersWrapper, ProfileSearchMultipleOptionsFilter} from '../../../components/filters';
import {PremiumFeatureWrapper} from '../../../components/premium-feature-wrapper';
import {Score} from '../../../components/score';
import {
  MyConnectionsFilterKeys,
  MyConnectionTableFilter,
  Permission,
  ScopeFilterOptions,
} from '../../../types';

type Props = {
  tableFilters: MyConnectionTableFilter;
};

const DEFAULT_FILTER = MyConnectionsFilterKeys.CurrentJobCompanyId;
const OON_FILTER_KEY = MyConnectionsFilterKeys.Scope;
const OON_FILTER_VALUE = ScopeFilterOptions.OutOfNetwork;

export const Filters = ({tableFilters}: Props) => {
  const {filters, clearFilter, clearFilters, setFilter, getFilterValue} = tableFilters;

  const onChangeFilter = useCallback(
    (filterName: MyConnectionsFilterKeys) => (filterValue: string[]) => {
      if (filterValue.length === 0) {
        clearFilter(filterName);
      } else {
        if (filterName === OON_FILTER_KEY && isEqual(filterValue, [OON_FILTER_VALUE])) {
          setFilter(filterName, filterValue);
        } else {
          setFilter(filterName, filterValue);
        }
      }
    },
    [setFilter, clearFilter]
  );

  const isOutOfNetwork = isEqual(getFilterValue(OON_FILTER_KEY), [OON_FILTER_VALUE]);

  const filterProps = <Key extends MyConnectionsFilterKeys>(fieldKey: Key) => ({
    fieldKey: fieldKey,
    onChange: onChangeFilter(fieldKey),
    value: getFilterValue(fieldKey),
    teamGraph: true,
    filters: filters,
  });

  const hasFiltersSet = without(Object.keys(filters), DEFAULT_FILTER).length > 0;

  return (
    <div className="flex gap-2">
      <FiltersWrapper hasFiltersSet={hasFiltersSet} clearFilters={clearFilters}>
        <ProfileSearchMultipleOptionsFilter
          {...filterProps(MyConnectionsFilterKeys.CurrentJobTitle)}
          title="Title"
          searchable
          exactMatchAutocompletionsSearch
          exactMatchFiltering
        />
        <ProfileSearchMultipleOptionsFilter
          {...filterProps(MyConnectionsFilterKeys.Location)}
          title="Location"
          searchable
          exactMatchAutocompletionsSearch
          exactMatchFiltering
        />
        <ProfileSearchMultipleOptionsFilter
          {...filterProps(MyConnectionsFilterKeys.Scope)}
          title="Network Status"
          directItems={[
            {value: ScopeFilterOptions.InNetwork, label: 'In-network'},
            {value: ScopeFilterOptions.OutOfNetwork, label: 'Out-of-network'},
          ]}
        />
        <ProfileSearchMultipleOptionsFilter
          {...filterProps(MyConnectionsFilterKeys.ConnectedTo)}
          title="Connected to"
          disabled={isOutOfNetwork}
        />
        <PremiumFeatureWrapper
          permission={Permission.ConnectionStrength}
          featureName="Connection Strength"
          fallbackProps={{disabled: true}}
          tooltipPlacement="bottom-center"
          location="connection score filter"
        >
          <ProfileSearchMultipleOptionsFilter
            {...filterProps(MyConnectionsFilterKeys.ConnectionsStrength)}
            title="Strength"
            labelsMapping={{
              '0': <Score value={0} showLabel />,
              '1': <Score value={1} showLabel />,
              '2': <Score value={2} showLabel />,
              '3': <Score value={3} showLabel />,
            }}
            forceOrder={['3', '2', '1', '0']}
            disabled={isOutOfNetwork}
          />
        </PremiumFeatureWrapper>
      </FiltersWrapper>
    </div>
  );
};

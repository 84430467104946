import {CREATOR} from './SuggestionsIndicator';
import Styles from './SuggestionsIndicator.module.scss';

type Creators = (typeof CREATOR)[keyof typeof CREATOR];

type Props = {
  creator?: Creators;
  white?: boolean;
};

export const SuggestionIcon = ({creator, white}: Props) => {
  const imageSrc =
    creator === CREATOR.AI ? `/icons/robot${white ? '-white' : ''}.svg` : '/icons/pipeline-icon.svg';
  return <img alt={`${creator} suggestion`} className={Styles.suggestionIcon} src={imageSrc} />;
};

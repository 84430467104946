import {ConnectionsColumns} from '../../components/table/connectionsColumns';

export const availableMyConnectionsColumns = [
  ConnectionsColumns.LastName,
  ConnectionsColumns.ConnectionsStrength,
  ConnectionsColumns.JobTitle,
  ConnectionsColumns.JobCompanyName,
  ConnectionsColumns.LocationName,
];

export const availableConnectionsColumns = [
  ConnectionsColumns.LastName,
  ConnectionsColumns.JobTitle,
  ConnectionsColumns.JobCompanyName,
  ConnectionsColumns.LocationName,
  ConnectionsColumns.Tags,
  ConnectionsColumns.ConnectionsStrength,
  ConnectionsColumns.ConnectedMembersCount,
];

export const CONNECTIONS_COLUMNS_PREFERENCES_KEY = 'connections_columns';
export const MY_CONNECTIONS_COLUMNS_PREFERENCES_KEY = 'my_connections_columns';

import {useCallback, useState} from 'react';
import {useOnboardingContext} from '../../../../contexts';
import {CreateTeamScreen} from './CreateTeamScreen';
import {JoinTeamScreen} from './JoinTeamScreen';
import {TeamStepScreens} from './types';

type Props = {
  initialScreen?: TeamStepScreens;
};

export const TeamStep = ({initialScreen = TeamStepScreens.JoinTeamScreen}: Props) => {
  const {onFinishStep} = useOnboardingContext();
  const [currentView, setCurrentView] = useState<TeamStepScreens>(initialScreen);

  const goToCreateTeamScreen = useCallback(() => setCurrentView(TeamStepScreens.CreateTeamScreen), []);

  switch (currentView) {
    case TeamStepScreens.JoinTeamScreen:
      return <JoinTeamScreen onSuccess={onFinishStep} goToCreateTeam={goToCreateTeamScreen} />;
    case TeamStepScreens.CreateTeamScreen:
      return <CreateTeamScreen onSuccess={onFinishStep} />;
    default:
      return <>Invalid screen</>;
  }
};

import {useCallback} from 'react';

const shareNetworkMagicLinkCodeKey = window.btoa('SHARE_NETWORK_MAGIC_LINK_CODE_KEY');

export const useMagicLinkCode = () => {
  const saveCode = useCallback((code: string) => {
    if (!code) return;

    localStorage.setItem(shareNetworkMagicLinkCodeKey, code);
  }, []);

  const retrieveCode = useCallback(() => {
    return localStorage.getItem(shareNetworkMagicLinkCodeKey);
  }, []);

  const clearCode = useCallback(() => {
    localStorage.removeItem(shareNetworkMagicLinkCodeKey);
  }, []);

  return {saveCode, retrieveCode, clearCode};
};

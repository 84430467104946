import {useCallback, useEffect, useState} from 'react';
import {FilterKey, FilterOperator, FiltersState, FilterState} from './types';
import {useCurrentTeam} from '../../hooks';

const DEFAULT_OPERATOR = FilterOperator.is;
const DEFAULT_STATE: FilterState = {operator: DEFAULT_OPERATOR, value: []};

export const useFiltersState = (defaultState: FiltersState = {}, filtersName: string = '') => {
  const {id: teamID} = useCurrentTeam();
  const localStorageKey = `filtersState-${teamID}-${filtersName}`;
  const [filtersState, setFiltersState] = useState<FiltersState>(
    localStorage.getItem(localStorageKey)
      ? JSON.parse(localStorage.getItem(localStorageKey) as string)
      : defaultState
  );

  useEffect(() => {
    if (filtersName !== '') {
      localStorage.setItem(localStorageKey, JSON.stringify(filtersState));
    }
  }, [localStorageKey, filtersState, filtersName]);

  const addNewFilter = useCallback(
    (filterKey: FilterKey, initialState: FilterState = DEFAULT_STATE) => {
      if (filtersState.hasOwnProperty(filterKey)) {
        return;
      }

      setFiltersState(prev => ({...prev, [filterKey]: initialState}));
    },
    [filtersState]
  );

  const removeFilter = useCallback((filterKey: FilterKey) => {
    setFiltersState(prev => {
      const newState = {...prev};
      delete newState[filterKey];
      return newState;
    });
  }, []);

  const setFilter = useCallback(
    (filterKey: FilterKey, newState?: FilterState) => {
      if (!filtersState.hasOwnProperty(filterKey)) {
        addNewFilter(filterKey, newState);
      } else {
        if (!newState) {
          removeFilter(filterKey);
        } else {
          setFiltersState(prev => ({...prev, [filterKey]: newState}));
        }
      }
    },
    [addNewFilter, filtersState, removeFilter]
  );

  return {
    filtersState,
    setFilter,
  };
};

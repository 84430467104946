import {useCallback, useState} from 'react';
import {AuthBox, AuthBoxIcon} from '../../../../components/auth';
import {Button} from '../../../../components/button';
import {LinkedinWarningIcon, WorkOverlapsIcon} from '../../../../components/icons';
import {FindOutMoreContent, SkipWorkExperienceButton} from '../../../../components/onboarding';
import {Form} from './Form';

type Props = {
  onSuccess: () => void;
  onSkip: () => void;
  blueUserUrl?: string;
};

export const FormScreen = ({onSuccess, onSkip, blueUserUrl}: Props) => {
  const [isFindOutMoreOpen, setIsFindOutMoreOpen] = useState(false);
  const toggleFindOutMore = useCallback(() => setIsFindOutMoreOpen(!isFindOutMoreOpen), [isFindOutMoreOpen]);

  return (
    <AuthBox
      headerImage={<HeaderImage />}
      headerTitle="Work experience"
      headerDescription={
        <>
          We’ll find people you may know
          <br />
          based on your work experience.
        </>
      }
    >
      <Button
        variant="tertiary"
        chevron={isFindOutMoreOpen ? 'up' : 'down'}
        className="mx-auto mb-4 text-swarm-gray-700 underline"
        onClick={toggleFindOutMore}
        tracking={{
          label: 'find out more',
          location: 'add overlaps',
        }}
      >
        Find out more
      </Button>

      <FindOutMoreContent visible={isFindOutMoreOpen} />

      <Form onSuccess={onSuccess} blueUserUrl={blueUserUrl} />

      <SkipWorkExperienceButton visible={isFindOutMoreOpen} afterSkip={onSkip} />
      {!blueUserUrl && (
        <p className="mb-0 mt-1 flex items-center gap-2 text-left text-base font-light text-swarm-gray-700">
          <LinkedinWarningIcon />
          It doesn't add your LinkedIn connections, <br />
          they can be mapped later.
        </p>
      )}
    </AuthBox>
  );
};

const HeaderImage = () => (
  <AuthBoxIcon>
    <WorkOverlapsIcon className="w-8" />
  </AuthBoxIcon>
);

import {useCallback, useEffect, useMemo, useState} from 'react';
import {Role} from '../../../types';
import {segmentTrack} from '../../../utils';
import {Modal} from '../../modal';
import {AddConnectorsStep} from './AddConnectorsStep';
import Styles from './AddMembersModal.module.scss';
import {InviteMembersStep} from './InviteMembersStep';
import {SelectRoleStep} from './SelectRoleStep';

type AddMembersModalProps = {
  visible: boolean;
  onClose: () => void;
  onSuccess?: () => void;
};

export const AddMembersModal = ({visible, onClose, onSuccess}: AddMembersModalProps) => {
  const [selectedRole, setSelectedRole] = useState<Role | null>(null);

  const clearSelectedRole = () => setSelectedRole(null);

  useEffect(() => {
    if (visible) {
      clearSelectedRole();
      segmentTrack('Modal Viewed', {label: 'add members'});
    }
  }, [visible]);

  const onInviteSuccess = useCallback(() => {
    onSuccess?.();
    onClose();
  }, [onSuccess, onClose]);

  const content = useMemo(() => {
    switch (selectedRole) {
      case Role.Admin:
        return <InviteMembersStep goBack={clearSelectedRole} onSuccess={onInviteSuccess} role={Role.Admin} />;
      case Role.Contributor:
        return (
          <InviteMembersStep goBack={clearSelectedRole} onSuccess={onInviteSuccess} role={Role.Contributor} />
        );
      case Role.Guest:
        return <InviteMembersStep goBack={clearSelectedRole} onSuccess={onInviteSuccess} role={Role.Guest} />;
      case Role.Connector:
        return <AddConnectorsStep goBack={clearSelectedRole} onSuccess={onInviteSuccess} />;
      default:
        return <SelectRoleStep setSelectedRole={setSelectedRole} />;
    }
  }, [onInviteSuccess, selectedRole]);

  return (
    <Modal visible={visible} onClose={onClose} closeButtonVisible className={Styles.addMembersModal}>
      {content}
    </Modal>
  );
};

/*
 * Generated type guards for "api.ts".
 * WARNING: Do not manually change this file.
 */
import { ShareNetworkInvitationWithUser } from "./api";

export function isShareNetworkInvitationWithUser(obj: unknown): obj is ShareNetworkInvitationWithUser {
    const typedObj = obj as ShareNetworkInvitationWithUser
    return (
        (typedObj !== null &&
            typeof typedObj === "object" ||
            typeof typedObj === "function") &&
        typeof typedObj["id"] === "string" &&
        typeof typedObj["userId"] === "string" &&
        typeof typedObj["inviteeEmail"] === "string" &&
        typeof typedObj["note"] === "string" &&
        typeof typedObj["validUntil"] === "string" &&
        typeof typedObj["createdAt"] === "string" &&
        typeof typedObj["updatedAt"] === "string" &&
        (typedObj["user"] !== null &&
            typeof typedObj["user"] === "object" ||
            typeof typedObj["user"] === "function") &&
        typeof typedObj["user"]["id"] === "string" &&
        typeof typedObj["user"]["firstName"] === "string" &&
        typeof typedObj["user"]["lastName"] === "string" &&
        typeof typedObj["user"]["email"] === "string" &&
        (typeof typedObj["user"]["isAnonymized"] === "undefined" ||
            typedObj["user"]["isAnonymized"] === false ||
            typedObj["user"]["isAnonymized"] === true)
    )
}

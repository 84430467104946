import {ReactNode} from 'react';
import {Role} from '../../../types';
import {capitalizeString} from '../../../utils';
import {Card} from '../../card';
import {RoleIcon} from '../../icons';
import {PaidRoleBadge} from '../../paid-role-badge';
import Styles from './AddMembersModal.module.scss';

type Props = {
  setSelectedRole: (role: Role) => void;
};
export const SelectRoleStep = ({setSelectedRole}: Props) => (
  <>
    <h4 className={Styles.header}>Add members</h4>
    <p className={Styles.subheader}>Choose a role first</p>

    <hr className={Styles.divider} />

    <div className={Styles.roleSelector}>
      <RoleSelector
        role={Role.Admin}
        description="All permissions on."
        icon={<RoleIcon role={Role.Admin} />}
        onClick={() => setSelectedRole(Role.Admin)}
      />

      <RoleSelector
        role={Role.Contributor}
        description="All permissions on except for adding and removing members."
        icon={<RoleIcon role={Role.Contributor} />}
        onClick={() => setSelectedRole(Role.Contributor)}
      />

      <RoleSelector
        role={Role.Guest}
        description="Cannot see connections of other guests, nor any contact info."
        icon={<RoleIcon role={Role.Guest} />}
        onClick={() => setSelectedRole(Role.Guest)}
      />

      <RoleSelector
        role={Role.Connector}
        description="Passive user type: They don’t get notified and don’t sign up."
        icon={<RoleIcon role={Role.Connector} />}
        onClick={() => setSelectedRole(Role.Connector)}
      />
    </div>

    <p className={Styles.learnMore}>
      Visit{' '}
      <a
        className="cursor-pointer text-swarm-gray-800 underline hover:text-swarm-black"
        href="http://theswarm.com/pricing"
        target="_blank"
        rel="nofollow noreferrer"
      >
        theswarm.com/pricing
      </a>{' '}
      for more details.
    </p>
  </>
);

type RoleSelectorProps = {
  role: Role;
  description: string;
  icon: ReactNode;
  onClick: () => void;
};
const RoleSelector = ({role, description, icon, onClick}: RoleSelectorProps) => {
  return (
    <Card className={Styles.roleSelectorItem} onClick={onClick}>
      <div className="w-8">{icon}</div>
      <div>
        <h4 className={Styles.roleSelectorItemTitle}>
          {capitalizeString(role)} <PaidRoleBadge role={role} />
        </h4>
        <p className={Styles.roleSelectorItemDescription}>{description}</p>
      </div>
    </Card>
  );
};

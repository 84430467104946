import {Dialog} from '@headlessui/react';
import {useCallback, useEffect, useState} from 'react';
import {FiBookOpen as BookOpenIcon} from 'react-icons/fi';
import {getConfig, segmentTrack} from '../../../utils';
import {Button} from '../../button';
import {Label} from '../../form';
import {IndeterminateCheckbox} from '../../indeterminate-checkbox';
import {TokenizedListEditor} from './TokenizedListEditor';
import {TokenizedListFilterModalContentProps} from './types';

const {extendYourSearchEnabled} = getConfig();

type PossibleButtonClickedEventLabels = 'learn more' | 'cancel' | 'apply';
const emitButtonClickedClicked = (label: PossibleButtonClickedEventLabels, additionalPayload: object = {}) =>
  segmentTrack('Button Clicked', {label, location: 'list filter', ...additionalPayload});

export const TokenizedListFilterModalContent = ({
  value,
  modalTitle,
  modalDescription,
  promptMessage,
  tokensLabel,
  exactMatchDescription,
  onApply,
  onCancel,
  minItemLength,
}: TokenizedListFilterModalContentProps) => {
  const [exactMatchEnabled, setExactMatchEnabled] = useState(value?.exact_match ?? true);
  const [tokens, setTokens] = useState(value?.list ?? []);

  useEffect(() => {
    segmentTrack('Modal Viewed', {label: 'list filter'});
  }, []);

  const onApplyClicked = useCallback(() => {
    onApply({list: tokens, exact_match: exactMatchEnabled});
    emitButtonClickedClicked('apply', {list_count: tokens.length});
  }, [onApply, tokens, exactMatchEnabled]);

  const onCancelClicked = useCallback(() => {
    onCancel();
    emitButtonClickedClicked('cancel');
  }, [onCancel]);

  return (
    <>
      <Dialog.Title as="h3" className="text-3xl font-bold text-swarm-black">
        {modalTitle}
      </Dialog.Title>
      {modalDescription && <Dialog.Description className="mb-4 mt-2">{modalDescription}</Dialog.Description>}
      <TokenizedListEditor
        value={tokens}
        label={promptMessage}
        onChange={setTokens}
        tokensLabel={tokensLabel}
        minItemLength={minItemLength}
      />

      {extendYourSearchEnabled && (
        <Label>
          <div className="flex gap-2 pb-2 pt-4">
            <div>
              <IndeterminateCheckbox
                checked={!exactMatchEnabled}
                onChange={e => setExactMatchEnabled(!e.currentTarget.checked)}
                indeterminate={false}
              />
            </div>
            <div className="flex-col">
              <span className="block text-lg text-swarm-gray-800">Extend your search</span>
              <span className="text-sm text-swarm-gray-500">{exactMatchDescription}</span>
            </div>
          </div>
        </Label>
      )}

      <div className="mt-8 flex justify-between">
        <div>
          <Button
            variant="tertiary"
            icon={<BookOpenIcon />}
            href="https://help.theswarm.com/en/articles/6863913-how-to-search-through-the-network-graph"
            target="_blank"
            onClick={() => emitButtonClickedClicked('learn more')}
          >
            Learn more about advanced filtering
          </Button>
        </div>
        <div className="flex gap-2">
          <Button onClick={onCancelClicked}>Cancel</Button>
          <Button variant="primary" onClick={onApplyClicked}>
            Apply
          </Button>
        </div>
      </div>
    </>
  );
};

import cs from 'classnames';
import {ReactElement} from 'react';
import {Crm, ScoreValue} from '../../../../types';
import {formatRange, integrationNameMap, parseRange} from '../../../../utils';
import {Avatar} from '../../../avatar';
import {CrmIcon} from '../../../crm-icon';
import {Score} from '../../../score';
import {TruncateWithTooltip} from '../../../truncate-with-tooltip';
import {DecoratorType} from '../../types';
import Styles from './FilterOptionItem.module.scss';

type Props = {
  onChange: (value: string) => void;
  checked: boolean;
  value: string;
  label: string;
  isMultipleChoice?: boolean;
  count?: number;
  decorator?: DecoratorType;
  element?: ReactElement;
};

export const FilterOptionItem = ({
  onChange,
  checked,
  value,
  label,
  isMultipleChoice = true,
  count,
  decorator,
  element,
}: Props) => {
  let content;
  switch (decorator) {
    case 'user':
      content = (
        <span className={cs(Styles.label, Styles.userDecorator)}>
          <Avatar fullname={label} size="xs" />
          <TruncateWithTooltip content={label}>{label}</TruncateWithTooltip>
        </span>
      );
      break;
    case 'strength':
      content = (
        <span className={cs(Styles.label, Styles.strengthDecorator)}>
          <Score value={value as unknown as ScoreValue} showLabel />
        </span>
      );
      break;
    case 'crm':
      const crm = label as Crm;
      const crmName = integrationNameMap[crm];
      content = (
        <span className={cs(Styles.label, Styles.crmDecorator)}>
          <CrmIcon crm={crm} className="!size-4" />
          <TruncateWithTooltip content={crmName}>{crmName}</TruncateWithTooltip>
        </span>
      );
      break;
    case 'size':
      const size = formatRange(parseRange(value));
      content = (
        <span className={cs(Styles.label)}>
          <TruncateWithTooltip content={size}>{size}</TruncateWithTooltip>
        </span>
      );
      break;
    default:
      content = (
        <span className={Styles.label}>
          <TruncateWithTooltip content={label}>{label}</TruncateWithTooltip>
        </span>
      );
      break;
  }

  const inputType = isMultipleChoice ? 'checkbox' : 'radio';

  return (
    <div onClick={() => onChange(value)} className={Styles.item}>
      <input type={inputType} checked={checked} readOnly className={Styles.checkbox} />
      {element || content}
      {!!count && <span className={Styles.count}>{count.toLocaleString('en-US')}</span>}
    </div>
  );
};

import {createContext, ReactNode, useCallback, useContext, useEffect, useState} from 'react';
import {ImportConnectionsModal, ImportViews} from '../components/import-connections';
import {useModal, useShouldOpenImportModal} from '../hooks';

type ContextValue = {
  importModalVisible: boolean;
  showImportModal: (initialView?: ImportViews) => void;
};

export const ImportConnectionsModalContext = createContext<ContextValue>({
  importModalVisible: false,
  showImportModal: () => {},
});
ImportConnectionsModalContext.displayName = 'SwarmImportConnectionsModalContext';

type Props = {
  children: ReactNode;
  afterClose?: () => void;
};

export const ImportConnectionsModalContextProvider = ({afterClose, children}: Props) => {
  const {visible: importModalVisible, show: showImportModal, close: closeImportModal} = useModal();
  const {shouldShowImportModal} = useShouldOpenImportModal();
  const [initialView, setInitialView] = useState<ImportViews>();

  const enhancedShowImportModal = useCallback(
    (initialView?: ImportViews) => {
      setInitialView(initialView);
      showImportModal();
    },
    [showImportModal]
  );

  useEffect(() => {
    if (shouldShowImportModal) {
      // TODO: consider to entirely remove this logic because of onboarding flow
      enhancedShowImportModal(ImportViews.WorkOverlaps);
    }
  }, [enhancedShowImportModal, shouldShowImportModal]);

  const value: ContextValue = {
    importModalVisible,
    showImportModal: enhancedShowImportModal,
  };

  const handleOnClose = useCallback(() => {
    closeImportModal();
    afterClose?.();
    setInitialView(undefined);
  }, [afterClose, closeImportModal]);

  return (
    <ImportConnectionsModalContext.Provider value={value}>
      {children}
      <ImportConnectionsModal
        onClose={handleOnClose}
        visible={importModalVisible}
        initialView={initialView}
      />
    </ImportConnectionsModalContext.Provider>
  );
};

export const useImportConnectionsModal = () => {
  const contextValue = useContext(ImportConnectionsModalContext);

  return contextValue;
};

import {useQueriesTeams} from '../api';
import {useUserSessionContext} from '../contexts';
import {EnhancedTeam, Role, Team} from '../types';

export const useChangeTeam = () => {
  const {changeActiveTeam} = useUserSessionContext();
  return changeActiveTeam;
};

export const useTeamsList = (): EnhancedTeam[] => {
  const {activeTeamId, teams: userTeams} = useUserSessionContext();
  const teamsIds = userTeams.map(team => team.id);

  const teamQueries = useQueriesTeams(teamsIds, {
    refetchOnMount: false,
    keepPreviousData: true,
  });

  const allQueriesCatched = teamQueries.every(query => query.data);
  if (!allQueriesCatched) return [];

  const queriedTeams = teamQueries.map(query => query.data) as Team[];
  return queriedTeams.map((team: Team) => {
    const userTeam = userTeams.find(userTeam => userTeam.id === team.id);
    return {
      ...team,
      role: userTeam?.role || Role.Contributor,
      isActive: userTeam?.id === activeTeamId,
    };
  });
};

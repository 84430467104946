import {isEmpty} from 'lodash';
import {useCallback, useEffect, useMemo} from 'react';
import {useQueryProfiles} from '../../api';
import {AddMembersModal, useAddMembersModal} from '../../components/add-members';
import {BulkAddToPipelineButton} from '../../components/add-to-pipeline';
import {LoadingSpinner} from '../../components/animations';
import {Button} from '../../components/button';
import {BulkCrmExportMenu} from '../../components/crm-export-menu';
import {NoResults} from '../../components/no-results';
import {PermissionChecker} from '../../components/permission';
import {PremiumFeatureClickWrapper} from '../../components/premium-feature-wrapper';
import {BulkScoreDropdown} from '../../components/score';
import {Table, UseSwarmTableProps} from '../../components/table';
import {prepareQueryProfilesInput} from '../../components/table/prepareProfilesQueryInput';
import {useProfilesTable} from '../../components/table/useProfilesTable';
import {BulkProfilesTags} from '../../components/tags';
import {useImportConnectionsModal, useImportTasks} from '../../contexts';
import {useCheckPermission, useCurrentTeam} from '../../hooks';
import {MyConnectionTableFilter, Permission, Profile, ScoreValue, TagSimple} from '../../types';
import {getConfig, pluralizedConnection, segmentTrack} from '../../utils';
import {useProfilesReducer} from './useProfilesReducer.hook';

const {crmProfileExportEnabled} = getConfig();

type Props = {
  teamGraph: boolean;
  searchQuery: string;
  tableFilters: MyConnectionTableFilter;
  pagination: Required<UseSwarmTableProps>['pagination'];
  sorting: Required<UseSwarmTableProps>['sorting'];
  openIntroModal: (profileId: string) => void;
  visibleColumnsState: UseSwarmTableProps['visibleColumnsState'];
  columnsOrder: UseSwarmTableProps['columnsOrder'];
  availableColumns: UseSwarmTableProps['availableColumns'];
};

export const MyConnectionsTable = ({
  teamGraph,
  searchQuery,
  tableFilters,
  pagination,
  sorting,
  openIntroModal,
  visibleColumnsState,
  columnsOrder,
  availableColumns,
}: Props) => {
  const canTeamMemberUpdate = useCheckPermission(Permission.TeamMemberUpdate);
  const {hasPendingTasks} = useImportTasks();
  const {id: teamId} = useCurrentTeam();
  const {showImportModal} = useImportConnectionsModal();
  const {paginationState, totalCount, setMeta} = pagination;
  const {sortingState} = sorting;
  const {addMembersVisible, closeAddMembersModal, openAddMembersModal} = useAddMembersModal();
  const getProfilesInput = prepareQueryProfilesInput({
    teamGraph,
    filters: tableFilters.filters,
    ...paginationState,
    ...(searchQuery.length && {query: searchQuery}),
    sorting: sortingState,
  });

  const {
    data: connections,
    isFetchedAfterMount,
    refetch: refetchProfiles,
    isLoading,
  } = useQueryProfiles(teamId, getProfilesInput, {
    keepPreviousData: true,
    onSuccess: ({meta}) => setMeta(meta),
    refetchInterval: hasPendingTasks ? 5000 : false,
    refetchOnWindowFocus: false, // TODO: remove this when we have a better default sorting for the received data
  });

  const {profiles, reload, updateScore, assignTag, unassignTag, addToPipeline} = useProfilesReducer(
    connections?.data || []
  );

  useEffect(() => {
    const connectionsData = connections?.data || [];
    reload(connectionsData);
  }, [connections?.data, reload]);

  const table = useProfilesTable({
    rows: profiles,
    isLoaded: isFetchedAfterMount,
    teamGraph,
    tableFilters,
    pagination,
    sorting,
    searchQuery,
    onModifyStrength: updateScore,
    onAssignTag: assignTag,
    onUnassignTag: unassignTag,
    onAddToPipeline: addToPipeline,
    openIntroModal,
    visibleColumnsState,
    columnsOrder,
    availableColumns,
    refetchProfiles,
  });

  useEffect(() => {
    refetchProfiles();
  }, [refetchProfiles, teamId]);

  const noResultsContent = teamGraph
    ? {
        heading: 'Build up your Network Graph.',
        subheading:
          'Add your connections and invite more members to add theirs.\nYour searchable, collective network will be available here!',
      }
    : {
        heading: 'Start by adding your connections.',
        subheading: "We'll combine and enrich them for you.\nIt only takes a few minutes.",
      };

  const filtersOrSearchSet = !isEmpty(tableFilters.filters) || !!searchQuery;

  const selectedRows = table.getSelectedRowModel().rows;
  const selectedProfiles = useMemo(() => selectedRows.map(row => row.original), [selectedRows]);
  const selectedProfileIds = useMemo(
    () => selectedProfiles.map(profile => profile.profile_info.id),
    [selectedProfiles]
  );
  const selectedProfilesCount = selectedProfileIds.length;
  const shouldShowBulkActions = selectedProfilesCount > 0;

  const selectedRowsInfo = useMemo(
    () => `${selectedProfilesCount} ${pluralizedConnection(selectedProfilesCount)} selected`,
    [selectedProfilesCount]
  );

  const onScoreChangeSuccess = useCallback(
    (profileIds: string[], score: ScoreValue) => {
      updateScore(profileIds, score);
      table.toggleAllRowsSelected(false);
    },
    [table, updateScore]
  );

  const onBulkAssignTagSuccess = useCallback(
    (tag: TagSimple) => {
      assignTag(selectedProfileIds, tag);
      table.toggleAllRowsSelected(false);
    },
    [assignTag, selectedProfileIds, table]
  );
  const onBulkUnassignTagSuccess = useCallback(
    (tagId: string) => {
      unassignTag(selectedProfileIds, tagId);
      table.toggleAllRowsSelected(false);
    },
    [selectedProfileIds, table, unassignTag]
  );

  const onBulkAddToPipeline = useCallback(
    (pipelineId: string, pipelineTitle?: string) => {
      addToPipeline(selectedProfileIds, pipelineId, pipelineTitle);
      table.toggleAllRowsSelected(false);
    },
    [addToPipeline, selectedProfileIds, table]
  );

  const onAddConnectionsClick = useCallback(() => {
    showImportModal();

    const screenName = teamGraph ? 'network graph' : 'my connections';

    segmentTrack('Button Clicked', {
      label: 'add connections',
      location: `empty ${screenName}`,
    });
  }, [showImportModal, teamGraph]);

  return (
    <div>
      <Table<Profile>
        headerGroups={table.getHeaderGroups()}
        rowModel={table.getRowModel()}
        totalWidth={table.getTotalSize()}
        bulkActionsSlot={
          shouldShowBulkActions && (
            <>
              <BulkAddToPipelineButton
                profileIDs={selectedProfileIds}
                onAddToPipelineSuccess={onBulkAddToPipeline}
                onCreatePipelineSuccess={refetchProfiles}
              />
              {!teamGraph && (
                <PermissionChecker permission={Permission.ConnectionStrength}>
                  <BulkScoreDropdown
                    profileIds={selectedProfileIds}
                    onScoreChangeSuccess={onScoreChangeSuccess}
                  />
                </PermissionChecker>
              )}
              {teamGraph && (
                <BulkProfilesTags
                  selectedProfiles={selectedProfiles}
                  onAssignSuccess={onBulkAssignTagSuccess}
                  onUnassignSuccess={onBulkUnassignTagSuccess}
                />
              )}
              {crmProfileExportEnabled && (
                <PremiumFeatureClickWrapper permission={Permission.CRMExport}>
                  <BulkCrmExportMenu
                    targetIds={selectedProfileIds}
                    targetType="profile"
                    onSuccess={_crm => refetchProfiles()} // TODO: optimistic update for CRMs
                  />
                </PremiumFeatureClickWrapper>
              )}
              <span>{selectedRowsInfo}</span>
            </>
          )
        }
        rowKeyPath="original.profile_info.id"
      />
      {isLoading && <LoadingSpinner size="small" color="black" centered />}
      {isFetchedAfterMount &&
        totalCount === 0 &&
        (filtersOrSearchSet ? (
          <NoResults
            heading="Sorry, there are no results."
            subheading={
              'Please clear search filters and try again.\nConsider adding members to expand your Network Graph.'
            }
            page="results"
          />
        ) : (
          <NoResults
            heading={noResultsContent.heading}
            subheading={noResultsContent.subheading}
            page="my-connections"
          >
            <Button variant="secondary" onClick={onAddConnectionsClick}>
              Add connections
            </Button>
            {canTeamMemberUpdate && (
              <>
                <Button variant="secondary" onClick={openAddMembersModal}>
                  Add members
                </Button>
                <AddMembersModal visible={addMembersVisible} onClose={closeAddMembersModal} />
              </>
            )}
          </NoResults>
        ))}
    </div>
  );
};

import {SortingState} from '@tanstack/react-table';
import {isEqual, uniqBy} from 'lodash';
import {useCallback, useEffect, useMemo} from 'react';
import {Helmet} from 'react-helmet';
import {FiSearch as SearchIcon} from 'react-icons/fi';
import {useQueryBffProfilesList, useQueryProfileListBffAggregateForTeamGraph} from '../../api';
import {LoadingSpinner} from '../../components/animations';
import {ColumnSettingsDropdown} from '../../components/column-settings';
import {Input} from '../../components/form';
import {filtersStateToRequest, FiltersWrapper, useFiltersState} from '../../components/new-filters';
import {FilterOperator} from '../../components/new-filters/types';
import {NoResults} from '../../components/no-results';
import {PageHeader} from '../../components/page-header';
import {PaginationButtons} from '../../components/pagination-buttons';
import {RequestIntro, useRequestIntroModal} from '../../components/request-intro';
import {useTablePagination, useTableSearch, useTableSorting} from '../../components/table';
import {useColumnSettings, usePrevious, useRestoreScrollOnPopState} from '../../hooks';
import {AppLayout} from '../../layouts';
import {GetProfilesListRequest, GetProfilesListResponse} from '../../types';
import {getRequestOffset, pluralizedProfile, sortingStateToSortOptions} from '../../utils';
import {availableColumns, labelsMap, PROFILES_COLUMNS_PREFERENCES_KEY, ProfilesColumns} from './columns';
import {filtersConfig} from './filtersConfig';
import {ProfilesListTable} from './ProfilesListTable';
import {useProfilesTable} from './useProfilesTable';

const emptyProfilesResponse: GetProfilesListResponse = {
  items: [],
  meta: {totalCount: 0, limit: 0, offset: 0},
};

const subSorting: SortingState = [
  {id: 'connectedMembersCount', desc: true},
  {id: 'lastName', desc: false},
];

const defaultSorting: SortingState = [subSorting[0]];

const initialFiltersState = {
  scope: {
    operator: FilterOperator.is,
    value: ['inNetwork'],
    selectedOptions: [{value: 'inNetwork', label: 'In Network'}],
  },
};

type Props = {
  teamGraph: boolean;
};

export const ProfilesPage = ({teamGraph}: Props) => {
  const {introModalVisible, closeIntroModal, openIntroModal, introProfileId} = useRequestIntroModal();

  const {filtersState, setFilter} = useFiltersState(initialFiltersState, filtersConfig.filtersName);

  const {columnSettings, setColumnSettings, visibleColumnsState, columnsOrder} = useColumnSettings(
    availableColumns,
    PROFILES_COLUMNS_PREFERENCES_KEY
  );
  const {searchQuery, onChangeSearchQuery, onClearSearchQuery, searchQueryFetchParam} = useTableSearch();

  const {
    currentPage,
    paginationState,
    setPaginationState,
    setPageSize,
    totalCount,
    setMeta,
    visibleItemsString,
    isFirstPage,
    isLastPage,
    nextPage,
    previousPage,
    goToPage,
  } = useTablePagination();

  const {sortingState, setSorting} = useTableSorting(defaultSorting);
  const sort = useMemo(
    () => sortingStateToSortOptions(uniqBy([...sortingState, ...subSorting], item => item.id)),
    [sortingState]
  );
  const offset = getRequestOffset(paginationState.pageSize, paginationState.pageIndex);

  const filters = useMemo(() => {
    return {
      ...filtersStateToRequest(filtersState),
      ...(searchQueryFetchParam ? {query: searchQueryFetchParam} : {}),
    };
  }, [filtersState, searchQueryFetchParam]);

  const searchParams: GetProfilesListRequest = useMemo(
    () => ({
      offset,
      limit: paginationState.pageSize,
      filters,
      sort: searchQueryFetchParam ? [] : sort, // SWRM-2794 - no sorting if there's a search query, enabling having results with highest OpenSearch score on top
      teamGraph: teamGraph ? true : undefined,
    }),
    [filters, offset, paginationState.pageSize, sort, teamGraph, searchQueryFetchParam]
  );

  const {
    data = emptyProfilesResponse,
    isFetchedAfterMount,
    refetch,
  } = useQueryBffProfilesList(searchParams, {
    onSuccess: ({meta}) => setMeta(meta),
  });

  const table = useProfilesTable({
    teamGraph,
    rows: data.items,
    isLoaded: isFetchedAfterMount,
    pagination: {
      paginationState,
      setPaginationState,
      currentPage,
      totalCount,
      setMeta,
    },
    sorting: {
      sortingState,
      setSorting,
    },
    onAssignTag: () => refetch(),
    onUnassignTag: () => refetch(),
    visibleColumnsState,
    columnsOrder,
    openIntroModal,
  });

  useRestoreScrollOnPopState('profiles', isFetchedAfterMount);

  const returnToFirstPage = useCallback(() => table.setPageIndex(1), [table]);

  const prevFilters = usePrevious(filters);
  useEffect(() => {
    if (prevFilters && !isEqual(prevFilters, filters)) {
      returnToFirstPage();
    }
  }, [filters, prevFilters, returnToFirstPage]);

  const paginationFullInfo = `${visibleItemsString} ${pluralizedProfile(totalCount)}`;

  const hasProfiles = totalCount > 0;

  return (
    <>
      <Helmet>
        <title>Profiles - The Swarm</title>
      </Helmet>
      <AppLayout
        header={
          <PageHeader title="Profiles" hasBottomSeparator>
            <Input
              icon={SearchIcon}
              type="text"
              value={searchQuery}
              onChange={e => onChangeSearchQuery(e.target.value)}
              onClear={onClearSearchQuery}
              className="max-w-sm"
              placeholder="Search for profile name, location..."
              intercomTarget="search"
            />
          </PageHeader>
        }
        subHeader={
          <div className="flex items-end justify-between">
            <FiltersWrapper
              filtersState={filtersState}
              onFilterSet={setFilter}
              filtersConfig={filtersConfig}
              useFetchOptions={useQueryProfileListBffAggregateForTeamGraph}
              mainSearchQuery={searchQueryFetchParam}
            />
            <div className="m-2">
              <ColumnSettingsDropdown
                availableColumns={availableColumns}
                columnSettings={columnSettings}
                setColumnSettings={setColumnSettings}
                fixedColumns={[ProfilesColumns.FullName]}
                labelsMap={labelsMap}
              />
            </div>
          </div>
        }
        footer={
          hasProfiles && (
            <PaginationButtons
              visibleItemsString={paginationFullInfo}
              pageIndex={currentPage}
              isFirstPage={isFirstPage}
              isLastPage={isLastPage}
              navigate={{
                next: nextPage,
                previous: previousPage,
                goToPage: goToPage,
              }}
              totalCount={totalCount}
              pageSize={paginationState.pageSize}
              setPageSize={setPageSize}
            />
          )
        }
      >
        {!isFetchedAfterMount ? (
          <LoadingSpinner size="small" color="black" centered />
        ) : !hasProfiles ? (
          <NoResults
            heading="Sorry, there are no results."
            subheading={
              'Please clear search filters and try again.\nConsider adding members to expand your network.'
            }
            page="results"
          ></NoResults>
        ) : (
          <>
            <ProfilesListTable tableData={table} onChangeSuccess={refetch} teamGraph={teamGraph} />
            {teamGraph && (
              <RequestIntro
                profileId={introProfileId}
                onClose={closeIntroModal}
                visible={introModalVisible}
              />
            )}
          </>
        )}
      </AppLayout>
    </>
  );
};

import {flatten, groupBy, partition} from 'lodash';
import {useMemo} from 'react';
import {useCurrentUser} from '../../hooks';
import {Invite, InviteStatus, Member, Role} from '../../types';
import {getUserFullName} from '../../utils';
import {MembersListItem, MembersListItemStatus} from './types';

export const convertMemberToMembersListItem = (member: Member): MembersListItem => {
  const {id, teamId, connectionCount, user} = member;
  const {id: userId, email, role} = user;
  return {
    type: 'member',
    id,
    userId,
    teamId,
    name: getUserFullName(user),
    email: email,
    role,
    connectionCount,
    status: MembersListItemStatus.Active,
    inviteStatus: InviteStatus.Accepted,
  };
};

export const convertInviteToMembersListItem = (invite: Invite): MembersListItem => {
  const {
    invite_id: id,
    invite_email: email,
    invite_role: role,
    invite_status: inviteStatus,
    updated_at: inviteSentDate,
    team_id: teamId,
  } = invite;
  return {
    type: 'invite',
    id,
    teamId,
    name: email.split('@')[0],
    email,
    role,
    status: MembersListItemStatus.Invited,
    inviteStatus,
    inviteSentDate: inviteSentDate,
  };
};

export const isValuableInvitation = ({invite_status}: Invite) => invite_status === InviteStatus.Pending;

export const searchInMemberListItem = (membersListItem: MembersListItem, searchQuery: string = '') => {
  const searchQueryLower = searchQuery.toLowerCase();
  const nameLower = membersListItem.name.toLowerCase();
  const emailLower = membersListItem.email.toLowerCase();
  return nameLower.includes(searchQueryLower) || emailLower.includes(searchQueryLower);
};

export const useSortedMembers = (members: MembersListItem[]): MembersListItem[] => {
  const {id: currentUserId} = useCurrentUser();

  const sortedByGroups = useMemo(() => {
    const grouped = groupBy(members, member => member.role);

    return flatten([
      grouped[Role.Owner],
      grouped[Role.Admin],
      grouped[Role.Contributor],
      grouped[Role.Guest],
      grouped[Role.Connector],
    ]).filter(Boolean);
  }, [members]);

  const [[currentMember], restMembers] = useMemo(
    () => partition(sortedByGroups, member => member.userId === currentUserId),
    [currentUserId, sortedByGroups]
  );

  // can occur because it's a hook and could not be early returned,
  // so sometimes it's called before the members list is loaded
  if (typeof currentMember === 'undefined') {
    return [];
  }

  return [{...currentMember, name: `${currentMember.name} (you)`}, ...restMembers];
};

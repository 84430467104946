import {useCallback} from 'react';
import {useFieldArray, useForm} from 'react-hook-form';
import {FiMail as MailIcon} from 'react-icons/fi';
import {inviteMember} from '../../../../api';
import {AddInvitationNote} from '../../../../components/add-invitation-note';
import {LoadingSpinner} from '../../../../components/animations';
import {Button} from '../../../../components/button';
import {Input, Message} from '../../../../components/form';
import {notify} from '../../../../components/notifications';
import {useCurrentTeam, useCurrentUser} from '../../../../hooks';
import {Role} from '../../../../types';
import {emailPattern, getUserFullName} from '../../../../utils';

const AVAILABLE_NUMBER_OF_INVITEES = 4;

type InviteMembersFormProps = {
  invitees: {email: string}[];
  note?: string;
};

type Props = {
  onSuccess: () => void;
};

const defaultInvitees = Array.from({length: AVAILABLE_NUMBER_OF_INVITEES}, () => ({email: undefined}));

export const InviteMembersManuallyForm = ({onSuccess}: Props) => {
  const {name: teamName, id: teamId} = useCurrentTeam();
  const currentUser = useCurrentUser();
  const sender = getUserFullName(currentUser);

  const {
    formState: {isSubmitting, errors},
    getValues,
    setValue,
    register,
    watch,
    control,
    handleSubmit,
    setError,
  } = useForm<InviteMembersFormProps>({
    defaultValues: {
      invitees: defaultInvitees,
      note: '',
    },
    mode: 'onBlur',
  });

  const {fields} = useFieldArray({
    control,
    name: 'invitees',
    rules: {
      minLength: AVAILABLE_NUMBER_OF_INVITEES,
    },
  });

  const onSubmit = async () => {
    //TODO: analytics
    const uniqueInvitees = getValues('invitees').filter(
      (invitee, index, self) =>
        invitee.email && self.findIndex(({email}) => email === invitee.email) === index
    );
    const note = getValues('note');

    if (!uniqueInvitees.length) {
      setError('invitees', {message: 'At least one email address is required.'});
      return;
    }

    for (const {email} of uniqueInvitees) {
      try {
        await inviteMember(teamId, {
          email,
          role: Role.Admin,
          note,
        });
        onSuccess();
      } catch (e) {
        notify(`Sending invitation to ${email} failed`);
        setError('invitees', {message: 'Something went wrong.'});
      }
    }
  };

  const setNote = useCallback(
    (value: string) => {
      setValue('note', value);
    },
    [setValue]
  );

  const noteSaved = watch('note');

  if (!teamName || !teamId)
    return (
      <div className="flex justify-center">
        <LoadingSpinner />
      </div>
    );

  return (
    <form className="flex flex-col gap-2 text-left">
      <h4>Enter email addresses below</h4>
      <div className="flex flex-col">
        {fields.map((field, index) => (
          <Input
            key={field.id}
            className="mb-1"
            {...register(`invitees.${index}.email`, {
              pattern: {
                value: emailPattern,
                message: 'Invalid email address',
              },
            })}
            placeholder="Work email"
            isInvalid={Boolean(errors.invitees?.[index]?.email)}
            message={errors.invitees?.[index]?.email?.message}
          />
        ))}
      </div>
      {errors.invitees?.message && <Message message={errors.invitees.message} hasError className="mt-2" />}

      <AddInvitationNote
        content={`Join now and add your connections to the ${teamName} collective network on The Swarm.`}
        note=""
        setNote={setNote}
        subject={`${sender} has invited you to The Swarm`}
        title={`${sender} has invited you to The Swarm`}
        customAddNoteButton={
          <Button
            variant={noteSaved ? 'green' : 'secondary'}
            fullWidth
            size="lg"
            icon={<MailIcon />}
            type="button"
            className="mt-4"
            tracking={{
              label: 'customise invite message',
              location: 'invite members manually',
            }}
          >
            {noteSaved ? 'Note saved' : 'Customize invite message'}
          </Button>
        }
      />

      <Button
        type="submit"
        variant="primary"
        fullWidth
        loading={isSubmitting}
        size="lg"
        onClick={handleSubmit(onSubmit)}
        tracking={{
          label: 'send invitations',
          location: 'invite members manually',
        }}
      >
        Send Invitations
      </Button>
    </form>
  );
};

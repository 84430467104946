import {uniqBy} from 'lodash';
import {useCallback, useReducer} from 'react';
import {Company, Crm, TagSimple} from '../../types';

type CompaniesAction =
  | {type: 'RELOAD'; payload: Company[]}
  | {type: 'ASSIGN_TAG'; payload: {companyId: string; tag: TagSimple}[]}
  | {type: 'UNASSIGN_TAG'; payload: {companyId: string; tagId: TagSimple['id']}[]}
  | {type: 'ADD_CRM_EXPORT'; payload: {companyId: string; crm: Crm}[]};

export const useCompaniesReducer = (initialData: Company[]) => {
  const profileReducer = (state: Company[], action: CompaniesAction): Company[] => {
    switch (action.type) {
      case 'RELOAD':
        return action.payload;
      case 'ASSIGN_TAG':
        return state.map(company => {
          const newTag = action.payload.find(p => p.companyId === company.id)?.tag;

          if (!newTag) {
            return company;
          }

          return {
            ...company,
            tags: uniqBy([...company.tags, newTag], 'id'),
          };
        });
      case 'UNASSIGN_TAG':
        return state.map(company => {
          const tagId = action.payload.find(p => p.companyId === company.id)?.tagId;

          if (!tagId) {
            return company;
          }

          return {
            ...company,
            tags: company.tags.filter(tag => tag.id !== tagId),
          };
        });
      case 'ADD_CRM_EXPORT':
        return state.map(company => {
          const crm = action.payload.find(p => p.companyId === company.id)?.crm;

          if (!crm) {
            return company;
          }

          return {
            ...company,
            crms: uniqBy([...company.crms, {crm, integrationId: '', teamId: ''}], 'crm'),
          };
        });
      default:
        throw new Error('Invalid action type');
    }
  };

  const [state, dispatch] = useReducer(profileReducer, initialData);

  const reload = useCallback((companies: Company[]) => {
    dispatch({type: 'RELOAD', payload: companies});
  }, []);

  const assignTag = useCallback((companiesIds: string[], tag: TagSimple) => {
    dispatch({type: 'ASSIGN_TAG', payload: companiesIds.map(companyId => ({companyId, tag}))});
  }, []);

  const unassignTag = useCallback((companiesIds: string[], tagId: TagSimple['id']) => {
    dispatch({type: 'UNASSIGN_TAG', payload: companiesIds.map(companyId => ({companyId, tagId}))});
  }, []);

  const addCrmExport = useCallback((companiesIds: string[], crm: Crm) => {
    dispatch({type: 'ADD_CRM_EXPORT', payload: companiesIds.map(companyId => ({companyId, crm}))});
  }, []);

  return {
    companies: state,
    reload,
    assignTag,
    unassignTag,
    addCrmExport,
  };
};

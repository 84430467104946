import {AxiosResponse} from 'axios';
import {useQuery, UseQueryOptions} from 'react-query';
import {Profile, ScoreValue, SearchMeta, Sort, User} from '../types';
import {apiClient} from './apiClient';

type ProfileOutput = {
  data: Profile;
};

const getProfile = (id: string): Promise<Profile> =>
  apiClient.get<ProfileOutput>(`/profiles/${id}`).then(res => res.data.data);

export const useQueryProfile = (id: string, teamId: string) =>
  useQuery(['profile', {id, teamId}], () => getProfile(id), {
    enabled: Boolean(id),
    refetchOnWindowFocus: false,
  });

type GetProfilesOutput = {
  data: Profile[];
  meta: SearchMeta;
};

type FilterOptions = {
  query: string[];
  industry: string[];
  job_title_role: string[];
  job_title_sub_role: string[];
  job_title_levels: string[];
  job_company_id: string[];
  job_company_industry: string[];
  job_company_location_name?: string;
  job_company_location_country: string[];
  job_company_location_metro: string[];
  connected_to: string[];
  connections_strength: string[];
  skills?: string;
  interests?: string;
};

type GetProfilesInput = {
  filters: Partial<FilterOptions>;
  sort?: Sort;
  offset?: number;
  limit?: number;
  team_graph: boolean;
};

export const getProfiles = (data?: GetProfilesInput): Promise<GetProfilesOutput> =>
  apiClient
    .request<GetProfilesOutput, AxiosResponse<GetProfilesOutput>, GetProfilesInput>({
      url: '/profiles',
      method: 'POST',
      data,
    })
    .then(res => res.data);

export const useQueryProfiles = (
  teamId: string,
  getProfilesInput?: GetProfilesInput,
  options?: UseQueryOptions<GetProfilesOutput>
) =>
  useQuery<GetProfilesOutput>(
    ['my-connections', getProfilesInput ? getProfilesInput : 'all'],
    () => getProfiles(getProfilesInput),
    options
  );

/* request intro */

type RequestIntroInput = {
  profileId: string;
  to: string;
  subject: string;
  body: string;
  pipeline?: string;
  addToCc?: boolean;
};

export const requestIntro = (input: RequestIntroInput) =>
  apiClient
    .post<void, AxiosResponse<void>, RequestIntroInput>('/profiles/request-intro', input)
    .then(res => res.data);

/* profiles count */

type GetProfilesCountOutput = {
  profileCount: number;
};

export const getProfilesCount = (): Promise<number> =>
  apiClient
    .request<GetProfilesCountOutput, AxiosResponse<GetProfilesCountOutput>>({
      url: '/profiles/count',
      method: 'GET',
    })
    .then(res => res.data)
    .then(({profileCount}) => profileCount);

export const useQueryProfilesCount = (options?: UseQueryOptions<number>) =>
  useQuery<number>(['profiles-count'], getProfilesCount, {
    keepPreviousData: true,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    ...options,
  });

type RequestIntroConnectorsInput = {
  teamId: string;
  profileId: string;
};

type RequestIntroConnectorsRequest = {
  profile_id: string;
};

type RequestIntroConnectorsResponse = {
  connectors: IntroConnector[];
};

export type IntroConnector = {
  user: User;
  score: ScoreValue;
};

const getRequestIntroConnectors = (
  params: RequestIntroConnectorsRequest
): Promise<RequestIntroConnectorsResponse> =>
  apiClient
    .get<RequestIntroConnectorsResponse>('/profiles/request-intro-connectors', {params})
    .then(res => res.data);

export const useQueryRequestIntroConnectors = (
  params: RequestIntroConnectorsInput,
  options?: UseQueryOptions<RequestIntroConnectorsResponse>
) =>
  useQuery<RequestIntroConnectorsResponse>(
    ['request-intro-connectors', params],
    () =>
      getRequestIntroConnectors({
        profile_id: params.profileId,
      }),
    {
      keepPreviousData: true,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      ...options,
    }
  );

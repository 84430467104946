import {differenceWith} from 'lodash';
import {createContext, ReactNode, useContext, useEffect, useState} from 'react';
import {useQueryTasks} from '../api/tasks';
import {notify} from '../components/notifications';
import {usePrevious} from '../hooks';
import {Task, TaskResult, TaskStatus} from '../types';
import {segmentTrack} from '../utils';

const DEFAULT_REFETCH_INTERVAL = 10_000;
const BUSY_REFETCH_INTERVAL = 2000;

type ContextValue = {
  tasks: Task[];
  hasImportTasks: boolean;
  hasUnfinishedTasks: boolean;
  hasPendingTasks: boolean;
  isTasksFetched: boolean;
  isRefetchingTasks: boolean;
  pendingTasks: Task[];
};

export const ImportTasksContext = createContext({} as ContextValue);
ImportTasksContext.displayName = 'SwarmImportTasksContext';

const compareTasks = (task1: Task, task2: Task) => task1.taskId === task2.taskId;

type Props = {
  children: ReactNode;
};
export const ImportTasksContextProvider = ({children}: Props) => {
  const [refetchInterval, setRefetchInterval] = useState(DEFAULT_REFETCH_INTERVAL);

  const {
    data: tasks = [],
    isFetched: isTasksFetched,
    isRefetching: isRefetchingTasks,
  } = useQueryTasks({
    keepPreviousData: true,
    refetchOnMount: false,
    refetchInterval,
    refetchIntervalInBackground: true,
  });

  const hasImportTasks = tasks.length > 0;
  const hasUnfinishedTasks = tasks.some(task => task.status !== TaskStatus.StatusDone);

  const pendingTasks = tasks.filter(task => task.status === TaskStatus.StatusRunning);
  const previousPendingTasks = usePrevious(pendingTasks) ?? [];
  const hasPendingTasks = pendingTasks.length > 0;

  const recentlyEndedTasks = differenceWith(previousPendingTasks, pendingTasks, compareTasks).map(
    endedTask => tasks.find(t => t.taskId === endedTask.taskId) ?? endedTask // use previous task data if it's not available (shouldn't happen, just to satisfy TS)
  );

  useEffect(() => {
    if (hasPendingTasks) {
      setRefetchInterval(BUSY_REFETCH_INTERVAL);
    } else {
      setRefetchInterval(DEFAULT_REFETCH_INTERVAL);
    }
  }, [hasPendingTasks]);

  useEffect(() => {
    recentlyEndedTasks.forEach(task => {
      if (task.result === TaskResult.OK) {
        notify('Your connections are now imported.');
        segmentTrack('Notification Viewed', {
          message: 'Your connections are now imported.',
          type: 'confirmation',
          process: 'connections import',
          data_provider: task.dataProvider,
        });
      }

      if (task.result === TaskResult.Failed) {
        if (task.dataProvider === 'WorkOverlaps') {
          notify('Work Overlaps import failed. Try again later.', true);
        } else {
          notify('Connections import failed. Try again later.', true);
        }

        segmentTrack('Notification Viewed', {
          message: 'Connections import failed. Try again later.',
          type: 'error',
          process: 'connections import',
          data_provider: task.dataProvider,
        });
      }
    });
  }, [recentlyEndedTasks]);

  const value: ContextValue = {
    tasks,
    hasImportTasks,
    hasUnfinishedTasks,
    hasPendingTasks,
    isTasksFetched,
    isRefetchingTasks,
    pendingTasks,
  };

  return <ImportTasksContext.Provider value={value}>{children}</ImportTasksContext.Provider>;
};

export const useImportTasks = () => {
  const contextValue = useContext(ImportTasksContext);

  return contextValue;
};

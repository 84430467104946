import {SortingState} from '@tanstack/react-table';
import {isEqual} from 'lodash';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {Helmet} from 'react-helmet';
import {FiSearch as SearchIcon} from 'react-icons/fi';
import {useQueryCompanies} from '../../api';
import {LoadingSpinner} from '../../components/animations';
import {ColumnSettingsDropdown} from '../../components/column-settings';
import {Input} from '../../components/form';
import {NoResults} from '../../components/no-results';
import {PageHeader} from '../../components/page-header';
import {PaginationButtons} from '../../components/pagination-buttons';
import {useTablePagination, useTableSearch, useTableSorting} from '../../components/table';
import {
  useColumnSettings,
  useCurrentTeam,
  useObjectInSearchParam,
  useOnFilteringEvent,
  usePrevious,
  useRestoreScrollOnPopState,
} from '../../hooks';
import {AppLayout} from '../../layouts';
import {CompaniesFilterOptions, CompaniesResponse, ScopeFilterOptions} from '../../types';
import {getRequestOffset, prepareSearchQuery, sortingStateToSort} from '../../utils';
import {availableColumns, COMPANIES_COLUMNS_PREFERENCES_KEY, CompaniesColumns, labelsMap} from './columns';
import {CompaniesListTable} from './CompaniesListTable';
import {Filters} from './Filters';
import {useCompaniesReducer} from './useCompaniesReducer.hook';
import {useCompaniesTable} from './useCompaniesTable';

const subSorting: SortingState = [
  {id: 'connectedMembersCount', desc: true},
  {id: 'size', desc: true},
  {id: 'name', desc: false},
];

const defaultSorting: SortingState = [subSorting[0]];

const emptyCompaniesResponse: CompaniesResponse = {
  companies: [],
  meta: {totalCount: 0, limit: 0, offset: 0},
};
const normalizeFilter = (filter: CompaniesFilterOptions): CompaniesFilterOptions =>
  Object.fromEntries(Object.entries(filter).filter(([, value]) => value.length > 0));

export const CompaniesPage = () => {
  const {columnSettings, setColumnSettings, visibleColumnsState, columnsOrder} = useColumnSettings(
    availableColumns,
    COMPANIES_COLUMNS_PREFERENCES_KEY
  );
  const {id: teamId} = useCurrentTeam();
  const {searchQuery, onChangeSearchQuery, searchQueryFetchParam, onClearSearchQuery} = useTableSearch();

  const {getObjectFromSearchParam: getFiltersFromUrl, saveObjectAsSearchParam: saveFiltersInUrl} =
    useObjectInSearchParam<CompaniesFilterOptions>({
      defaultObject: {},
      searchParamName: 'filters',
      replaceHistory: true,
    });
  const defaultFilters = useMemo(() => {
    const filters = getFiltersFromUrl();
    if (Object.keys(filters).length === 0) {
      return {
        'filters[scope][]': [ScopeFilterOptions.InNetwork],
      };
    }
    filters['filters[query][]'] = searchQueryFetchParam ? [searchQueryFetchParam] : [];
    return filters;
  }, [getFiltersFromUrl, searchQueryFetchParam]);
  const [filters, setFilters] = useState<CompaniesFilterOptions>(defaultFilters);
  useEffect(() => {
    setTimeout(() => saveFiltersInUrl(normalizeFilter(filters)));
  }, [filters, saveFiltersInUrl]);

  useOnFilteringEvent({
    filters,
    filtersToOmit: ['filters[query][]'], // because the query is added internally inside of hook
    searchQuery: prepareSearchQuery(searchQuery),
  });

  const {
    currentPage,
    paginationState,
    setPaginationState,
    setPageSize,
    totalCount,
    setMeta,
    visibleItemsString,
    isFirstPage,
    isLastPage,
    nextPage,
    previousPage,
    goToPage,
  } = useTablePagination();

  const {sortingState, setSorting} = useTableSorting(defaultSorting);
  const sort = useMemo(() => sortingStateToSort([...sortingState, ...subSorting]), [sortingState]);

  const offset = getRequestOffset(paginationState.pageSize, paginationState.pageIndex);
  const searchParams = useMemo(
    () => ({
      offset,
      limit: paginationState.pageSize,
      ...filters,
      sort,
    }),
    [filters, offset, paginationState.pageSize, sort]
  );

  const {data = emptyCompaniesResponse, isFetchedAfterMount} = useQueryCompanies(teamId, searchParams, {
    onSuccess: ({meta}) => setMeta(meta),
  });
  useRestoreScrollOnPopState('companies', isFetchedAfterMount);

  const {companies, reload, assignTag, unassignTag, addCrmExport} = useCompaniesReducer(data.companies);

  useEffect(() => {
    reload(data.companies);
  }, [data.companies, reload]);

  const table = useCompaniesTable({
    rows: companies,
    isLoaded: isFetchedAfterMount,
    pagination: {
      paginationState,
      setPaginationState,
      currentPage,
      totalCount,
      setMeta,
    },
    sorting: {
      sortingState,
      setSorting,
    },
    onAssignTag: assignTag,
    onUnassignTag: unassignTag,
    visibleColumnsState,
    columnsOrder,
  });

  const returnToFirstPage = useCallback(() => table.setPageIndex(1), [table]);

  const setFilter = useCallback(
    (key: keyof CompaniesFilterOptions, value: string[]) =>
      setFilters(filters => {
        if (isEqual(filters[key], value)) return filters;
        return {...filters, [key]: value};
      }),
    []
  );

  const prevFilter = usePrevious(filters);
  useEffect(() => {
    if (prevFilter && !isEqual(normalizeFilter(prevFilter), normalizeFilter(filters))) {
      returnToFirstPage();
    }
  }, [filters, prevFilter, returnToFirstPage]);

  useEffect(() => {
    const searchQueryKey = 'filters[query][]';
    setFilter(searchQueryKey, searchQueryFetchParam ? [prepareSearchQuery(searchQueryFetchParam)] : []);
  }, [searchQueryFetchParam, setFilter]);

  const paginationFullInfo = `${visibleItemsString} companies`;

  return (
    <>
      <Helmet>
        <title>Companies - The Swarm</title>
      </Helmet>
      <AppLayout
        header={
          <PageHeader title="Companies" hasBottomSeparator>
            <Input
              icon={SearchIcon}
              type="text"
              value={searchQuery}
              onChange={e => onChangeSearchQuery(e.target.value)}
              onClear={onClearSearchQuery}
              className="max-w-sm"
              placeholder="Search for company name, location..."
              intercomTarget="search"
            />
          </PageHeader>
        }
        subHeader={
          <div className="flex items-end justify-between">
            <div>
              <Filters setFilter={setFilter} searchParams={searchParams} />
            </div>
            <div className="m-2">
              <ColumnSettingsDropdown
                availableColumns={availableColumns}
                columnSettings={columnSettings}
                setColumnSettings={setColumnSettings}
                fixedColumns={[CompaniesColumns.Name]}
                labelsMap={labelsMap}
              />
            </div>
          </div>
        }
        footer={
          totalCount > 0 && (
            <PaginationButtons
              visibleItemsString={paginationFullInfo}
              pageIndex={currentPage}
              isFirstPage={isFirstPage}
              isLastPage={isLastPage}
              navigate={{
                next: nextPage,
                previous: previousPage,
                goToPage: goToPage,
              }}
              totalCount={totalCount}
              pageSize={paginationState.pageSize}
              setPageSize={setPageSize}
            />
          )
        }
      >
        {!isFetchedAfterMount ? (
          <LoadingSpinner size="small" color="black" centered />
        ) : totalCount === 0 ? (
          <NoResults
            heading="Sorry, there are no results."
            subheading={
              'Please clear search filters and try again.\nConsider adding members to expand your network.'
            }
            page="results"
          ></NoResults>
        ) : (
          <CompaniesListTable
            tableData={table}
            onAssignTagSuccess={assignTag}
            onUnassignTagSuccess={unassignTag}
            onCrmExportSuccess={addCrmExport}
          />
        )}
      </AppLayout>
    </>
  );
};

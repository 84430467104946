import {useCallback} from 'react';
import {Link} from 'react-router-dom';
import {useCheckPermission} from '../../hooks';
import {Permission} from '../../types';
import {segmentTrack} from '../../utils';

export type UpgradeTooltipContentLocation =
  | 'connection score'
  | 'connection score filter'
  | 'connection company filters'
  | 'team settings tabs'
  | 'tags filter'
  | 'profile contact data'
  | 'profile connection score';

type Props = {
  featureName: string;
  location: UpgradeTooltipContentLocation;
};

export const UpgradeTooltipContent = ({featureName, location}: Props) => {
  const canUpgrade = useCheckPermission(Permission.PlanUpdate);

  const emitEvent = useCallback(() => {
    segmentTrack('Button Clicked', {
      label: 'upgrade',
      location,
    });
  }, [location]);

  const upgradeLink = canUpgrade ? (
    <Link to="/upgrade-plan" className="underline" onClick={emitEvent}>
      Upgrade
    </Link>
  ) : (
    'Upgrade'
  );

  return (
    <>
      {upgradeLink} to enable
      <br /> {featureName}
    </>
  );
};

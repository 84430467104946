import {useCallback} from 'react';
import {FiChevronDown as ChevronDownIcon, FiX as ClearIcon} from 'react-icons/fi';
import {segmentTrack} from '../../../utils';
import {Button, ButtonsGroup} from '../../button';
import {Modal} from '../../modal';
import {TruncateWithTooltip} from '../../truncate-with-tooltip';
import {ActiveFiltersBadge} from '../ActiveFilterBadge';
import {TokenizedStringFilterValue} from '../types';
import {useIsFilterOpen} from '../useIsFilterOpen';
import {TokenizedListFilterModalContent} from './TokenizedListFilterModalContent';
import {TokenizedListFilterProps} from './types';

type Value = TokenizedStringFilterValue;

const emptyValue: Value = {
  list: [],
  exact_match: true,
};

export const TokenizedListFilter = ({
  value = emptyValue,
  title,
  onChange,
  modalTitle,
  modalDescription,
  promptMessage,
  exactMatchDescription,
  tokensLabel,
  disabled,
  minItemLength,
}: TokenizedListFilterProps) => {
  const [filterModalVisible, setFilterModalVisible] = useIsFilterOpen({
    title,
  });
  const showFilterModal = useCallback(() => setFilterModalVisible(true), [setFilterModalVisible]);
  const closeFilterModal = useCallback(() => setFilterModalVisible(false), [setFilterModalVisible]);

  const tokensLength = value.list.length;
  const isActive = tokensLength > 0;

  const clearFilter = useCallback(() => {
    onChange(emptyValue);
  }, [onChange]);

  const onClear = useCallback(() => {
    clearFilter();
    segmentTrack('Selector Cleared', {
      label: 'filter',
      filter: title,
    });
  }, [clearFilter, title]);

  const onApply = useCallback(
    (newValue: Value) => {
      onChange(newValue);
      segmentTrack('Selector Applied', {
        label: 'filter',
        filter: title,
      });
      closeFilterModal();
    },
    [closeFilterModal, onChange, title]
  );

  return (
    <>
      <ButtonsGroup>
        <Button
          variant={isActive ? 'black' : 'secondary'}
          icon={tokensLength ? <ActiveFiltersBadge activeFilters={tokensLength} /> : <ChevronDownIcon />}
          reversedOrder={!tokensLength}
          positionInGroup={isActive ? 'first' : undefined}
          className="max-w-xs"
          data-intercom-target={title}
          onClick={showFilterModal}
          disabled={disabled}
        >
          <TruncateWithTooltip content={title}>{title}</TruncateWithTooltip>
        </Button>
        {isActive && <Button variant="black" icon={<ClearIcon />} iconOnly onClick={onClear} />}
      </ButtonsGroup>
      <Modal visible={filterModalVisible} className="w-[650px] font-light text-swarm-gray-700">
        <TokenizedListFilterModalContent
          modalTitle={modalTitle}
          modalDescription={modalDescription}
          promptMessage={promptMessage}
          tokensLabel={tokensLabel}
          exactMatchDescription={exactMatchDescription}
          onApply={onApply}
          onCancel={closeFilterModal}
          value={value}
          minItemLength={minItemLength}
        />
      </Modal>
    </>
  );
};

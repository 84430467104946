import {ReactNode} from 'react';
import {FiX as ClearIcon} from 'react-icons/fi';
import {Button, ButtonsGroup} from '../button';
import {Dropdown} from '../dropdown';
import {TruncateWithTooltip} from '../truncate-with-tooltip';
import {ActiveFiltersBadge} from './ActiveFilterBadge';

const SelectAllButton = ({onClick}: {onClick: () => void}) => (
  <Button variant="tertiary" onClick={onClick} size="sm">
    Select all
  </Button>
);

type Props = {
  title: string;
  isActive: boolean;
  activeFiltersCount?: number;
  onClear?: () => void;
  onSelectAll?: () => void;
  search?: ReactNode;
  searchValue?: string;
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  children: ReactNode;
  disabled?: boolean;
};
export const AutocompleteFilter = ({
  title,
  isActive,
  activeFiltersCount,
  onClear,
  onSelectAll,
  search,
  isOpen,
  setIsOpen,
  children,
  disabled,
}: Props) => {
  const isClearVisible = onClear && isActive;

  return (
    <ButtonsGroup>
      <Dropdown
        renderDropdownTrigger={() => (
          <Button
            variant={isActive ? 'black' : 'secondary'}
            chevron="down"
            positionInGroup={isClearVisible ? 'first' : undefined}
            className="max-w-xs"
            data-intercom-target={title}
            disabled={disabled}
          >
            <TruncateWithTooltip content={title}>
              {activeFiltersCount ? <ActiveFiltersBadge activeFilters={activeFiltersCount} /> : null}
              {title}
            </TruncateWithTooltip>
          </Button>
        )}
        visibleOnEmpty
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        dropdownHeader={search}
        dropdownFooter={onSelectAll && <SelectAllButton onClick={onSelectAll} />}
      >
        {children}
      </Dropdown>
      {isClearVisible && <Button variant="black" icon={<ClearIcon />} iconOnly onClick={onClear} />}
    </ButtonsGroup>
  );
};

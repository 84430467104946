import {useQueryProfiles} from '../../../api';
import {LoadingSpinner} from '../../../components/animations';
import {ColumnSettingsDropdown} from '../../../components/column-settings';
import {NoResults} from '../../../components/no-results';
import {PaginationButtons} from '../../../components/pagination-buttons';
import {RequestIntro, useRequestIntroModal} from '../../../components/request-intro';
import {
  useTableFilters,
  useTablePagination,
  useTableSearch,
  useTableSorting,
} from '../../../components/table';
import {ConnectionsColumns, labelsMap} from '../../../components/table/connectionsColumns';
import {prepareQueryProfilesInput} from '../../../components/table/prepareProfilesQueryInput';
import {TabLinks} from '../../../components/tabs';
import {useColumnSettings, useCurrentTeam, useOnFilteringEvent} from '../../../hooks';
import {AppLayout} from '../../../layouts';
import {MyConnectionsFilter, PipelineCommonResponse} from '../../../types';
import {PipelineHeader} from '../components/PipelineHeader';
import {getTabLinksConfig} from '../getTabLinksConfig';
import {availableColumns, PREFERENCES_KEY} from './columns';
import {Filters} from './Filters';
import {ProfilesTable} from './ProfilesTable';
import {useProfilesToCount} from './useProfilesToCount';

type Props = {
  pipeline: PipelineCommonResponse;
  refetchPipeline: () => void;
};

export const Profiles = ({pipeline}: Props) => {
  const {id: teamId} = useCurrentTeam();
  const {introModalVisible, closeIntroModal, openIntroModal, introProfileId} = useRequestIntroModal();
  const {id: pipelineId} = pipeline;
  const {sortingState, setSorting} = useTableSorting([{id: ConnectionsColumns.Status, desc: true}]);
  const defaultFilters = {in_pipeline: [pipelineId]};
  const tableFilters = useTableFilters<MyConnectionsFilter>(defaultFilters);
  const {filters} = tableFilters;
  const {searchQuery, onChangeSearchQuery, onClearSearchQuery, searchQueryFetchParam} = useTableSearch();
  const {columnSettings, setColumnSettings, visibleColumnsState, columnsOrder} = useColumnSettings(
    availableColumns,
    PREFERENCES_KEY
  );
  const {profilesToCount, refetchProfilesToCount} = useProfilesToCount(pipelineId);

  useOnFilteringEvent({
    filters,
    filtersToOmit: ['in_pipeline'],
    searchQuery: searchQueryFetchParam,
  });

  const {
    currentPage,
    paginationState,
    setPaginationState,
    totalCount,
    setMeta,
    visibleItemsString,
    isFirstPage,
    isLastPage,
    nextPage,
    previousPage,
    goToPage,
  } = useTablePagination();

  const paginationFullInfo = `${visibleItemsString} connections`;

  const getProfilesInput = prepareQueryProfilesInput({
    teamGraph: true,
    filters: tableFilters.filters,
    ...(searchQuery.length && {query: searchQuery}),
    ...paginationState,
    sorting: sortingState,
  });

  const {
    data: pipelineProfiles,
    isFetchedAfterMount,
    isLoading,
  } = useQueryProfiles(teamId, getProfilesInput, {
    keepPreviousData: true,
    onSuccess: ({meta}) => setMeta(meta),
    refetchOnWindowFocus: false, // TODO: remove this when we have a better default sorting for the received data
  });

  const tabLinksConfig = getTabLinksConfig(pipelineId);

  if (isLoading) {
    return <LoadingSpinner size="small" color="black" centered />;
  }

  return (
    <AppLayout
      header={<PipelineHeader pipeline={pipeline} />}
      subHeader={
        <>
          <TabLinks tabs={tabLinksConfig} activeTab="Profiles" />
          <div className="flex items-start justify-between">
            <div className="w-full">
              <div className="flex items-end gap-2">
                <Filters
                  tableFilters={tableFilters}
                  profilesToCount={profilesToCount}
                  totalCount={totalCount}
                  pipelineId={pipelineId}
                  searchQuery={searchQuery}
                  onChangeSearchQuery={onChangeSearchQuery}
                  onClearSearchQuery={onClearSearchQuery}
                />
              </div>
            </div>
            <div className="m-2">
              <ColumnSettingsDropdown
                availableColumns={availableColumns}
                fixedColumns={[ConnectionsColumns.LastName, ConnectionsColumns.Status]}
                columnSettings={columnSettings}
                setColumnSettings={setColumnSettings}
                labelsMap={labelsMap}
              />
            </div>
          </div>
        </>
      }
      footer={
        totalCount > 0 && (
          <PaginationButtons
            visibleItemsString={paginationFullInfo}
            pageIndex={currentPage}
            isFirstPage={isFirstPage}
            isLastPage={isLastPage}
            navigate={{
              next: nextPage,
              previous: previousPage,
              goToPage: goToPage,
            }}
            totalCount={totalCount}
            pageSize={paginationState.pageSize}
          />
        )
      }
    >
      {isFetchedAfterMount && totalCount === 0 ? (
        <NoResults
          heading="No more profiles matching your search."
          subheading="All fitting connections have been added to list."
          page="results"
        ></NoResults>
      ) : (
        pipelineProfiles && (
          <>
            <ProfilesTable
              tableFilters={tableFilters}
              pagination={{
                paginationState,
                setPaginationState,
                currentPage,
                totalCount,
                setMeta,
              }}
              sorting={{
                sortingState,
                setSorting,
              }}
              openIntroModal={openIntroModal}
              pipelineId={pipelineId}
              pipelineProfiles={pipelineProfiles.data}
              visibleColumnsState={visibleColumnsState}
              columnsOrder={columnsOrder}
              availableColumns={availableColumns}
              refetchProfilesToCount={refetchProfilesToCount}
            />
            <RequestIntro profileId={introProfileId} visible={introModalVisible} onClose={closeIntroModal} />
          </>
        )
      )}
    </AppLayout>
  );
};

export enum ProfilesColumns {
  FullName = 'lastName',
  Status = 'pipelineStatus',
  AddToPipeline = 'addToPipeline',
  ConnectionsStrength = 'connectionStrength',
  JobTitle = 'jobTitle',
  JobCompanyName = 'jobCompanyName',
  LocationName = 'location_name',
  StartDate = 'experienceStartDate',
  EndDate = 'experienceEndDate',
  Tags = 'tags',
  ConnectedMembersCount = 'connectedMembersCount',
}

export const labelsMap = {
  [ProfilesColumns.FullName]: 'Name',
  [ProfilesColumns.AddToPipeline]: 'Add',
  [ProfilesColumns.Status]: 'Status',
  [ProfilesColumns.JobTitle]: 'Title',
  [ProfilesColumns.JobCompanyName]: 'Company',
  [ProfilesColumns.StartDate]: 'Start Date',
  [ProfilesColumns.EndDate]: 'End Date',
  [ProfilesColumns.LocationName]: 'Location',
  [ProfilesColumns.Tags]: 'Profile Tags',
  [ProfilesColumns.ConnectionsStrength]: 'Strength',
  [ProfilesColumns.ConnectedMembersCount]: 'Connected To',
};

export const PROFILES_COLUMNS_PREFERENCES_KEY = 'profiles_columns';
export const availableColumns = [
  ProfilesColumns.FullName,
  ProfilesColumns.JobTitle,
  ProfilesColumns.JobCompanyName,
  ProfilesColumns.LocationName,
  ProfilesColumns.Tags,
  ProfilesColumns.ConnectionsStrength,
  ProfilesColumns.ConnectedMembersCount,
];

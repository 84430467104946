import {ChangeEvent, FormEvent, useCallback, useState} from 'react';
import {Input} from '../../../form';

type Props = {
  query?: string;
  onChange: (value: string) => void;
  onSubmit?: (value: string) => void;
};
export const FilterSearch = ({query, onChange, onSubmit}: Props) => {
  const [searchQuery, setSearchQuery] = useState(query ?? '');

  const submitSearch = useCallback(
    (e: FormEvent) => {
      e.preventDefault();
      onSubmit?.(searchQuery);
    },
    [onSubmit, searchQuery]
  );

  const onSearch = useCallback(
    ({target: {value}}: ChangeEvent<HTMLInputElement>) => {
      setSearchQuery(value);
      onChange(value);
    },
    [onChange]
  );

  const onClear = useCallback(() => {
    setSearchQuery('');
    onChange('');
  }, [onChange]);

  return (
    <form className="sticky inset-0 -m-1 block bg-white p-1 pb-0" onSubmit={submitSearch}>
      <Input
        inputStyle="bordered"
        type="text"
        value={searchQuery}
        onChange={onSearch}
        className="my-0"
        placeholder="Start typing to filter the list"
        onClear={onClear}
        autoFocus
      />
    </form>
  );
};

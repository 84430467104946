import {SortingState} from '@tanstack/react-table';
import {Filters} from '../components/table';

export const getRequestOffset = (pageSize: number, pageIndex = 0) => {
  return (pageIndex - 1) * pageSize;
};

export type PathParams = {
  pageIndex: number;
  pageSize?: number;
  query?: string;
  filters?: Filters;
  sorting?: SortingState;
  teamGraph?: boolean;
};

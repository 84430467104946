import {concat, omit, partition, sortBy} from 'lodash';
import {useCallback, useState} from 'react';
import {useQueryAutocompletions} from '../../api';
import {useCurrentUserSession, useDebounce} from '../../hooks';
import {FilterOptionsItem} from '../../types';

type Props = {
  fieldKey: string;
  disabled?: boolean;
  exactMatch?: boolean;
  teamGraph?: boolean;
  filters?: object;
  selectedValues?: string[]; // if provided, will sort the results so that the selected values are first
  labelsMapping?: Record<string, string>;
  forceOrder?: string[];
  alphabeticalOrder?: boolean;
  clientSideSearchable?: boolean;
  limit?: number;
};

export const useAutocompletions = ({
  fieldKey,
  disabled,
  exactMatch,
  teamGraph,
  filters,
  selectedValues = [],
  labelsMapping = {},
  forceOrder = [],
  alphabeticalOrder,
  clientSideSearchable,
  limit,
}: Props) => {
  const [searchPhrase, setSearchPhrase] = useState('');
  const debouncedSearchQuery = useDebounce(searchPhrase.trim(), 500);

  const {team} = useCurrentUserSession();
  const teamId = team?.id;

  const otherFieldsFilters = omit(filters, fieldKey);

  const {data: {data} = {}, isLoading} = useQueryAutocompletions(
    {
      searchPhrase: debouncedSearchQuery,
      exactMatch,
      teamGraph,
      fieldKey,
      filters: otherFieldsFilters,
      limit,
      teamId,
    },
    {enabled: !disabled}
  );

  const reorderData = useCallback(
    (data?: FilterOptionsItem[]) => {
      if (!data) {
        return [];
      }

      const lastForceOrderIndex = data.length;

      return sortBy(data, function (item) {
        return forceOrder.indexOf(item.value) !== -1 ? forceOrder.indexOf(item.value) : lastForceOrderIndex;
      });
    },
    [forceOrder]
  );

  const allAutocompletions: FilterOptionsItem[] = (
    forceOrder.length
      ? reorderData(data)
      : alphabeticalOrder
        ? sortBy(data, 'label')
        : concat(...partition(data, option => selectedValues.includes(option.value)))
  ).map(option => ({
    ...option,
    label: labelsMapping[option.value] || option.label,
  }));

  const autocompletions = (
    clientSideSearchable && searchPhrase.trim()
      ? allAutocompletions.filter(option => option.label.toLowerCase().includes(searchPhrase.toLowerCase()))
      : allAutocompletions
  ).filter(item => item.value);

  return {
    autocompletions,
    isLoading,
    onSearchChange: setSearchPhrase,
    searchPhrase,
  };
};

import {ColumnDef} from '@tanstack/react-table';
import {useMemo} from 'react';
import {Avatar} from '../../components/avatar';
import {Pill} from '../../components/pill';
import {Table, TableInnerHeader, useSwarmTable, useTablePagination} from '../../components/table';
import {TruncateWithTooltip} from '../../components/truncate-with-tooltip';
import {useCheckPermission, useCurrentUser} from '../../hooks';
import {Permission, Role} from '../../types';
import {RolesMap} from './constants';
import {MemberOptionsDropdown} from './MemberOptionsDropdown';
import Styles from './Members.module.scss';
import {MembersListItem, MembersListItemStatus} from './types';

type Props = {
  members: MembersListItem[];
};

export const JoinedMembersList = ({members}: Props) => {
  const activeMembers = members.filter(member => member.status === MembersListItemStatus.Active);

  const canTeamMemberUpdate = useCheckPermission(Permission.TeamMemberUpdate);
  const user = useCurrentUser();

  const pagination = useTablePagination();

  const columns = useMemo<ColumnDef<MembersListItem>[]>(
    () => [
      {
        accessorKey: 'name',
        header: () => <TableInnerHeader label="Name" />,
        cell: info => {
          const {name, status, role} = info.row.original;
          const isConnector = role === Role.Connector;
          const isInvite = status === MembersListItemStatus.Invited;
          return (
            <div className={Styles.nameCellWrapper}>
              <Avatar fullname={name} size="md" outline={isConnector} empty={isInvite || isConnector} />
              <span className={Styles.nameWrapper}>
                <TruncateWithTooltip content={name}>{name}</TruncateWithTooltip>
              </span>
              {status === MembersListItemStatus.Invited && <Pill color="gray">Invited</Pill>}
            </div>
          );
        },
        size: 250,
      },
      {
        accessorKey: 'connectionCount',
        header: () => <TableInnerHeader label="Connections" />,
        cell: info => {
          const connectionCount = (info.row.original.connectionCount || 0).toLocaleString('en-US');
          return <span>{connectionCount}</span>;
        },
        size: 120,
        meta: {
          truncate: true,
        },
      },
      {
        accessorKey: 'email',
        header: () => <TableInnerHeader label="E-mail" />,
        cell: info => {
          const {email, role} = info.row.original;

          if (role === Role.Connector) {
            return '';
          }

          return email;
        },
        size: 250,
        meta: {
          truncate: true,
        },
      },
      {
        accessorKey: 'role',
        header: () => <TableInnerHeader label="Role" />,
        cell: ({row}) => (
          <span data-intercom-target={`role ${RolesMap[row.original.role]}`}>
            {RolesMap[row.original.role]}
          </span>
        ),
        size: 140,
        meta: {
          truncate: true,
        },
      },
      {
        accessorKey: 'actions',
        header: () => <TableInnerHeader label="Actions" hideLabel />,
        cell: info => {
          const member = info.row.original;
          const role = member.role;

          const hasOptions = role !== Role.Owner;
          const isCurrentUser = member.userId === user.id;
          const shouldDisplayOptions = canTeamMemberUpdate && hasOptions && !isCurrentUser;

          return shouldDisplayOptions ? <MemberOptionsDropdown member={member} /> : null;
        },
        size: 60,
        enableResizing: false,
      },
    ],
    [canTeamMemberUpdate, user.id]
  );

  const table = useSwarmTable<MembersListItem>({
    uniqueName: 'activeMembers',
    rows: activeMembers,
    columns,
    defaultColumnSize: 250,
    defaultColumnMinSize: 50,
    isLoaded: true,
    pagination,
  });

  return (
    <Table
      headerGroups={table.getHeaderGroups()}
      rowModel={table.getRowModel()}
      totalWidth={table.getTotalSize()}
      cellHeight={60}
      rowClassName={({status}) =>
        status === MembersListItemStatus.Invited ? 'text-swarm-gray-700 grayscale' : 'text-swarm-black'
      }
    />
  );
};

import cs from 'classnames';
import {ReactNode} from 'react';
import Styles from './PageHeader.module.scss';

type Props = {
  title?: string;
  rightSlot?: ReactNode;
  hasBottomSeparator?: boolean;
  className?: string;
  children?: ReactNode;
};

export const PageHeader = ({title, rightSlot, hasBottomSeparator, className, children}: Props) => {
  return (
    <div className={cs(Styles.pageHeader, {[Styles.borderedPageHeader]: hasBottomSeparator}, className)}>
      <div className={Styles.leftSlot}>
        {title && <h2 className={Styles.title}>{title}</h2>}
        {children && <div className={Styles.childrenWrapper}>{children}</div>}
      </div>
      {rightSlot && <div className={Styles.rightSlot}>{rightSlot}</div>}
    </div>
  );
};

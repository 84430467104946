import {AddMembersModal, useAddMembersModal} from '../../components/add-members';
import {Button} from '../../components/button';
import {PermissionChecker} from '../../components/permission';
import {Permission} from '../../types';

type Props = {
  onSuccess: () => void;
};
export const AddMembersButton = ({onSuccess}: Props) => {
  const {addMembersVisible, closeAddMembersModal, openAddMembersModal} = useAddMembersModal();

  return (
    <PermissionChecker permission={Permission.TeamMemberUpdate}>
      <Btn onClick={openAddMembersModal} />
      <AddMembersModal visible={addMembersVisible} onClose={closeAddMembersModal} onSuccess={onSuccess} />
    </PermissionChecker>
  );
};

type BtnProps = {
  onClick: () => unknown;
};
const Btn = ({onClick}: BtnProps) => (
  <Button
    onClick={onClick}
    variant="primary"
    data-intercom-target="Invite members"
    tracking={{label: 'add members'}}
  >
    Add members
  </Button>
);

import {createContext, ReactNode, useContext, useEffect} from 'react';
import {useMutationAcceptMagicLinkCode, useQueryNetworkSharingInvitations} from '../api';
import {useMagicLinkCode} from '../hooks';
import {ShareNetworkInvitationWithUser} from '../types';
import {isShareNetworkInvitationWithUser} from '../types/api.guard';

type ContextValue = {
  shareNetworkInvitations: ShareNetworkInvitationWithUser[];
  refetchShareNetworkInvitations: () => void;
  isLoadingShareNetworkInvitations: boolean;
};

const ShareNetworkContext = createContext<ContextValue>({
  shareNetworkInvitations: [],
  refetchShareNetworkInvitations: () => {},
  isLoadingShareNetworkInvitations: true,
});
ShareNetworkContext.displayName = 'SwarmShareNetworkContext';

type Props = {
  children: ReactNode;
};
export const ShareNetworkContextProvider = ({children}: Props) => {
  const {retrieveCode, clearCode} = useMagicLinkCode();

  const {
    data,
    refetch: refetchShareNetworkInvitations,
    isLoading: isLoadingShareNetworkInvitations,
    isRefetching: isRefetchingShareNetworkInvitations,
  } = useQueryNetworkSharingInvitations();

  const acceptMagicLinkCodeMutation = useMutationAcceptMagicLinkCode({
    onSuccess: () => {
      refetchShareNetworkInvitations();
      clearCode();
    },
  });

  useEffect(() => {
    const code = retrieveCode();

    if (code) {
      acceptMagicLinkCodeMutation.mutate({code});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- observing acceptMagicLinkCodeMutation causes infinity loop
  }, []);

  const shareNetworkInvitations = data?.invitations.filter(isShareNetworkInvitationWithUser) || [];

  const value = {
    shareNetworkInvitations,
    refetchShareNetworkInvitations,
    isLoadingShareNetworkInvitations: isLoadingShareNetworkInvitations || isRefetchingShareNetworkInvitations,
  };

  return <ShareNetworkContext.Provider value={value}>{children}</ShareNetworkContext.Provider>;
};

export const useShareNetworkContext = () => {
  const context = useContext(ShareNetworkContext);
  return context;
};

import {useCallback, useMemo} from 'react';
import {FiPlus as PlusIcon} from 'react-icons/fi';
import {createSuggestion} from '../../api';
import {useCurrentTeam, useCurrentUser} from '../../hooks';
import {segmentTrack} from '../../utils';
import {Button} from '../button';
import {notify} from '../notifications';

type Props = {
  profileId: string;
  pipelineId: string;
  onAddToPipelineSuccess?: () => void;
};

export const AddToParticularPipelineButton = ({profileId, pipelineId, onAddToPipelineSuccess}: Props) => {
  const {id: userId} = useCurrentUser();
  const {id: teamId} = useCurrentTeam();

  const commonAnalyticsProperties = useMemo(() => ({userId, teamId, profileId}), [userId, teamId, profileId]);

  const handleProfileInPipeline = useCallback(() => {
    const analyticsProperties = {
      ...commonAnalyticsProperties,
      pipelineId,
      profileId,
    };

    createSuggestion(pipelineId, profileId)
      .then(() => {
        segmentTrack('Selector Applied', {
          label: 'add to pipeline',
          ...analyticsProperties,
        });
      })
      .then(() => {
        setTimeout(() => {
          onAddToPipelineSuccess?.();
          notify('Connection successfully added to list.');
        }, 1200);
      });
  }, [commonAnalyticsProperties, profileId, pipelineId, onAddToPipelineSuccess]);

  return (
    <Button
      onClick={handleProfileInPipeline}
      variant="tertiary"
      outline
      iconOnly
      icon={<PlusIcon size={16} />}
      size="xs"
      intercomTarget="add profile to pipeline"
    />
  );
};

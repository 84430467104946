import Rive from '@rive-app/react-canvas';
import {useEffect, useState} from 'react';
import {useMutationStartWorkOverlapsImportTask} from '../../../../api';
import {AuthBox} from '../../../../components/auth';
import {Button} from '../../../../components/button';
import {DelayedAppearingContent} from '../../../../components/common/DelayedAppearingContent';
import {segmentVirtualPageview} from '../../../../utils';

type Props = {
  onFinish: () => void;
};

export const ExplanationScreen = ({onFinish}: Props) => {
  const [initialized, setInitialized] = useState(false);
  const {mutate: initializeTask} = useMutationStartWorkOverlapsImportTask({
    onSuccess: () => setInitialized(true),
  });

  useEffect(() => {
    segmentVirtualPageview('/overlaps-importing');
  }, []);

  useEffect(() => {
    if (!initialized) {
      initializeTask();
    }
  }, [initialized, initializeTask]);

  return (
    <AuthBox
      headerImage={<HeaderImage />}
      headerTitle="Mapping your network..."
      headerDescription={
        <>
          We’re reviewing your experience and searching our
          <br />
          database for people you may know.
        </>
      }
    >
      <DelayedAppearingContent delayMs={3000}>
        <Button onClick={onFinish} variant="primary" fullWidth size="lg" className="mb-4">
          Continue
        </Button>
        <span className="text-swarm-gray-700">
          You’ll have the option to import your
          <br />
          Linkedin network later on.
        </span>
      </DelayedAppearingContent>
    </AuthBox>
  );
};

const HeaderImage = () => <Rive src="/animations/workoverlaps.riv" className="h-[300px] w-full " />;

import {UserRespondedStatus} from '../../api';
import {Invite, InviteStatus} from '../../types';
import {Avatar} from '../avatar';
import {Button} from '../button';
import {Card} from '../card';
import Styles from './Invitation.module.scss';

type Props = {
  invite: Invite;
  onUpdateStatus: (inviteId: Invite['invite_id'], status: UserRespondedStatus, teamId?: string) => unknown;
};

export const Invitation = ({invite, onUpdateStatus}: Props) => {
  const onAccept = () => onUpdateStatus(invite.invite_id, InviteStatus.Accepted, invite.team_id);
  const onReject = () => onUpdateStatus(invite.invite_id, InviteStatus.Rejected);

  return (
    <Card className={Styles.invitation}>
      <div className={Styles.invitationContent}>
        <Avatar fullname={invite.creator_name || invite.invite_email} size="lg" />
        <p>
          {invite.creator_name || invite.creator_id} has invited you to join{' '}
          <span className={Styles.companyName}>{invite.team_name || invite.invite_id}</span> as{' '}
          {invite.invite_role}.
        </p>
      </div>
      <div className={Styles.invitationButtons}>
        <Button variant="primary" onClick={onAccept} className={Styles.invitationButton}>
          Accept
        </Button>
        <Button onClick={onReject} variant="secondary" className={Styles.invitationButton}>
          Reject
        </Button>
      </div>
    </Card>
  );
};

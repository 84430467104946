import {useShareNetworkContext} from '../../contexts';
import {Card} from '../card';

import {User} from '../../types';
import Styles from './ShareNetworkInvitations.module.scss';

export const ShareNetworkInvitations = () => {
  const {shareNetworkInvitations} = useShareNetworkContext();

  if (shareNetworkInvitations.length === 0) {
    return null;
  }

  return (
    <div className={Styles.ShareNetworkInvitations}>
      {shareNetworkInvitations.map(invitation => (
        <InvitationBar key={invitation.id} {...invitation.user} />
      ))}
    </div>
  );
};

const InvitationBar = ({lastName, firstName}: User) => {
  return (
    <Card className={Styles.InvitationBar} color="yellow">
      <p className={Styles.InvitationBarHeader}>
        Search through the network of {firstName} {lastName}
      </p>
      <p className={Styles.InvitationBarBody}>
        You've been invited by {firstName} {lastName}. They will automatically be added to your new team so
        you can search through their connections and send intro requests.
      </p>
    </Card>
  );
};

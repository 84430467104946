import {stringify} from 'qs';
import {useQuery, UseQueryOptions} from 'react-query';
import {FilterOptionsItem} from '../types';
import {apiClient} from './apiClient';

const transformFieldKeyToUriPath = (fieldKey: string) => fieldKey.replace(/_/g, '-');

const isValidSearchPhrase = (searchPhrase?: string): searchPhrase is string =>
  !!searchPhrase && searchPhrase.length >= 2;

type AutocompletionsProps = {
  fieldKey: string;
  searchPhrase?: string;
  exactMatch?: boolean;
  teamGraph?: boolean;
  filters?: object;
  limit?: number;
};

type GetAutocompletionsOutput = {
  data: FilterOptionsItem[];
};
export const getAutocompletions = async ({
  fieldKey,
  searchPhrase,
  exactMatch,
  teamGraph,
  filters,
  limit,
}: AutocompletionsProps): Promise<GetAutocompletionsOutput> => {
  const requestPath = `profiles/filter-data-source/${transformFieldKeyToUriPath(fieldKey)}`;

  const requestQueryParams = stringify(
    {
      ...(isValidSearchPhrase(searchPhrase) && {
        query: exactMatch ? `"${searchPhrase}"` : searchPhrase,
      }),
      ...(teamGraph && {teamGraph}),
      ...(filters && {filters}),
      ...(limit && {limit}),
    },
    {arrayFormat: 'brackets'}
  );

  const requestUrl = [requestPath, requestQueryParams].join('?');

  const resp = await apiClient.get<GetAutocompletionsOutput>(requestUrl);

  return resp.data;
};

export const useQueryAutocompletions = (
  {
    fieldKey,
    searchPhrase,
    exactMatch,
    teamGraph,
    filters,
    limit,
    teamId,
  }: {
    fieldKey: string;
    searchPhrase: string;
    exactMatch?: boolean;
    teamGraph?: boolean;
    filters?: object;
    limit?: number;
    teamId?: string;
  },
  options: UseQueryOptions<GetAutocompletionsOutput> = {}
) =>
  useQuery<GetAutocompletionsOutput>(
    [
      teamGraph ? 'companyGraph' : 'myConnections',
      'filtersAutocompletions',
      fieldKey,
      filters,
      searchPhrase,
      teamId,
    ],
    () =>
      getAutocompletions({
        fieldKey,
        searchPhrase,
        exactMatch,
        teamGraph,
        filters,
        limit,
      }),
    options
  );

import {useCallback} from 'react';
import {useMutationUpdateOnboardingStatus} from '../../../api';
import {Button} from '../../button';
import {HideableContent} from '../../common';

type Props = {
  visible: boolean;
  afterSkip: () => void;
};
export const SkipWorkExperienceButton = ({visible, afterSkip}: Props) => {
  const mutationUpdateOnboardingStatus = useMutationUpdateOnboardingStatus({
    onSuccess: afterSkip,
  });

  const onClick = useCallback(() => {
    mutationUpdateOnboardingStatus.mutate({hasSkippedLinkedin: true});
  }, [mutationUpdateOnboardingStatus]);

  return (
    <HideableContent visible={visible}>
      <Button
        variant="tertiary"
        size="lg"
        className="mt-4"
        fullWidth
        onClick={onClick}
        loading={mutationUpdateOnboardingStatus.isLoading}
        tracking={{
          label: 'skip',
          location: 'add overlaps',
        }}
      >
        Skip
      </Button>
    </HideableContent>
  );
};

import {useUiSetting} from './useUiSetting';
import {useMemo} from 'react';
import {upperCase} from 'lodash';

const getColumnsState = (visibleColumns: string[], availableColumns: string[]) => {
  const state: Record<string, boolean> = {};
  for (const column of availableColumns) {
    state[column] = visibleColumns.includes(column);
  }
  return state;
};

type ColumnSettings = {
  visibleColumns: string[];
  columnsOrder: string[];
};

export const useColumnSettings = (availableColumns: string[], settingsKey: string) => {
  const [
    columnSettings = {
      visibleColumns: [...availableColumns],
      columnsOrder: [...availableColumns],
    },
    setColumnSettings,
  ] = useUiSetting<ColumnSettings>(upperCase(settingsKey));

  const visibleColumnsState = useMemo(
    () => getColumnsState(columnSettings.visibleColumns, availableColumns),
    [availableColumns, columnSettings]
  );

  const columnsOrder = useMemo(() => columnSettings.columnsOrder, [columnSettings]);

  return {
    columnSettings,
    setColumnSettings,
    columnsOrder,
    visibleColumnsState,
  };
};

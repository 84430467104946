import {AxiosError} from 'axios';
import {useMutation, useQuery, UseQueryOptions} from 'react-query';
import {UseMutationOptions} from 'react-query/types/react/types';
import {ErrorResponse, Onboarding, OnboardingCompany} from '../types';
import {apiClient} from './apiClient';

type GetOnboardingStatusResponse = {
  data: Onboarding;
};

const getOnboardingStatus = () =>
  apiClient.get<GetOnboardingStatusResponse>('/onboarding/status').then(res => res.data.data);

export const useOnboardingStatus = (options?: UseQueryOptions<Onboarding>) =>
  useQuery<Onboarding>('onboardingStatus', () => getOnboardingStatus(), {
    refetchOnMount: false,
    keepPreviousData: true,
    ...options,
  });

// UPDATE ONBOARDING STATUS
type UpdateOnboardingStatusInput = {
  hasSkippedLinkedin?: boolean;
  hasSkippedCreatingSharedNetwork?: boolean;
};

export const updateOnboardingStatus = (input: UpdateOnboardingStatusInput) => {
  return apiClient
    .patch<UpdateOnboardingStatusInput, GetOnboardingStatusResponse>('/onboarding/status', input)
    .then(res => res.data);
};

export const useMutationUpdateOnboardingStatus = (
  options?: UseMutationOptions<Onboarding, AxiosError<ErrorResponse>, Partial<UpdateOnboardingStatusInput>>
) =>
  useMutation<Onboarding, AxiosError<ErrorResponse>, Partial<UpdateOnboardingStatusInput>>(
    newStatusData => updateOnboardingStatus(newStatusData),
    options
  );

export const mapNetwork = (companyID: string) =>
  apiClient
    .post<unknown>(
      '/onboarding/map-network',
      {
        companyID,
      },
      {headers: {'X-Swarm-Bff': 'true'}}
    )
    .then(res => res.data);

type GetCompanyByLiResponse = OnboardingCompany;
export const getCompanyByLi = (companyLiUrl: string) =>
  apiClient
    .get<GetCompanyByLiResponse>('/onboarding/company/by-company-li', {
      headers: {'X-Swarm-Bff': 'true'},
      params: {
        url: companyLiUrl,
      },
    })
    .then(res => res.data);

type GetCompanyByUserLiResponse = OnboardingCompany;
export const getCompanyByUserLiHandler = (userLiUrl: string) =>
  apiClient
    .get<GetCompanyByUserLiResponse>('/onboarding/company/by-user-li', {
      headers: {'X-Swarm-Bff': 'true'},
      params: {
        url: userLiUrl,
      },
    })
    .then(res => res.data);

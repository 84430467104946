import cs from 'classnames';
import {
  AnchorHTMLAttributes,
  ButtonHTMLAttributes,
  MouseEvent as ReactMouseEvent,
  MouseEventHandler,
  ReactElement,
  ReactNode,
} from 'react';
import {
  FiChevronDown as ChevronDown,
  FiChevronLeft as ChevronLeft,
  FiChevronRight as ChevronRight,
  FiChevronUp as ChevronUp,
} from 'react-icons/fi';
import {segmentTrack} from '../../utils';
import {LoadingSpinner} from '../animations';
import Styles from './Button.module.scss';

type LoadingSpinnerProps = Parameters<typeof LoadingSpinner>[0];

export const SIZE = {
  XS: 'xs',
  SM: 'sm',
  MD: 'md',
  LG: 'lg',
  XL: 'xl',
} as const;
type Size = (typeof SIZE)[keyof typeof SIZE];

export const BUTTON_VARIANT = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  TERTIARY: 'tertiary',
  BLACK: 'black',
  RED: 'red',
  GREEN: 'green',
} as const;
type ButtonVariant = (typeof BUTTON_VARIANT)[keyof typeof BUTTON_VARIANT];

const SPINNER_COLOR_MAP: Record<ButtonVariant, LoadingSpinnerProps['color']> = {
  [BUTTON_VARIANT.PRIMARY]: 'white',
  [BUTTON_VARIANT.SECONDARY]: 'black',
  [BUTTON_VARIANT.TERTIARY]: 'black',
  [BUTTON_VARIANT.BLACK]: 'white',
  [BUTTON_VARIANT.RED]: 'red',
  [BUTTON_VARIANT.GREEN]: 'green',
};

export const BUTTON_CHEVRON = {
  UP: 'up',
  RIGHT: 'right',
  DOWN: 'down',
  LEFT: 'left',
} as const;
type ButtonChevron = (typeof BUTTON_CHEVRON)[keyof typeof BUTTON_CHEVRON];

const CHEVRON_MAP: Record<ButtonChevron, ReactElement> = {
  [BUTTON_CHEVRON.UP]: <ChevronUp />,
  [BUTTON_CHEVRON.RIGHT]: <ChevronRight />,
  [BUTTON_CHEVRON.DOWN]: <ChevronDown />,
  [BUTTON_CHEVRON.LEFT]: <ChevronLeft />,
};

export type ButtonProps = {
  variant?: ButtonVariant;
  icon?: ReactNode;
  iconOnly?: boolean;
  chevron?: 'up' | 'right' | 'down' | 'left';
  /**
   * Reverses order of icon and text. Effective only when icon is set.
   */
  reversedOrder?: boolean;
  fullWidth?: boolean;
  children?: ReactNode;
  size?: Size;
  href?: string;
  rel?: string;
  outline?: boolean;
  disabled?: boolean;
  rounded?: boolean;
  /**
   * Show loading state. By default, loading state make button disabled while
   * showing spinner inside and keep button's width unchanged.
   */
  loading?: boolean;
  className?: string;
  target?: AnchorHTMLAttributes<HTMLAnchorElement>['target'];
  type?: ButtonHTMLAttributes<HTMLButtonElement>['type'];
  onClick?: MouseEventHandler<HTMLButtonElement | HTMLAnchorElement>;
  positionInGroup?: 'first' | 'middle' | 'last';
  intercomTarget?: string;
  disableTitleCase?: boolean;
  tracking?: {
    label: string;
    location?: string;
    customProperties?: Record<string, unknown>;
  };
};
// We could try to add this as rest types, but right now it's not working as expected
// & (ButtonHTMLAttributes<HTMLButtonElement> | AnchorHTMLAttributes<HTMLAnchorElement>)

const ButtonSizeMap: Record<Size, string> = {
  [SIZE.XS]: Styles.buttonSizeXSmall,
  [SIZE.SM]: Styles.buttonSizeSmall,
  [SIZE.MD]: Styles.buttonSizeMedium,
  [SIZE.LG]: Styles.buttonSizeLarge,
  [SIZE.XL]: Styles.buttonSizeXLarge,
};

const ButtonVariantMap: Record<ButtonVariant, string> = {
  [BUTTON_VARIANT.PRIMARY]: Styles.buttonVariantPrimary,
  [BUTTON_VARIANT.SECONDARY]: Styles.buttonVariantSecondary,
  [BUTTON_VARIANT.TERTIARY]: Styles.buttonVariantTertiary,
  [BUTTON_VARIANT.BLACK]: Styles.buttonVariantBlack,
  [BUTTON_VARIANT.RED]: Styles.buttonVariantRed,
  [BUTTON_VARIANT.GREEN]: Styles.buttonVariantGreen,
};
export const Button = ({
  size = SIZE.MD,
  variant = BUTTON_VARIANT.SECONDARY,
  fullWidth,
  icon,
  iconOnly,
  chevron,
  reversedOrder,
  href,
  rel,
  disabled,
  loading,
  children,
  className,
  target,
  onClick,
  type,
  outline,
  rounded,
  positionInGroup,
  intercomTarget,
  tracking,
  disableTitleCase,
  ...props
}: ButtonProps) => {
  const isLink = Boolean(href);
  const isDisabled = disabled || loading;

  const btnClass = cs(
    Styles.button,
    ButtonSizeMap[size],
    ButtonVariantMap[variant],
    {
      [Styles.buttonDisabled]: isDisabled,
      [Styles.buttonLoading]: loading,
      [Styles.buttonFullWidth]: fullWidth,
      [Styles.buttonOutline]: outline,
      [Styles.buttonTitleCase]: !disableTitleCase,
      [Styles.buttonIconOnly]: Boolean(icon) && iconOnly,
      [Styles.buttonReverseOrder]: reversedOrder,
      [Styles.buttonRounded]: rounded,
      [Styles.buttonPositionInGroupFirst]: positionInGroup === 'first',
      [Styles.buttonPositionInGroupMiddle]: positionInGroup === 'middle',
      [Styles.buttonPositionInGroupLast]: positionInGroup === 'last',
    },
    className
  );

  const onButtonClick = (e: ReactMouseEvent<HTMLAnchorElement | HTMLButtonElement, MouseEvent>) => {
    if (isDisabled) {
      return;
    }

    if (tracking) {
      // TODO: refactor every single `segmentTrack('Button Clicked'` ocurrence to use `track` property instead
      const {label, location} = tracking;
      segmentTrack('Button Clicked', {
        label,
        ...(location ? {location} : {}),
        ...tracking.customProperties,
      });
    }

    onClick?.(e);
  };

  const TagToRender = isLink ? (isDisabled ? 'span' : 'a') : 'button';

  return (
    <TagToRender
      {...props}
      className={btnClass}
      href={href}
      rel={rel}
      disabled={isDisabled}
      target={target}
      onClick={onButtonClick}
      type={type}
      {...(intercomTarget ? {'data-intercom-target': intercomTarget} : {})}
    >
      <span className={Styles.blend} />
      {icon && <span className={Styles.icon}>{icon}</span>}
      <span className={Styles.text}>{children}</span>
      {chevron && <span className={Styles.icon}>{CHEVRON_MAP[chevron]}</span>}
      {loading && (
        <LoadingSpinner size="auto" color={SPINNER_COLOR_MAP[variant]} className={Styles.spinner} />
      )}
    </TagToRender>
  );
};

import {useCallback, useMemo, useState} from 'react';
import {segmentTrack} from '../../utils';
import {Dropdown} from '../dropdown';
import {DropdownSearch} from '../dropdown-search';
import {AutocompleteFilter} from './AutocompleteFilter';
import {AutocompletionsLoading} from './AutocompletionsLoading';
import {PolymorphicFilterRules} from './PolymorphicFilterRules';
import {PolymorphicFilterProps} from './types';
import {useAutocompletions} from './useAutocompletions';
import {useIsFilterOpen} from './useIsFilterOpen';

type Props = PolymorphicFilterProps<string[]>;

export const PolymorphicMultipleOptionsFilter = ({
  fieldKeysRules,
  title,
  searchable,
  searchPlaceholder,
  exactMatchAutocompletionsSearch,
  exactMatchFiltering,
  teamGraph,
  filters = {},
  externalIsOpen,
  setExternalIsOpen,
  emptyOptionsFallback,
}: Props) => {
  const [isOpen, setIsOpen] = useIsFilterOpen({
    externalIsOpen,
    setExternalIsOpen,
    title,
  });

  const thisFilterKeys = Object.keys(fieldKeysRules); // keys of the current filter
  const currentlyUsedFiltersKeys = Object.keys(filters); // keys of all used filters
  const initialFilterKey = currentlyUsedFiltersKeys.find(key => thisFilterKeys.includes(key)); // find matching filter key from the current filters

  const [currentFilterKey, setCurrentFilterKey] = useState(initialFilterKey || thisFilterKeys[0]);

  const {
    value = [],
    onChange,
    hideOccurrences,
  } = useMemo(() => fieldKeysRules[currentFilterKey], [currentFilterKey, fieldKeysRules]);

  const {autocompletions, isLoading, onSearchChange, searchPhrase} = useAutocompletions({
    fieldKey: currentFilterKey,
    disabled: !isOpen,
    exactMatch: exactMatchAutocompletionsSearch,
    teamGraph,
    filters,
    selectedValues: value,
    limit: 99, // [SWRM-1779] - temporary solution to get "all" results
  });

  const emitApplyEvent = useCallback(
    () =>
      segmentTrack('Selector Applied', {
        label: 'filter',
        filter: title,
      }),
    [title]
  );

  const onItemChange = useCallback(
    (itemValue: string) => {
      if (value.includes(itemValue)) {
        onChange(value.filter(item => item !== itemValue));
      } else {
        onChange([...value, itemValue]);
      }

      emitApplyEvent();
    },
    [onChange, emitApplyEvent, value]
  );

  const clearFilters = useCallback(() => {
    onChange([]);
  }, [onChange]);

  const onClear = useCallback(() => {
    clearFilters();
    segmentTrack('Selector Cleared', {
      label: 'filter',
      filter: title,
    });
  }, [clearFilters, title]);

  const onSearchSubmit = useCallback(
    (val: string) => {
      onChange([val]);
    },
    [onChange]
  );

  const onChangeRule = useCallback(
    (key: string) => {
      clearFilters();
      setCurrentFilterKey(key);
    },
    [setCurrentFilterKey, clearFilters]
  );

  const onSelectAll = useCallback(() => {
    onChange(autocompletions.map(item => (exactMatchFiltering ? `"${item.value}"` : item.value)));
    emitApplyEvent();
  }, [onChange, autocompletions, emitApplyEvent, exactMatchFiltering]);

  return (
    <AutocompleteFilter
      title={title}
      isActive={Boolean(value.length)}
      activeFiltersCount={value.length}
      onClear={onClear}
      search={
        searchable && (
          <DropdownSearch
            query={searchPhrase}
            placeholder={searchPlaceholder}
            onChange={onSearchChange}
            onSubmit={onSearchSubmit}
          />
        )
      }
      onSelectAll={autocompletions.length ? onSelectAll : undefined}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
    >
      <PolymorphicFilterRules
        rulesConfiguration={fieldKeysRules}
        onChangeRule={onChangeRule}
        activeRule={currentFilterKey}
      />
      {isLoading ? (
        <AutocompletionsLoading />
      ) : autocompletions.length ? (
        autocompletions.map(item => {
          const filteringValue = exactMatchFiltering ? `"${item.value}"` : item.value;
          return (
            <Dropdown.Item
              key={item.value}
              label={item.label}
              type={{
                name: 'checkbox',
                onClick: () => onItemChange(filteringValue),
                checked: value.includes(filteringValue),
                badge: hideOccurrences ? undefined : item.count,
              }}
            />
          );
        })
      ) : (
        emptyOptionsFallback
      )}
    </AutocompleteFilter>
  );
};

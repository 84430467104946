import {createContext, ReactNode, useCallback, useContext, useEffect, useState} from 'react';
import {login, requestAuthChallenge, signUp as cognitoSignUp} from '../providers';
import {contains} from '../utils';
import {getErrorMessage} from '../utils/errors';

type ContextValue = {
  loading: boolean;
  email: string;
  setEmail: (email: ContextValue['email']) => void;
  signIn: (onSuccessCallback: () => void) => void;
  signInError: string;
  confirmAuth: (confirmationCode: string) => Promise<boolean>;
  confirmationError: boolean;
  isNewlyCreatedUser: boolean;
};

const AuthContext = createContext({} as ContextValue);
AuthContext.displayName = 'SwarmAuthContext';

export const useAuthContext = () => useContext(AuthContext);

const isUserDoesNotExistError = (error: string) => contains(error, 'User does not exist');

type Props = {
  children: ReactNode;
};
export const AuthContextProvider = ({children}: Props) => {
  const [email, setEmail] = useState<ContextValue['email']>('');
  const [loading, setLoading] = useState<ContextValue['loading']>(false);
  const [isNewlyCreatedUser, setIsNewlyCreatedUser] = useState<ContextValue['isNewlyCreatedUser']>(false);
  const [signInError, setSignInError] = useState('');
  const [confirmationError, setConfirmationError] = useState(false);

  useEffect(() => {
    setSignInError('');
  }, [email]);

  const signUp = useCallback(
    async (onSuccessCallback: () => void) => {
      setLoading(true);

      try {
        await cognitoSignUp(email);
        setIsNewlyCreatedUser(true);
        await requestAuthChallenge(email);

        onSuccessCallback();
      } catch (e) {
        console.error('Sign up failed', e);

        setSignInError(getErrorMessage(e));
      }

      setLoading(false);
    },
    [email]
  );

  const signIn = useCallback(
    async (onSuccessCallback: () => void) => {
      setLoading(true);

      try {
        await requestAuthChallenge(email);
        onSuccessCallback();
      } catch (e) {
        const errorMessage = getErrorMessage(e);

        // If user does not exist, sign up first
        if (isUserDoesNotExistError(errorMessage)) {
          await signUp(onSuccessCallback);
        } else {
          console.error('Log in failed', e); // not needed tracking here
          setSignInError(errorMessage);
        }
      }

      setLoading(false);
    },
    [email, signUp]
  );

  const confirmAuth = useCallback(async (confirmationCode: string) => {
    setLoading(true);
    setConfirmationError(false);

    let success = false;
    try {
      await login({code: confirmationCode});

      success = true;
    } catch (e) {
      setConfirmationError(true);
    }
    setLoading(false);

    return success;
  }, []);

  const value: ContextValue = {
    loading,
    email,
    setEmail,
    signIn,
    signInError,
    confirmationError,
    confirmAuth,
    isNewlyCreatedUser,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

import {TabLinkItem} from '../../components/tabs/types';
import {MembersListItem, MembersListItemStatus, MembersPageTabs} from './types';

export const useTabsConfig = (members: MembersListItem[]) => {
  const activeMembers = members.filter(member => member.status === MembersListItemStatus.Active);
  const invites = members.filter(member => member.status === MembersListItemStatus.Invited);

  const tabLinksConfig: TabLinkItem[] = [
    {
      title: MembersPageTabs.Joined,
      path: '/members',
      badge: ` (${activeMembers.length})`,
    },
    {
      title: MembersPageTabs.Invited,
      path: '/members/invited',
      badge: ` (${invites.length})`,
    },
  ];

  return tabLinksConfig;
};

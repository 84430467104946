import {useCallback, useMemo, useState} from 'react';
import {FiPlus as PlusIcon} from 'react-icons/fi';
import {createBulkSuggestions, useQueryPipelines} from '../../api';
import {useCurrentTeam, usePremiumFeatureCallback} from '../../hooks';
import {Permission, PipelineCommonResponse} from '../../types';
import {pluralizedConnection, segmentTrack} from '../../utils';
import {Button} from '../button';
import {Dropdown} from '../dropdown';
import {DropdownSearch} from '../dropdown-search';
import {notify} from '../notifications';
import {PipelineCreateModal, usePipelineFormModal} from '../pipeline-form';
import {PrivateLabel} from '../private-label';
import {SuggestionIcon} from '../suggestions-indicator';
import {TruncateWithTooltip} from '../truncate-with-tooltip';
import Styles from './AddToPipelineButton.module.scss';
import {EmptyPipelinesListInfo} from './EmptyPipelinesListInfo';

type BulkAddToPipelineButtonProps = {
  profileIDs: string[];
  onAddToPipelineSuccess?: (pipelineId: string, pipelineTitle?: string) => void;
  onCreatePipelineSuccess?: () => void;
};

export const BulkAddToPipelineButton = ({
  profileIDs,
  onAddToPipelineSuccess,
  onCreatePipelineSuccess,
}: BulkAddToPipelineButtonProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const {id: teamId} = useCurrentTeam();

  const {openPipelineFormModal, closePipelineFormModal, pipelineFormModalVisible} = usePipelineFormModal();

  const closeDropdown = useCallback(() => setIsOpen(false), []);

  const handleCreatePipeline = useCallback(() => {
    openPipelineFormModal();
    closeDropdown();
  }, [closeDropdown, openPipelineFormModal]);

  const onCreateSubmit = useCallback(
    (pipelineId: string, pipelineTitle: string) => {
      onAddToPipelineSuccess?.(pipelineId, pipelineTitle);
      onCreatePipelineSuccess?.();
    },
    [onAddToPipelineSuccess, onCreatePipelineSuccess]
  );

  const handleCreatePipelineAsPremiumFeature = usePremiumFeatureCallback({
    callback: handleCreatePipeline,
    permission: Permission.Pipeline,
    onPermissionDenied: closeDropdown,
  });

  const {data} = useQueryPipelines(teamId, {limit: 100, offset: 0});
  const pipelines = useMemo(() => data?.items || [], [data]);

  const pipelinesListNotEmpty = pipelines.length > 0;

  const handleBulkAddToPipeline = useCallback(
    (pipeline: PipelineCommonResponse) => {
      createBulkSuggestions(pipeline.id, {profileIDs})
        .then(closeDropdown)
        .then(() => {
          segmentTrack('Bulk Action Applied', {
            label: 'add to pipeline',
            profile_count: profileIDs.length,
            pipeline_id: pipeline.id,
          });
          notify(
            `Added ${profileIDs.length} ${pluralizedConnection(profileIDs.length)} to ${pipeline.title}.`
          );
        })
        .then(() => onAddToPipelineSuccess?.(pipeline.id));
    },
    [closeDropdown, onAddToPipelineSuccess, profileIDs]
  );
  const bulkAddToPipelineAsPremiumFeature = usePremiumFeatureCallback({
    callback: handleBulkAddToPipeline,
    permission: Permission.Pipeline,
    onPermissionDenied: closeDropdown,
  });

  const searchedPipelines = pipelines.filter(pipeline =>
    pipeline.pipeline.title.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const CreateNewPipelineButton = () => (
    <Button size="sm" variant="tertiary" onClick={handleCreatePipelineAsPremiumFeature} icon={<PlusIcon />}>
      Save to new list
    </Button>
  );

  return (
    <>
      <Dropdown
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        renderDropdownTrigger={() => (
          <Button
            className={Styles.bulkAddToPipelineButton}
            size="sm"
            variant="tertiary"
            outline
            chevron="down"
            icon={<SuggestionIcon />}
          >
            Add to list
          </Button>
        )}
        dropdownHeader={
          pipelinesListNotEmpty && (
            <DropdownSearch query="" placeholder="Search for a List" onChange={q => setSearchQuery(q)} />
          )
        }
        dropdownFooter={<CreateNewPipelineButton />}
      >
        {pipelinesListNotEmpty ? (
          <Dropdown.ItemsGroup
            items={searchedPipelines.map(({pipeline}) => ({
              label: pipeline.title,
              type: {
                name: 'custom',
                onClick: () => bulkAddToPipelineAsPremiumFeature(pipeline),
                element: (
                  <>
                    <TruncateWithTooltip content={pipeline.title}>{pipeline.title}</TruncateWithTooltip>
                    {pipeline.private && <PrivateLabel className="ml-2" />}
                  </>
                ),
              },
              dataIntercomTarget: 'add selected profiles to pipeline',
            }))}
          ></Dropdown.ItemsGroup>
        ) : (
          <EmptyPipelinesListInfo />
        )}
      </Dropdown>
      <PipelineCreateModal
        visible={pipelineFormModalVisible}
        onClose={closePipelineFormModal}
        onSubmit={onCreateSubmit}
        profileIDs={profileIDs}
      />
    </>
  );
};

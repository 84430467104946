import {Helmet} from 'react-helmet';
import {FiAlertTriangle as AlertTriangle} from 'react-icons/fi';
import {PageHeader} from '../../components/page-header';
import {TabLinks} from '../../components/tabs';
import {TabPageContent} from '../../components/tabs/TabPageContent';
import {AppLayout} from '../../layouts';
import {getConfig} from '../../utils';
import {Account, AccountDeletion, ClearUiSettings} from './account';
import {useTabsConfig} from './useTabsConfig';

const {cleanUiSettingsEnabled} = getConfig();

export const AccountPage = () => {
  const tabs = useTabsConfig();

  return (
    <>
      <Helmet>
        <title>Account Settings - The Swarm</title>
      </Helmet>
      <AppLayout
        header={<PageHeader title="Personal Settings" />}
        subHeader={<TabLinks tabs={tabs} activeTab="Account" />}
      >
        <TabPageContent heading="Your profile" subheading="Customize how you appear to your teammates">
          <Account />
        </TabPageContent>
        <TabPageContent
          heading="Delete my account"
          headingIcon={<AlertTriangle className="text-swarm-red" />}
        >
          <AccountDeletion />
        </TabPageContent>

        {cleanUiSettingsEnabled && (
          <TabPageContent
            heading="Clear UI Settings"
            headingIcon={<AlertTriangle className="text-swarm-red" />}
          >
            <ClearUiSettings />
          </TabPageContent>
        )}
      </AppLayout>
    </>
  );
};

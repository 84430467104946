/*
 * Generated type guards for "types.ts".
 * WARNING: Do not manually change this file.
 */
import { StringFilterValue, StringListFilterValue, TokenizedStringFilterValue } from "./types";

export function isStringFilterValue(obj: unknown): obj is StringFilterValue {
    const typedObj = obj as StringFilterValue
    return (
        typeof typedObj === "string"
    )
}

export function isStringListFilterValue(obj: unknown): obj is StringListFilterValue {
    const typedObj = obj as StringListFilterValue
    return (
        Array.isArray(typedObj) &&
        typedObj.every((e: any) =>
            isStringFilterValue(e) as boolean
        )
    )
}

export function isTokenizedStringFilterValue(obj: unknown): obj is TokenizedStringFilterValue {
    const typedObj = obj as TokenizedStringFilterValue
    return (
        (typedObj !== null &&
            typeof typedObj === "object" ||
            typeof typedObj === "function") &&
        Array.isArray(typedObj["list"]) &&
        typedObj["list"].every((e: any) =>
            isStringFilterValue(e) as boolean
        ) &&
        (typeof typedObj["exact_match"] === "undefined" ||
            typedObj["exact_match"] === false ||
            typedObj["exact_match"] === true)
    )
}

import {useCallback, useMemo} from 'react';
import {BulkCrmExportMenu} from '../../components/crm-export-menu';
import {PremiumFeatureClickWrapper} from '../../components/premium-feature-wrapper';
import {Table} from '../../components/table';
import {BulkCompaniesTags} from '../../components/tags';
import {BffCompanyListRow, Permission} from '../../types';
import {pluralizedCompany} from '../../utils';
import {type UseCompaniesTableResult} from './useCompaniesTable';

type Props = {
  tableData: UseCompaniesTableResult;
  onChangeSuccess: () => void;
};

export const CompaniesListTable = ({tableData, onChangeSuccess}: Props) => {
  const selectedRows = tableData.getSelectedRowModel().rows;
  const selectedCompanies = useMemo(() => selectedRows.map(row => row.original), [selectedRows]);
  const selectedCompaniesCount = selectedCompanies.length;
  const shouldShowBulkActions = selectedCompaniesCount > 0;

  const onBulkActionSuccess = useCallback(() => {
    onChangeSuccess();
    tableData.toggleAllRowsSelected(false);
  }, [onChangeSuccess, tableData]);

  const selectedRowsInfo = useMemo(
    () => `${selectedCompaniesCount} ${pluralizedCompany(selectedCompaniesCount)} selected`,
    [selectedCompaniesCount]
  );

  return (
    <Table<BffCompanyListRow>
      headerGroups={tableData.getHeaderGroups()}
      rowModel={tableData.getRowModel()}
      totalWidth={tableData.getTotalSize()}
      rowKeyPath="original.id"
      bulkActionsSlot={
        shouldShowBulkActions && (
          <>
            <BulkCompaniesTags
              selectedCompanies={selectedCompanies}
              onAssignSuccess={onBulkActionSuccess}
              onUnassignSuccess={onBulkActionSuccess}
            />
            <PremiumFeatureClickWrapper permission={Permission.CRMExport}>
              <BulkCrmExportMenu
                targetIds={selectedCompanies.map(company => company.id)}
                targetType="company"
                onSuccess={onChangeSuccess}
              />
            </PremiumFeatureClickWrapper>
            <span>{selectedRowsInfo}</span>
          </>
        )
      }
    />
  );
};

import {ShieldCheck as ShieldIcon} from 'phosphor-react';
import {Card} from '../card';
import {Invitation} from '../invitation';
import {Modal} from '../modal';
import Styles from './PendingInvitations.module.scss';
import {useMyInvites} from './useMyInvites';

type Props = {
  visible: boolean;
  close: () => void;
};
export const PendingInvitations = ({visible, close}: Props) => {
  const {invites, onUpdateStatus} = useMyInvites();

  return (
    <Modal className="w-fit max-w-[540px] p-8" visible={visible} closeButtonVisible onClose={close}>
      <div>
        <h1 className={Styles.header}>You have been invited</h1>
        {invites.map(invite => (
          <Invitation key={invite.invite_id} invite={invite} onUpdateStatus={onUpdateStatus} />
        ))}
        <Card className="mt-4 bg-swarm-gray-50 p-3.5">
          <div className="flex flex-row items-center">
            {/* TODO: safety information component */}
            <ShieldIcon size="26px" color="black" weight="fill" className="mr-4 shrink-0" />
            <p>
              Joining a new team allows you to share your network with its members. Learn about how teams work{' '}
              <a
                target="_blank"
                rel="noreferrer"
                href="https://news.intercom.com/the-swarm/news/16428-multi-team-support-is-live"
                className="text-swarm-black underline"
              >
                here
              </a>
              .
            </p>
          </div>
        </Card>
      </div>
    </Modal>
  );
};

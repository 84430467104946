import cs from 'classnames';
import {map} from 'lodash';
import {useCallback, useEffect, useState} from 'react';
import {useQueryProfile, useQueryRequestIntroConnectors} from '../../api';
import {useCurrentTeam, useCurrentUser} from '../../hooks';
import {getUserFullName, segmentTrack} from '../../utils';
import {Modal} from '../modal';
import {Preview} from './preview';
import {RequestIntroForm} from './request-intro-form';
import Styles from './RequestIntro.module.scss';
import {RequestIntroFormType} from './types';

type RequestIntroProps = {
  profileId: string;
  visible: boolean;
  onClose: () => void;
  pipelineId?: string;
  onSubmitRequestWithPipelineSuccess?: (pipelineId: string) => void;
};

export const RequestIntro = ({
  profileId,
  visible,
  onClose,
  pipelineId,
  onSubmitRequestWithPipelineSuccess,
}: RequestIntroProps) => {
  const currentUser = useCurrentUser();
  const {name: teamName, id: teamId} = useCurrentTeam();
  const {data} = useQueryRequestIntroConnectors({profileId, teamId}, {enabled: Boolean(profileId)});
  const [previewState, setPreviewState] = useState<RequestIntroFormType>();
  const [previewVisible, setPreviewVisible] = useState(false);

  const connectors = data?.connectors || [];

  const sender = getUserFullName(currentUser);
  const senderEmail = currentUser.email;

  const showPreview = useCallback((data: RequestIntroFormType) => {
    setPreviewState(data);
    setPreviewVisible(true);
  }, []);

  const handleModalClose = useCallback(() => {
    onClose();
    setPreviewVisible(false);
  }, [onClose, setPreviewVisible]);

  const {data: profile} = useQueryProfile(profileId, teamId); // TODO: don't fetch profile if profileId is empty
  const candidateFullName = profile?.profile_info.full_name || '';
  const candidateLinkedin = profile?.profile_info.linkedin_url || '';
  const candidatePipelines =
    profile?.pipelines.filter(pipeline => pipeline.containProfile).map(({id}) => id) || [];

  useEffect(() => {
    if (visible && connectors.length) {
      segmentTrack('Modal Viewed', {
        label: 'intro request',
        step: previewVisible ? 'preview' : 'form',
        possible_recipients_ids: map(connectors, 'id'),
        candidate_id: profileId,
        ...(pipelineId && {pipeline_id: pipelineId}),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- we want to track only when previewVisible changes and modal is visible
  }, [connectors.length, previewVisible, visible]);

  return (
    <Modal
      className={Styles.requestIntroModal}
      visible={visible}
      onClose={handleModalClose}
      closeButtonVisible
    >
      <div className={cs({[Styles.hiddenSection]: !previewVisible})}>
        <Preview
          sender={sender}
          senderEmail={senderEmail}
          data={previewState}
          close={() => setPreviewVisible(false)}
        />
      </div>
      <div className={cs({[Styles.hiddenSection]: previewVisible})}>
        <h4 className={Styles.header}>Send intro request</h4>
        <p>
          This email will be sent from <span className={Styles.email}>noreply@theswarm.com</span>.
        </p>
        <hr className={Styles.divider} />
        {connectors.length > 0 && (
          <RequestIntroForm
            connectors={connectors}
            sender={sender}
            senderEmail={senderEmail}
            teamName={teamName}
            profileFullName={candidateFullName}
            profileLinkedin={candidateLinkedin}
            profileId={profileId}
            profilePipelines={candidatePipelines}
            pipelineId={pipelineId}
            close={onClose}
            showPreview={showPreview}
            onSubmitRequestWithPipelineSuccess={onSubmitRequestWithPipelineSuccess}
          />
        )}
      </div>
    </Modal>
  );
};

import {
  affinityIcon,
  csvIcon,
  googleIcon,
  hubspotIcon,
  linkedinIcon,
  linkedinOldIcon,
  linkedinWarningIcon,
  roleAdmin,
  roleConnector,
  roleContributor,
  roleGuest,
  salesforceIcon,
  starsIcon,
  swarmLogo,
  workOverlapsIcon,
} from './assets';
import {SwarmIcon} from './SwarmIcon';

export {RoleIcon} from './RoleIcon';

export const SwarmLogoIcon = SwarmIcon({
  iconName: 'SwarmLogo',
  imgSrc: swarmLogo,
  alt: 'Swarm icon',
});

export const CsvIcon = SwarmIcon({iconName: 'Csv', imgSrc: csvIcon, alt: 'CSV icon'});

export const GoogleIcon = SwarmIcon({iconName: 'Google', imgSrc: googleIcon, alt: 'Google icon'});

export const LinkedinIcon = SwarmIcon({
  iconName: 'Linkedin',
  imgSrc: linkedinIcon,
  alt: 'LinkedIn icon',
});

export const LinkedinWarningIcon = SwarmIcon({
  iconName: 'LinkedinWarning',
  imgSrc: linkedinWarningIcon,
  alt: 'LinkedIn Warning icon',
});

export const LinkedinOldIcon = SwarmIcon({
  iconName: 'LinkedinOld',
  imgSrc: linkedinOldIcon,
  alt: 'LinkedIn icon',
});

export const WorkOverlapsIcon = SwarmIcon({
  iconName: 'WorkOverlaps',
  imgSrc: workOverlapsIcon,
  alt: 'Work Overlaps icon',
});

export const PremiumStarsIcon = SwarmIcon({
  iconName: 'PremiumStars',
  imgSrc: starsIcon,
  alt: 'Premium Stars icon',
});

export const AffinityIcon = SwarmIcon({
  iconName: 'Affinity',
  imgSrc: affinityIcon,
  alt: 'Affinity CRM icon',
});

export const HubSpotIcon = SwarmIcon({
  iconName: 'HubSpot',
  imgSrc: hubspotIcon,
  alt: 'HubSpot CRM icon',
});

export const SalesforceIcon = SwarmIcon({
  iconName: 'Salesforce',
  imgSrc: salesforceIcon,
  alt: 'Salesforce CRM icon',
});

export const RoleAdminIcon = SwarmIcon({
  iconName: 'Role Admin',
  imgSrc: roleAdmin,
  alt: 'Role Admin icon',
});

export const RoleContributorIcon = SwarmIcon({
  iconName: 'Role Contributor',
  imgSrc: roleContributor,
  alt: 'Role Contributor icon',
});

export const RoleGuestIcon = SwarmIcon({
  iconName: 'Role Guest',
  imgSrc: roleGuest,
  alt: 'Role Guest icon',
});

export const RoleConnectorIcon = SwarmIcon({
  iconName: 'Role Connector',
  imgSrc: roleConnector,
  alt: 'Role Connector icon',
});

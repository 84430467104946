import {useCallback, useState} from 'react';

export const useAddMembersModal = () => {
  const [addMembersVisible, setAddMembersVisible] = useState(false);

  const closeAddMembersModal = () => {
    setAddMembersVisible(false);
  };

  const openAddMembersModal = useCallback(() => {
    setAddMembersVisible(true);
  }, []);

  return {
    addMembersVisible,
    closeAddMembersModal,
    openAddMembersModal,
  };
};

import {cloneElement, ReactElement} from 'react';
import {Placement} from 'react-laag';
import {PermissionChecker, type PermissionCheckerProps} from '../permission';
import {UpgradeTooltip} from '../upgrade-tooltip';
import {UpgradeTooltipContentLocation} from '../upgrade-tooltip/UpgradeTooltipContent';

type Props = Pick<PermissionCheckerProps, 'children' | 'permission'> &
  Pick<Partial<PermissionCheckerProps>, 'fallback'> & {
    featureName?: string;
    location?: UpgradeTooltipContentLocation;
    tooltipPlacement?: Placement;
    fallbackProps?: object; // if passed, will be forwarded to the children component as props and rendered instead as fallback
  };

export const PremiumFeatureWrapper = ({
  featureName,
  permission,
  fallback,
  fallbackProps,
  tooltipPlacement,
  location,
  children,
}: Props) => {
  if (Boolean(fallback) && Boolean(fallbackProps)) {
    throw new Error('Cannot use both fallback and fallbackProps');
  }

  if (!fallback && Boolean(fallbackProps)) {
    fallback = cloneElement(children as ReactElement, fallbackProps);
  }

  const decoratedFallback =
    featureName && location ? (
      <UpgradeTooltip featureName={featureName} placement={tooltipPlacement} location={location}>
        {fallback}
      </UpgradeTooltip>
    ) : (
      fallback
    );

  return (
    <PermissionChecker permission={permission} missingPlanFallback={decoratedFallback}>
      {children}
    </PermissionChecker>
  );
};

import {useState} from 'react';
import {MultipleOptionsFilter} from './MultipleOptionsFilter';
import {FilterProps, StringListFilterValue} from './types';
import {useAutocompletions} from './useAutocompletions';

type Props = FilterProps<StringListFilterValue>;
export const ProfileSearchMultipleOptionsFilter = (props: Props) => {
  const {
    fieldKey,
    value = [],
    exactMatchAutocompletionsSearch,
    exactMatchFiltering,
    teamGraph,
    filters,
    labelsMapping,
    forceOrder,
    alphabeticalOrder,
    directItems,
    clientSideSearchable,
    disabled,
    decorateOptions,
    emptyOptionsFallback,
    hideOccurrences,
  } = props;
  const [isOpen, setIsOpen] = useState(false);

  const {
    autocompletions = [],
    isLoading,
    onSearchChange,
    searchPhrase,
  } = useAutocompletions({
    fieldKey,
    disabled: Boolean(directItems) || !isOpen,
    exactMatch: exactMatchAutocompletionsSearch,
    teamGraph,
    filters,
    selectedValues: value,
    labelsMapping,
    forceOrder,
    alphabeticalOrder,
    clientSideSearchable,
    limit: 99, // [SWRM-1779] - temporary solution to get "all" results
  });

  const allFilterOptions = [
    ...(directItems ? directItems : []),
    ...(autocompletions.length ? autocompletions : []),
  ];
  const filterOptions = clientSideSearchable
    ? allFilterOptions.filter(option => option.label.toLowerCase().includes(searchPhrase.toLowerCase()))
    : allFilterOptions;

  const optionsToDisplay = decorateOptions ? decorateOptions(filterOptions) : filterOptions;

  const showClientSideSearch = clientSideSearchable && allFilterOptions.length > 3;

  return (
    <MultipleOptionsFilter
      {...props}
      clientSideSearchable={showClientSideSearch}
      filterOptions={optionsToDisplay}
      isLoading={isLoading}
      onSearchPhraseChange={onSearchChange}
      searchPhrase={searchPhrase}
      selectedOptionsValues={value}
      externalIsOpen={isOpen}
      setExternalIsOpen={setIsOpen}
      exactMatchFiltering={exactMatchFiltering}
      searchSubmit
      disabled={disabled}
      emptyOptionsFallback={emptyOptionsFallback}
      hideOccurrences={hideOccurrences}
    />
  );
};

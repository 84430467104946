import {useQuery, UseQueryOptions} from 'react-query';
import {
  CompaniesAggregateRequest,
  CompaniesAggregateResponse,
  CompaniesRequest,
  CompaniesResponse,
  Company,
} from '../types';
import {apiClient} from './apiClient';

const getCompany = async (id: string): Promise<Company> =>
  apiClient.get<Company>(encodeURI(`/companies/${id}`)).then(res => res.data);

export const useQueryCompany = (id: string) => useQuery(['company', id], () => getCompany(id));

const getCompanies = async (params: CompaniesRequest): Promise<CompaniesResponse> =>
  apiClient.get<CompaniesResponse>('/companies', {params}).then(res => res.data);

export const useQueryCompanies = (
  teamId: string,
  params: CompaniesRequest,
  options: UseQueryOptions<CompaniesResponse> = {}
) => useQuery<CompaniesResponse>(['companies', teamId, params], () => getCompanies(params), options);

const getCompanyAggregate = async (params: CompaniesAggregateRequest): Promise<CompaniesAggregateResponse> =>
  apiClient.get<CompaniesAggregateResponse>('/companies/aggregate', {params}).then(res => res.data);

export const useQueryCompanyAggregate = (
  teamId: string,
  params: CompaniesAggregateRequest,
  options: UseQueryOptions<CompaniesAggregateResponse> = {}
) =>
  useQuery<CompaniesAggregateResponse>(
    ['companiesAggregate', teamId, params],
    () => getCompanyAggregate(params),
    options
  );

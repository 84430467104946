import {SuitcaseSimple, UsersThree} from 'phosphor-react';
import {HideableContent} from '../../common';
import {FindOutMoreItem} from './FindOutMoreItem';

type FindOutMoreProps = {
  visible: boolean;
};
export const FindOutMoreContent = ({visible}: FindOutMoreProps) => (
  <HideableContent visible={visible} className="py-2">
    <FindOutMoreItem
      title="Adding your work overlaps"
      text="We'll jumpstart your network by curating a list of people you may know based on your work experience. Don’t worry, they won’t be notified."
      icon={UsersThree}
    />

    <FindOutMoreItem
      title="How it works"
      text="We find hundreds of “work overlaps” by looking at the names of companies you worked at, the position and timeframes, and compare those with our database of millions of profiles!"
      icon={SuitcaseSimple}
    />
  </HideableContent>
);

import {useEffect, useMemo} from 'react';
import {useQueryProfiles} from '../../../api';
import {BulkAddToPipelineButton} from '../../../components/add-to-pipeline';
import {LoadingSpinner} from '../../../components/animations';
import {NoResults} from '../../../components/no-results';
import {RequestIntro, useRequestIntroModal} from '../../../components/request-intro';
import {Table, UseSwarmTableProps} from '../../../components/table';
import {prepareQueryProfilesInput} from '../../../components/table/prepareProfilesQueryInput';
import {useProfilesTable} from '../../../components/table/useProfilesTable';
import {MyConnectionTableFilter, Profile} from '../../../types';
import {pluralizedConnection} from '../../../utils';

type Props = {
  tableFilters: MyConnectionTableFilter;
  searchQuery: string;
  pagination: Required<UseSwarmTableProps>['pagination'];
  sorting: Required<UseSwarmTableProps>['sorting'];
  visibleColumnsState: UseSwarmTableProps['visibleColumnsState'];
  columnsOrder: UseSwarmTableProps['columnsOrder'];
  availableColumns: UseSwarmTableProps['availableColumns'];
  teamId: string;
  companyName: string;
};
export const ConnectionsTable = ({
  tableFilters,
  searchQuery,
  pagination,
  sorting,
  teamId,
  visibleColumnsState,
  columnsOrder,
  availableColumns,
  companyName,
}: Props) => {
  const {introModalVisible, closeIntroModal, openIntroModal, introProfileId} = useRequestIntroModal();

  const {paginationState, totalCount, setMeta} = pagination;
  const {sortingState} = sorting;

  const getProfilesInput = prepareQueryProfilesInput({
    teamGraph: true,
    filters: tableFilters.filters,
    ...(searchQuery.length && {query: searchQuery}),
    ...paginationState,
    sorting: sortingState,
  });

  const {
    data: connections,
    refetch: refetchProfiles,
    isFetchedAfterMount,
    isLoading,
  } = useQueryProfiles(teamId, getProfilesInput, {
    keepPreviousData: true,
    onSuccess: ({meta}) => setMeta(meta),
    refetchOnWindowFocus: false, // TODO: remove this when we have a better default sorting for the received data
  });

  const rows = connections?.data || [];

  const table = useProfilesTable({
    rows,
    isLoaded: isFetchedAfterMount,
    teamGraph: true,
    searchQuery,
    tableFilters,
    pagination,
    sorting,
    refetchProfiles,
    openIntroModal,
    companyName,
    visibleColumnsState,
    columnsOrder,
    availableColumns,
  });

  const selectedRows = table.getSelectedRowModel().rows;
  const selectedProfileIDs = useMemo(
    () => selectedRows.map(row => row.original.profile_info.id),
    [selectedRows]
  );

  const selectedProfilesCount = selectedProfileIDs.length;
  const shouldShowBulkActions = selectedProfilesCount > 0;

  const selectedRowsInfo = useMemo(
    () => `${selectedProfilesCount} ${pluralizedConnection(selectedProfilesCount)} selected`,
    [selectedProfilesCount]
  );

  useEffect(() => {
    refetchProfiles();
  }, [refetchProfiles, teamId]);

  if (isLoading) return <LoadingSpinner size="small" color="black" centered />;
  if (isFetchedAfterMount && totalCount === 0) {
    return (
      <NoResults
        heading="Sorry, no results found for this search."
        subheading="Please try again with different search parameters."
        page="results"
      ></NoResults>
    );
  }

  return (
    <>
      <Table<Profile>
        headerGroups={table.getHeaderGroups()}
        rowModel={table.getRowModel()}
        totalWidth={table.getTotalSize()}
        bulkActionsSlot={
          shouldShowBulkActions && (
            <>
              <BulkAddToPipelineButton
                profileIDs={selectedProfileIDs}
                onAddToPipelineSuccess={() => refetchProfiles()}
              />
              <span>{selectedRowsInfo}</span>
            </>
          )
        }
        rowKeyPath="original.profile_info.id"
      />
      <RequestIntro profileId={introProfileId} visible={introModalVisible} onClose={closeIntroModal} />
    </>
  );
};

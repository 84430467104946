import {CrmIntegration, FilterOptionsItem, ScopeFilterOptions, TagSimple} from '../../types';
import {formatRange, integrationNameMap, parseRange} from '../../utils';
import {CrmIcon} from '../crm-icon';
import {Tag} from '../tags';
import {AnyFilterValue} from './types';
import {isStringFilterValue, isStringListFilterValue, isTokenizedStringFilterValue} from './types.guard';

export const isFilterEmpty = (filterValue: AnyFilterValue) => {
  if (isTokenizedStringFilterValue(filterValue)) {
    return filterValue.list.length === 0;
  }

  if (isStringListFilterValue(filterValue)) {
    return filterValue.length === 0;
  }

  if (isStringFilterValue(filterValue)) {
    return filterValue === '';
  }

  return false;
};

export const mapSizeOptions = (filterOptions: FilterOptionsItem[]): FilterOptionsItem[] => {
  return filterOptions.map(option => ({
    ...option,
    label: formatRange(parseRange(option.value)),
  }));
};

export const getScopeOptions = (filterOptions: FilterOptionsItem[]): FilterOptionsItem[] => {
  return [
    {
      value: ScopeFilterOptions.InNetwork,
      label: 'In-network',
      count: filterOptions.find(option => option.value === ScopeFilterOptions.InNetwork)?.count || 0,
    },
    {
      value: ScopeFilterOptions.OutOfNetwork,
      label: 'Out-of-network',
      count: filterOptions.find(option => option.value === ScopeFilterOptions.OutOfNetwork)?.count || 0,
    },
  ];
};

export const getTagsOptions = (tags: TagSimple[]): FilterOptionsItem[] => {
  return tags.map(tag => ({
    value: tag.id,
    label: tag.name,
    enhancedLabel: <Tag content={tag.name} color={tag.color} />,
  }));
};

export const getCrmSourceOptions = (integrations: CrmIntegration[]): FilterOptionsItem[] => {
  return integrations.map(integration => ({
    value: integration.id,
    label: integration.crm,
    enhancedLabel: (
      <div className="flex items-center gap-1">
        <CrmIcon crm={integration.crm} className="!size-4" /> {integrationNameMap[integration.crm]}
      </div>
    ),
  }));
};

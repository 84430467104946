import {ReactElement} from 'react';
import {Navigate, useLocation, useParams} from 'react-router-dom';
import {Analytics, Authorized} from './containers';
import {OnboardingContextProvider} from './contexts';
import {
  AfterGoogleSignin,
  AuthPage,
  CompaniesPage,
  DashboardPage,
  ErrorPage,
  MagicLinkLandingPage,
  ManageAccessPage,
  MyConnectionsPage,
  NewCompanyProfilePage,
  NewSingleProfilePage,
  PipelineDetailsPage,
  PipelineProfilesPage,
  PipelineSavedSearchPage,
  PipelinesPage,
  ProfilesPage,
  TeamInvitedMembersPage,
  TeamJoinedMembersPage,
  ThankYouPage,
  UpgradePlanPage,
} from './pages';
import {CompaniesPage as NewCompaniesPage} from './pages/companies-new';
import {AccountPage, SettingsPage} from './pages/settings';
import {IntegrationsPage, SubscriptionPage, TagsPage, TeamSettingsPage} from './pages/team-settings';
import {Permission} from './types';
import {getConfig} from './utils';

const {profilesPageEnabled, newCompaniesPageEnabled} = getConfig();

//TODO: remove this temporary redirect when all the links are updated (BE, FE, emails, website)
const TemporaryRedirectToConnections = () => {
  const {search} = useLocation();
  const redirectPath = `/connections${search}`;

  return <Navigate to={redirectPath} replace={true} />;
};

//TODO: remove this temporary redirect when all the links are updated (BE, FE, emails, website)
const LegacyRedirectToLists = () => {
  const {search, hash} = useLocation();
  let redirectPath = `/lists${search}`;

  if (hash) {
    redirectPath += hash;
  }

  return <Navigate to={redirectPath} replace={true} />;
};
const LegacyRedirectToList = ({subpage}: {subpage?: 'saved-search' | 'details'}) => {
  const {id = ''} = useParams();
  const {search, hash} = useLocation();
  let redirectPath = `/lists/${id}`;
  if (subpage) {
    redirectPath += `/${subpage}`;
  }
  if (search) {
    redirectPath += search;
  }
  if (hash) {
    redirectPath += hash;
  }

  return <Navigate to={redirectPath} replace={true} />;
};

const publicRoutes = [
  {element: <AuthPage />, path: '/login'},
  {element: <AuthPage />, path: '/sign-in'},
  {element: <AfterGoogleSignin />, path: '/google-signin'},
  {element: <MagicLinkLandingPage />, path: '/shared-network/:code'},
];

const privateRoutes = [
  {element: <DashboardPage />, path: '/'},
  {element: <TemporaryRedirectToConnections />, path: '/network-graph'},
  {element: <MyConnectionsPage teamGraph={true} />, path: '/connections'},
  ...(profilesPageEnabled ? [{element: <ProfilesPage teamGraph={true} />, path: '/profiles'}] : []),
  {element: <MyConnectionsPage teamGraph={false} />, path: '/my-connections'},
  {element: <ManageAccessPage />, path: '/grant-access'},
  {element: <NewSingleProfilePage />, path: '/people/:id'},
  {
    element: (
      <Authorized permission={Permission.PlanUpdate}>
        <UpgradePlanPage />
      </Authorized>
    ),
    path: '/upgrade-plan',
  },
  {
    element: (
      <Authorized permission={Permission.PlanUpdate}>
        <ThankYouPage />
      </Authorized>
    ),
    path: '/upgrade-success',
  },
  {
    element: (
      <Authorized permission={Permission.TeamMember}>
        <TeamJoinedMembersPage />
      </Authorized>
    ),
    path: '/members',
  },
  {
    element: (
      <Authorized permission={Permission.TeamMember}>
        <TeamInvitedMembersPage />
      </Authorized>
    ),
    path: '/members/invited',
  },
  {
    element: (
      <Authorized permission={Permission.Company}>
        <CompaniesPage />
      </Authorized>
    ),
    path: '/companies',
  },
  ...(newCompaniesPageEnabled
    ? [
        {
          element: (
            <Authorized permission={Permission.Company}>
              <NewCompaniesPage />
            </Authorized>
          ),
          path: '/new-companies',
        },
      ]
    : []),
  {
    element: (
      <Authorized permission={Permission.Company}>
        <NewCompanyProfilePage />
      </Authorized>
    ),
    path: '/new-companies/:id',
  },
  {element: <PipelinesPage />, path: '/lists'},
  {element: <LegacyRedirectToLists />, path: '/pipelines'},
  {
    element: (
      <Authorized permission={Permission.Pipeline}>
        <PipelineProfilesPage />
      </Authorized>
    ),
    path: '/lists/:id',
  },
  {element: <LegacyRedirectToList />, path: '/pipelines/:id'},
  {
    element: (
      <Authorized permission={Permission.Pipeline}>
        <PipelineProfilesPage />
      </Authorized>
    ),
    path: '/lists/:id/profiles',
  },
  {
    element: (
      <Authorized permission={Permission.Pipeline}>
        <PipelineSavedSearchPage />
      </Authorized>
    ),
    path: '/lists/:id/saved-search',
  },
  {element: <LegacyRedirectToList subpage="saved-search" />, path: '/pipelines/:id/saved-search'},
  {
    element: (
      <Authorized permission={Permission.Pipeline}>
        <PipelineDetailsPage />
      </Authorized>
    ),
    path: '/lists/:id/details',
  },
  {element: <LegacyRedirectToList subpage="details" />, path: '/pipelines/:id/details'},
  {
    element: (
      <Authorized>
        <SettingsPage />
      </Authorized>
    ),
    path: '/settings',
  },
  {
    element: (
      <Authorized>
        <AccountPage />
      </Authorized>
    ),
    path: '/settings/account',
  },
  {
    element: (
      <Authorized permission={Permission.TeamSettingsUpdate}>
        <TeamSettingsPage />
      </Authorized>
    ),
    path: '/team-settings',
  },
  {
    element: (
      <Authorized permission={Permission.PlanUpdate}>
        <SubscriptionPage />
      </Authorized>
    ),
    path: '/team-settings/subscription',
  },
  {
    element: (
      <Authorized permission={Permission.CRMIntegration}>
        <IntegrationsPage />
      </Authorized>
    ),
    path: '/team-settings/integrations',
  },
  {
    element: (
      <Authorized permission={Permission.TagUpdate}>
        <TagsPage />
      </Authorized>
    ),
    path: '/team-settings/tags',
  },
  {element: <ErrorPage forbidden />, path: '/403'},
  {element: <ErrorPage />, path: '/*'},
];

const withAuthorization = (route: Route): Route => {
  const {element, path} = route;
  return {
    path,
    element: (
      <OnboardingContextProvider>
        <Authorized>
          <Analytics>{element}</Analytics>
        </Authorized>
      </OnboardingContextProvider>
    ),
  };
};
type Route = {path: string; element: ReactElement};
export const routes: Route[] = [...publicRoutes, ...privateRoutes.map(withAuthorization)];

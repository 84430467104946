import {useCallback} from 'react';
import {useMutation, useQueryClient} from 'react-query';
import {
  deleteInvite,
  deleteMember,
  getMembersQueryKey,
  resendInvite,
  updateMember,
  useGetTeamInvites,
  useGetTeamMembers,
} from '../api';
import {MembersListItem} from '../pages/team-members/types';
import {
  convertInviteToMembersListItem,
  convertMemberToMembersListItem,
  isValuableInvitation,
} from '../pages/team-members/utils';
import {Member, Role} from '../types';
import {useCurrentTeam} from './index';

export const useTeamMembers = (refetchOnMount = true) => {
  const queryClient = useQueryClient();
  const team = useCurrentTeam();
  const teamId = team.id;

  const {
    data: members = [],
    isLoading: isListLoading,
    refetch: refetchMembers,
  } = useGetTeamMembers(teamId, refetchOnMount);
  const {
    data: invites = [],
    isLoading: isInvitesLoading,
    refetch: refetchInvites,
  } = useGetTeamInvites(teamId, refetchOnMount);

  const membersQueryKey = getMembersQueryKey(teamId);

  // COMBINED MEMBERS COLLECTION
  const membersListItems = [
    ...members.map(convertMemberToMembersListItem),
    ...invites.filter(isValuableInvitation).map(convertInviteToMembersListItem),
  ];

  // UPDATE MEMBER
  const memberUpdateMutation = useMutation(updateMember, {
    onMutate: async input => {
      const isMutatingMember = (m: MembersListItem | Member) => m.id === input.memberId;
      const previousMembers = queryClient.getQueryData<Member[]>(membersQueryKey);

      // cancel outgoing refetches
      await queryClient.cancelQueries(membersQueryKey);

      // optimistic update
      if (previousMembers) {
        queryClient.setQueryData<Member[]>(
          membersQueryKey,
          previousMembers.map(m => (isMutatingMember(m) ? {...m, role: input.role} : m))
        );
      }
    },
    onSettled: () => {
      refetchMembers();
    },
  });
  const onUpdateMemberRole = useCallback(
    (member: MembersListItem, newRole: Role) =>
      memberUpdateMutation.mutate({
        teamId: member.teamId,
        memberId: member.id,
        role: newRole,
      }),
    [memberUpdateMutation]
  );

  // DELETE MEMBER
  const deleteMemberMutation = useMutation(deleteMember, {
    onSettled: () => refetchMembers(),
  });
  const onDeleteMember = useCallback(
    (member: MembersListItem) =>
      deleteMemberMutation.mutateAsync({
        teamId,
        memberId: member.id,
      }),
    [deleteMemberMutation, teamId]
  );

  // RESEND INVITATION
  const resendInvitation = useCallback(
    async (inviteId: string) => {
      await resendInvite(teamId, inviteId);
      refetchInvites();
    },
    [refetchInvites, teamId]
  );

  // DELETE INVITATION
  const deleteInvitation = useCallback(
    async (inviteId: string) => {
      await deleteInvite({teamId, inviteId});
      refetchInvites();
    },
    [refetchInvites, teamId]
  );

  return {
    members: membersListItems,
    refetchInvites,
    isLoading: isListLoading || isInvitesLoading,
    onUpdateMemberRole,
    onDeleteMember,
    resendInvitation,
    deleteInvitation,
  };
};

import {isEqual} from 'lodash';
import {ReactElement, ReactNode, useEffect, useRef} from 'react';
import {useLocation} from 'react-router-dom';
import {useCurrentPlan, useQueryProfilesCount} from '../api';
import {useUserSessionContext} from '../contexts';
import {useCurrentUserSession, usePrevious, useTeamsList} from '../hooks';
import {EnhancedTeam} from '../types';
import {
  UserAnalyticsData,
  gtmSetupUser,
  segmentIdentifyUser,
  segmentPageview,
  sentryIdentify,
  setupSegmentRole,
  setupSegmentTeamGroup,
} from '../utils';

type Props = {
  children: ReactNode;
};

const identifyUser = (analyticsUser: UserAnalyticsData) => {
  gtmSetupUser(analyticsUser);
  segmentIdentifyUser(analyticsUser);
  sentryIdentify(analyticsUser);
};

const useCachedData = () => {
  const {isFetched, user, team} = useCurrentUserSession();
  const {data: plan, isFetched: isFetchedPlan} = useCurrentPlan();

  const previousUserObj = useRef(user);
  const previousUser = previousUserObj.current;
  const previousTeamObj = useRef(team);
  const previousTeam = previousTeamObj.current;

  const isUserChanged = !isEqual(previousUser, user);
  const isTeamChanged = !isEqual(previousTeam, team);

  useEffect(() => {
    if (isUserChanged) {
      previousUserObj.current = user;
    }
    if (isTeamChanged) {
      previousTeamObj.current = team;
    }
  }, [isUserChanged, isTeamChanged, user, team]);

  const currentUser = isUserChanged ? user : previousUser;
  const currentTeam = isTeamChanged ? team : previousTeam;

  return {
    isFetched: isFetched && isFetchedPlan,
    user: currentUser,
    team: currentTeam,
    plan,
  };
};

export const Analytics = ({children}: Props) => {
  const {pathname} = useLocation();
  const previousPathname = usePrevious(pathname, true);
  const {isFetched, user, team, plan} = useCachedData();
  const teamsList = useTeamsList();
  const {data: connectionsCount} = useQueryProfilesCount();

  const {
    user: {linkedInUrl, intercomHash},
  } = useUserSessionContext(); // TODO: use useCurrentUser after refactoring in useCurrentUserSession hook

  const teamsRoles = teamsList.reduce((acc: Record<string, string>, team: EnhancedTeam) => {
    acc[team.name] = team.role;
    return acc;
  }, {});

  const teamsRolesString = JSON.stringify(teamsRoles);

  useEffect(() => {
    segmentPageview({
      previousPathname,
    });
  }, [pathname, previousPathname]);

  useEffect(() => {
    if (!isFetched) {
      return;
    }

    identifyUser({
      email: user.email,
      name: `${user.firstName} ${user.lastName}`,
      id: user.id,
      teamId: team?.id,
      ...(team
        ? {
            role: team.role,
            userTeams: teamsRolesString,
          }
        : {}),
      linkedinUrl: linkedInUrl,
      connectionsCount,
      intercomHash,
    });
  }, [intercomHash, isFetched, user, team, teamsRolesString, linkedInUrl, connectionsCount]);

  useEffect(() => {
    if (!isFetched) {
      return;
    }
    setupSegmentTeamGroup({
      id: team?.id || 'no team assigned',
      name: team?.name || '',
      membersCount: team?.membersCount || 0,
      ...(plan && {plan}),
    });
  }, [isFetched, team, plan]);

  useEffect(() => {
    if (team?.role) {
      setupSegmentRole({
        role: team.role,
      });
    }
  }, [team?.role]);

  return children as ReactElement;
};

export const prepareSearchQuery = (phrase: string) => {
  const purgedPhrase = phrase.trim().replace(/"/g, '');
  const isSingleWord = purgedPhrase.split(' ').length === 1;

  if (isSingleWord) {
    return purgedPhrase;
  } else {
    return `"${purgedPhrase}"`;
  }
};

import cs from 'classnames';
import {MouseEvent, ReactElement, ReactNode, useCallback, useMemo} from 'react';
import {FiCheck as CheckIcon, FiX as XIcon} from 'react-icons/fi';
import {segmentTrack} from '../../utils';
import {LoadingSpinner} from '../animations';
import {Button} from '../button';
import {CheckMark} from '../marks';
import Styles from './DashboardTask.module.scss';
import {TaskPurpose} from './types';
import {useTaskState} from './useDashboardTask.hook';

type Props = {
  type: TaskPurpose;
  title: string;
  description: ReactNode;
  icon?: ReactNode;
  count?: number;
  inProgress?: boolean;
  onHide?: () => void;
  onClick?: () => void;
};

const IMPORT_TASKS: Partial<TaskPurpose>[] = [TaskPurpose.overlaps, TaskPurpose.linkedin, TaskPurpose.google];

export const DashboardTask = ({type, title, description, icon, count, inProgress, onClick}: Props) => {
  const {hidden, done, setDone, setHidden} = useTaskState(type);
  const isImportTask = IMPORT_TASKS.includes(type);
  const isComplete = isImportTask ? !inProgress && Boolean(count) : Boolean(done);

  const subtitle = useMemo(() => {
    if (isComplete) {
      return 'Completed';
    }
    if (inProgress) {
      return 'In progress';
    }
    return description;
  }, [isComplete, inProgress, description]);

  const handleOnClick = useCallback(() => {
    if (!isComplete) {
      onClick?.();
    }
  }, [isComplete, onClick]);

  if (hidden) {
    return null;
  }

  return (
    <li
      className={cs(Styles.task, {
        [Styles.taskComplete]: isComplete,
        [Styles.hasAction]: Boolean(onClick),
      })}
      onClick={handleOnClick}
    >
      <div className={Styles.leftSide}>
        <div className={Styles.iconWrapper}>
          {isComplete ? <CheckMark shape="circle" size="small" /> : icon}
        </div>
        <div className={Styles.heading}>
          <span className={Styles.title}>{title}</span>
          <span className={Styles.subtitle}>{subtitle}</span>
        </div>
      </div>
      <div className={Styles.rightSide}>
        {count ? <span className={Styles.count}>{count.toLocaleString('en-US')} connections</span> : null}
        {inProgress ? (
          <>
            <LoadingSpinner size="x-small" color="black" />
            <span className="text-sm text-swarm-gray-800">Processing...</span>
          </>
        ) : (
          <TaskActions
            isImportTask={isImportTask}
            complete={isComplete}
            setDone={setDone}
            setHidden={setHidden}
            type={type}
          />
        )}
      </div>
    </li>
  );
};

const TaskPurposeToStrategyMap: Record<TaskPurpose, string> = {
  [TaskPurpose.overlaps]: 'overlaps',
  [TaskPurpose.linkedin]: 'plugin',
  [TaskPurpose.google]: 'google',
  [TaskPurpose.review]: 'review',
  [TaskPurpose.addMembers]: 'add members',
};
type TaskActionsProps = {
  isImportTask: boolean;
  complete: boolean;
  setDone: () => void;
  setHidden: () => void;
  type: TaskPurpose;
};
const TaskActions = ({isImportTask, complete, setDone, setHidden, type}: TaskActionsProps) => {
  const actions: ReactElement[] = [];

  const wrapClickHandlerWithTracking = useCallback(
    (actionName: string, handler: () => void) => {
      const strategy = TaskPurposeToStrategyMap[type];
      return () => {
        handler();
        segmentTrack('Button Clicked', {
          label: actionName,
          location: 'home page',
          strategy,
        });
      };
    },
    [type]
  );

  if (!isImportTask && !complete) {
    actions.push(
      <TaskAction
        key="done"
        label="Mark as done"
        icon={<CheckIcon />}
        onClick={wrapClickHandlerWithTracking('mark as done', setDone)}
      />
    );
  }

  if (!complete) {
    actions.push(
      <TaskAction
        key="skip"
        label="Skip"
        icon={<XIcon />}
        onClick={wrapClickHandlerWithTracking('skip', setHidden)}
      />
    );
  }

  if (complete) {
    actions.push(
      <TaskAction
        key="hide"
        label="Hide"
        icon={<XIcon />}
        onClick={wrapClickHandlerWithTracking('hide', setHidden)}
      />
    );
  }

  return <div className={Styles.actions}>{actions}</div>;
};

type TaskActionProps = {
  label: string;
  icon?: ReactNode;
  onClick: () => void;
};
const TaskAction = ({label, icon, onClick}: TaskActionProps) => {
  const handleClick = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation(); // prevent click on the task container, which performs task action
      onClick();
    },
    [onClick]
  );

  return (
    <Button className={Styles.actionButton} onClick={handleClick} icon={icon} size="sm" variant="tertiary">
      {label}
    </Button>
  );
};

import {
  AddressBook as ConnectionsIcon,
  Folders as SourcesIcon,
  Gift as ReferAndEarnIcon,
  PuzzlePiece as ExtensionIcon,
  SignOut as SignOutIcon,
} from 'phosphor-react';
import {useCallback, useState} from 'react';
import {FiSettings as PersonalSettingsIcon, FiShare2 as AccessIcon} from 'react-icons/fi';
import {useNavigate} from 'react-router-dom';
import {useCurrentUser, useExtensionVersion} from '../../../hooks';
import {logout} from '../../../providers';
import {getConfig, getUserFullName, goToChromeExtension} from '../../../utils';
import {Dropdown, DropdownItemProps} from '../../dropdown';
import {DropdownItem} from '../../dropdown/DropdownItem';
import {MenuTrigger} from '../components';
import {MenuHeader} from './MenuHeader';
import Styles from './UserMenu.module.scss';

const {futureUserMenuItemsEnabled, grantAccessEnabled} = getConfig();
const TRACKING_LOCATION = 'sidebar user menu';
export const UserMenu = () => {
  const [userMenuOpen, setUserMenuOpen] = useState(false);
  const closeUserMenu = useCallback(() => setUserMenuOpen(false), []);

  const currentUser = useCurrentUser();
  const userFullName = getUserFullName(currentUser);

  const navigate = useNavigate();
  const goToMyConnections = useCallback(() => navigate({pathname: '/my-connections'}), [navigate]);
  const goToSettings = useCallback(() => navigate({pathname: '/settings'}), [navigate]);
  const goToManageAccess = useCallback(() => navigate({pathname: '/grant-access'}), [navigate]);
  const goToReferAndEarn = useCallback(() => window.open('https://www.theswarm.com/company/refer'), []);

  const closeMenuAfterAction = (action: () => void) => {
    action();
    closeUserMenu();
  };

  const extensionVersion = useExtensionVersion();

  return (
    <Dropdown
      isOpen={userMenuOpen}
      setIsOpen={setUserMenuOpen}
      placement="top-start"
      snapWidth
      tracking={{
        label: 'user',
        location: 'sidebar',
      }}
      renderDropdownTrigger={ref => (
        <MenuTrigger label={userFullName} ref={ref} dropdownOpen={userMenuOpen} bottomPlacement />
      )}
      dropdownHeader={
        <MenuHeader email={currentUser.email} fullName={userFullName} closeDropdown={closeUserMenu} />
      }
    >
      <Dropdown.ItemsGroup
        items={[
          {
            label: 'My Connections',
            tracking: {
              label: 'my connections',
              location: TRACKING_LOCATION,
            },
            type: {
              name: 'button',
              onClick: () => closeMenuAfterAction(goToMyConnections),
            },
            icon: <ConnectionsIcon />,
          },
          ...(grantAccessEnabled
            ? [
                {
                  label: 'Grant access',
                  tracking: {
                    label: 'grant access',
                    location: TRACKING_LOCATION,
                  },
                  type: {
                    name: 'button',
                    onClick: () => closeMenuAfterAction(goToManageAccess),
                  },
                  icon: <AccessIcon />,
                } satisfies DropdownItemProps,
              ]
            : []),
          {
            label: 'Personal Settings',
            tracking: {
              label: 'settings',
              location: TRACKING_LOCATION,
            },
            type: {
              name: 'button',
              onClick: () => closeMenuAfterAction(goToSettings),
            },
            icon: <PersonalSettingsIcon />,
          } satisfies DropdownItemProps,
          ...(futureUserMenuItemsEnabled
            ? [
                {
                  label: 'Connection sources',
                  tracking: {
                    label: 'connection souurces',
                    location: TRACKING_LOCATION,
                  },
                  type: {
                    name: 'button',
                    onClick: () => {},
                  },
                  icon: <SourcesIcon />,
                } satisfies DropdownItemProps,
              ]
            : []),
          {
            label: 'Chrome Extension',
            tracking: {
              label: 'chrome extension',
              location: TRACKING_LOCATION,
            },
            type: {
              name: 'button',
              onClick: () => closeMenuAfterAction(goToChromeExtension),
            },
            icon: <ExtensionIcon />,
          },
          {
            label: 'Refer & Earn',
            tracking: {
              label: 'refer and earn',
              location: TRACKING_LOCATION,
            },
            type: {
              name: 'button',
              onClick: () => closeMenuAfterAction(goToReferAndEarn),
            },
            icon: <ReferAndEarnIcon />,
          },
          ...(extensionVersion
            ? [
                {
                  label: 'version',
                  noHover: true,
                  type: {
                    name: 'custom',
                    onClick: () => {},
                    disabled: true,
                    element: (
                      <span className="-mt-1 text-xs text-swarm-gray-700">
                        Installed version: {extensionVersion}
                      </span>
                    ),
                  },
                } satisfies DropdownItemProps,
              ]
            : []),
        ]}
      />
      <hr className={Styles.divider} />
      <DropdownItem
        label="Sign Out"
        tracking={{
          label: 'sign out',
          location: TRACKING_LOCATION,
        }}
        type={{
          name: 'button',
          onClick: () => closeMenuAfterAction(logout),
        }}
        icon={<SignOutIcon />}
      />
    </Dropdown>
  );
};

export enum PlanType {
  Free = 'Free',
  Base = 'Base',
  Premium = 'Premium',
}

export enum PaymentPeriod {
  Monthly = 'Monthly',
  Yearly = 'Yearly',
}

export type Plan = {
  payment?: Payment;
  type: PlanType;
  trialAvailable: boolean;
  updatedAt: string;
};

export type Payment = {
  canceled: boolean;
  creditCardCollected: boolean;
  endDate: string;
  period: PaymentPeriod;
  trialActive: boolean;
};

import {useCallback, useMemo} from 'react';
import {BulkCrmExportMenu} from '../../components/crm-export-menu';
import {PremiumFeatureClickWrapper} from '../../components/premium-feature-wrapper';
import {Table} from '../../components/table';
import {BulkCompaniesTags} from '../../components/tags';
import {Company, Crm, Permission, TagSimple} from '../../types';
import {pluralizedCompany} from '../../utils';
import {type UseCompaniesTableResult} from './useCompaniesTable';

type Props = {
  tableData: UseCompaniesTableResult;
  onAssignTagSuccess: (profileIds: string[], tag: TagSimple) => void;
  onUnassignTagSuccess: (profileIds: string[], tagId: string) => void;
  onCrmExportSuccess: (profileIds: string[], crm: Crm) => void;
};

export const CompaniesListTable = ({
  tableData,
  onAssignTagSuccess,
  onUnassignTagSuccess,
  onCrmExportSuccess,
}: Props) => {
  const selectedRows = tableData.getSelectedRowModel().rows;
  const selectedCompanies = useMemo(() => selectedRows.map(row => row.original), [selectedRows]);
  const selectedCompaniesIds = useMemo(
    () => selectedCompanies.map(company => company.id),
    [selectedCompanies]
  );
  const selectedCompaniesCount = selectedCompanies.length;
  const shouldShowBulkActions = selectedCompaniesCount > 0;

  const onBulkAssignTagSuccess = useCallback(
    (tag: TagSimple) => {
      onAssignTagSuccess(selectedCompaniesIds, tag);
      tableData.toggleAllRowsSelected(false);
    },
    [onAssignTagSuccess, selectedCompaniesIds, tableData]
  );
  const onBulkUnassignTagSuccess = useCallback(
    (tagId: string) => {
      onUnassignTagSuccess(selectedCompaniesIds, tagId);
      tableData.toggleAllRowsSelected(false);
    },
    [onUnassignTagSuccess, selectedCompaniesIds, tableData]
  );

  const selectedRowsInfo = useMemo(
    () => `${selectedCompaniesCount} ${pluralizedCompany(selectedCompaniesCount)} selected`,
    [selectedCompaniesCount]
  );

  return (
    <Table<Company>
      headerGroups={tableData.getHeaderGroups()}
      rowModel={tableData.getRowModel()}
      totalWidth={tableData.getTotalSize()}
      rowKeyPath="original.id"
      bulkActionsSlot={
        shouldShowBulkActions && (
          <>
            <BulkCompaniesTags
              selectedCompanies={selectedCompanies}
              onAssignSuccess={onBulkAssignTagSuccess}
              onUnassignSuccess={onBulkUnassignTagSuccess}
            />
            <PremiumFeatureClickWrapper permission={Permission.CRMExport}>
              <BulkCrmExportMenu
                targetIds={selectedCompanies.map(company => company.id)}
                targetType="company"
                onSuccess={crm => onCrmExportSuccess(selectedCompaniesIds, crm)}
              />
            </PremiumFeatureClickWrapper>
            <span>{selectedRowsInfo}</span>
          </>
        )
      }
    />
  );
};

export enum CompaniesColumns {
  Name = 'name',
  Tags = 'tags',
  Industry = 'industry',
  Headquarters = 'locationName',
  Size = 'size',
  ProfileCount = 'profileCount',
  ConnectedMembers = 'connectedMembersCount',
}

export const labelsMap = {
  [CompaniesColumns.Name]: 'Name',
  [CompaniesColumns.Tags]: 'Company Tags',
  [CompaniesColumns.Industry]: 'Industry',
  [CompaniesColumns.Headquarters]: 'Headquarters',
  [CompaniesColumns.Size]: 'Company Size',
  [CompaniesColumns.ProfileCount]: 'Connections',
  [CompaniesColumns.ConnectedMembers]: 'Connected To',
};

export const COMPANIES_COLUMNS_PREFERENCES_KEY = 'companies_columns';
export const availableColumns = Object.values(CompaniesColumns).map(column => column as string);

import {Button} from '../../../components/button';
import {useSubscription} from '../../../hooks';
import {PaymentPeriod, PlanType} from '../../../types';
import {humanizeDate} from '../../../utils';

type Props = {
  requestedPlan: PlanType;
  period?: PaymentPeriod;
};

const CurrentPlanButton = () => <Button disabled>Current Plan</Button>;

const DowngradeToFreeButton = ({cancel, plan}: {cancel: () => void; plan: PlanType}) => (
  <Button
    onClick={cancel}
    tracking={{
      label: 'Downgrade to Free',
      customProperties: {
        plan,
      },
    }}
  >
    Downgrade to Free
  </Button>
);
const RenewPlanButton = ({manage, plan}: {manage: () => void; plan: PlanType}) => (
  <Button
    variant="primary"
    onClick={manage}
    tracking={{
      label: 'Renew Plan',
      customProperties: {
        plan,
      },
    }}
  >
    Renew Plan
  </Button>
);
const StartingOnDateButton = ({date}: {date: string}) => (
  <Button disabled>Starting on {humanizeDate(date)}</Button>
);
const UpgradeButton = ({upgrade, plan}: {upgrade: () => void; plan: PlanType}) => (
  <Button
    variant="primary"
    onClick={upgrade}
    tracking={{
      label: 'Upgrade',
      customProperties: {
        plan,
      },
    }}
  >
    Upgrade
  </Button>
);
const AddPaymentMethodButton = ({
  updatePaymentMethod,
  plan,
}: {
  updatePaymentMethod: () => void;
  plan: PlanType;
}) => (
  <Button
    variant="primary"
    onClick={updatePaymentMethod}
    tracking={{
      label: 'Add payment method',
      customProperties: {
        plan,
      },
    }}
  >
    Add payment method
  </Button>
);
const TalkToSalesButton = (plan: {plan: PlanType}) => (
  <Button
    variant="secondary"
    href="https://www.theswarm.com/talk-to-sales"
    target="_blank"
    tracking={{
      label: 'Talk to Sales',
      customProperties: {
        plan,
      },
    }}
  >
    Talk to Sales
  </Button>
);

enum ButtonType {
  CurrentPlan = 'CurrentPlan',
  RenewPlan = 'RenewPlan',
  StartingOnDate = 'StartingOnDate',
  DowngradeToFree = 'DowngradeToFree',
  Upgrade = 'Upgrade',
  AddPaymentMethod = 'AddPaymentMethod',
  TalkToSales = 'TalkToSales',
}

const buttonology = {
  freePlanActive: {
    [PlanType.Free]: ButtonType.CurrentPlan,
    [PlanType.Base]: ButtonType.Upgrade,
    [PlanType.Premium]: ButtonType.Upgrade,
  },
  basePlanActive: {
    [PlanType.Free]: ButtonType.DowngradeToFree,
    [PlanType.Base]: ButtonType.CurrentPlan,
    [PlanType.Premium]: ButtonType.TalkToSales,
  },
  premiumPlanActive: {
    [PlanType.Free]: ButtonType.DowngradeToFree,
    [PlanType.Base]: ButtonType.TalkToSales,
    [PlanType.Premium]: ButtonType.CurrentPlan,
  },
  basePlanTrial: {
    [PlanType.Free]: ButtonType.DowngradeToFree,
    [PlanType.Base]: ButtonType.AddPaymentMethod,
    [PlanType.Premium]: ButtonType.TalkToSales,
  },
  premiumPlanTrial: {
    [PlanType.Free]: ButtonType.DowngradeToFree,
    [PlanType.Base]: ButtonType.TalkToSales,
    [PlanType.Premium]: ButtonType.AddPaymentMethod,
  },
  basePlanCanceled: {
    [PlanType.Free]: ButtonType.StartingOnDate,
    [PlanType.Base]: ButtonType.RenewPlan,
    [PlanType.Premium]: ButtonType.TalkToSales,
  },
  premiumPlanCanceled: {
    [PlanType.Free]: ButtonType.StartingOnDate,
    [PlanType.Base]: ButtonType.TalkToSales,
    [PlanType.Premium]: ButtonType.RenewPlan,
  },
};
export const SubscriptionButton = ({requestedPlan, period}: Props) => {
  const {cancel, manage, updatePaymentMethod, upgrade, plan, isFreePlan, isPlanLoading} = useSubscription(
    period,
    requestedPlan
  );

  if (isPlanLoading) return <Button loading variant="secondary" />;

  const isBase = plan && plan.type === PlanType.Base;
  const isPremium = plan && plan.type === PlanType.Premium;
  const paymentProvided = plan && plan.payment?.creditCardCollected;
  const isCanceled = plan && plan.payment?.canceled;

  const freePlanActive = isFreePlan;
  const basePlanActive = isBase && paymentProvided && !isCanceled;
  const premiumPlanActive = isPremium && paymentProvided && !isCanceled;
  const basePlanTrial = isBase && !paymentProvided && plan.payment?.trialActive;
  const premiumPlanTrial = isPremium && !paymentProvided && plan.payment?.trialActive;
  const basePlanCanceled = isBase && plan.payment?.canceled;
  const premiumPlanCanceled = isPremium && plan.payment?.canceled;

  const buttonMap = {
    [ButtonType.CurrentPlan]: <CurrentPlanButton />,
    [ButtonType.RenewPlan]: <RenewPlanButton manage={manage} plan={requestedPlan} />,
    [ButtonType.StartingOnDate]: <StartingOnDateButton date={plan?.payment?.endDate || ''} />,
    [ButtonType.DowngradeToFree]: <DowngradeToFreeButton cancel={cancel} plan={requestedPlan} />,
    [ButtonType.Upgrade]: <UpgradeButton upgrade={upgrade} plan={requestedPlan} />,
    [ButtonType.AddPaymentMethod]: (
      <AddPaymentMethodButton updatePaymentMethod={updatePaymentMethod} plan={requestedPlan} />
    ),
    [ButtonType.TalkToSales]: <TalkToSalesButton plan={requestedPlan} />,
  };

  if (freePlanActive) {
    return buttonMap[buttonology.freePlanActive[requestedPlan]];
  }
  if (basePlanActive) {
    return buttonMap[buttonology.basePlanActive[requestedPlan]];
  }
  if (premiumPlanActive) {
    return buttonMap[buttonology.premiumPlanActive[requestedPlan]];
  }
  if (basePlanTrial) {
    return buttonMap[buttonology.basePlanTrial[requestedPlan]];
  }
  if (premiumPlanTrial) {
    return buttonMap[buttonology.premiumPlanTrial[requestedPlan]];
  }
  if (basePlanCanceled) {
    return buttonMap[buttonology.basePlanCanceled[requestedPlan]];
  }
  if (premiumPlanCanceled) {
    return buttonMap[buttonology.premiumPlanCanceled[requestedPlan]];
  }
  return null;
};

import {ColumnDef} from '@tanstack/react-table';
import cs from 'classnames';
import {useMemo} from 'react';
import {PiPencilSimple as EditIcon, PiTrashSimple as RemoveIcon} from 'react-icons/pi';
import {Button} from '../../../components/button';
import {
  onRowHoverVisible,
  TableInnerHeader,
  useSwarmTable,
  UseSwarmTableProps,
} from '../../../components/table';
import {Tag} from '../../../components/tags';
import {useCheckPermission, useCurrentUser} from '../../../hooks';
import {Permission, TagListItem} from '../../../types';
import {getUserFullName} from '../../../utils';
import Styles from './TagsManagement.module.scss';

export type UseTagsTableProps = {
  rows: TagListItem[];
  isLoaded: boolean;
  editTag: (tagId: string) => void;
  removeTag: (tagId: string) => void;
  sorting: UseSwarmTableProps['sorting'];
};

export const useTagsTable = ({rows, isLoaded, editTag, removeTag, sorting}: UseTagsTableProps) => {
  const {id: currentUserId} = useCurrentUser();
  const canTeamTagUpdate = useCheckPermission(Permission.TeamTagUpdate);

  const columns = useMemo<ColumnDef<TagListItem>[]>(
    () => [
      {
        accessorKey: 'name',
        header: () => <TableInnerHeader label="Tag name" />,
        cell: info => {
          const {tag} = info.row.original;
          const isOwnTag = currentUserId === tag.creator.id;
          const canUpdateTag = canTeamTagUpdate || isOwnTag;

          return (
            <div className={Styles.nameCell}>
              <Tag content={tag.name} color={tag.color} className={Styles.name} />
              {canUpdateTag && (
                <>
                  <Button
                    iconOnly
                    icon={<EditIcon />}
                    size="xs"
                    className={cs(Styles.actionButton, onRowHoverVisible)}
                    onClick={() => editTag(tag.id)}
                  />
                  <Button
                    iconOnly
                    icon={<RemoveIcon />}
                    variant="red"
                    size="xs"
                    className={cs(Styles.actionButton, onRowHoverVisible)}
                    onClick={() => removeTag(tag.id)}
                  />
                </>
              )}
            </div>
          );
        },
        size: 360,
      },
      {
        accessorKey: 'createdAt',
        header: () => <TableInnerHeader label="Created" />,
        cell: info => {
          const {tag} = info.row.original;
          const {createdAt: timestamp, creator} = tag;
          return (
            timestamp && (
              <div>
                <div className={Styles.creationCell}>
                  <span className={Styles.time}>{new Date(timestamp).toLocaleString()}</span>
                  <span className={Styles.creator}>by {getUserFullName(creator)}</span>
                </div>
              </div>
            )
          );
        },
        size: 260,
      },
      {
        accessorKey: 'profileCount',
        header: () => <TableInnerHeader label="Connections" />,
        cell: info => {
          const {count} = info.row.original;
          return <div className={Styles.count}>{count.profile || '0'}</div>;
        },
        size: 120,
      },
      {
        accessorKey: 'companyCount',
        header: () => <TableInnerHeader label="Companies" />,
        cell: info => {
          const {count} = info.row.original;
          return <div className={Styles.count}>{count.company || '0'}</div>;
        },
        size: 120,
      },
    ],
    [canTeamTagUpdate, currentUserId, editTag, removeTag]
  );

  return useSwarmTable<TagListItem>({
    uniqueName: 'tags',
    rows,
    isLoaded,
    selectable: false,
    columns,
    sorting,
  });
};

import {Dispatch, SetStateAction, useCallback, useState} from 'react';
import {segmentTrack} from '../../utils';
import {ConditionalIsOpenProps} from './types';

type UseIsOpenProps = ConditionalIsOpenProps & {title: string};

export const useIsFilterOpen = ({
  title,
  externalIsOpen,
  setExternalIsOpen,
}: UseIsOpenProps): [boolean, Dispatch<SetStateAction<boolean>>] => {
  const [isOpen, setIsOpen] = useState(false);

  const currentValue = externalIsOpen ?? isOpen;
  const setterFunction = setExternalIsOpen ?? setIsOpen;

  const enhancedSetterFunction: Dispatch<SetStateAction<boolean>> = useCallback(
    newVal => {
      setterFunction(newVal);

      if (newVal === true) {
        segmentTrack('Selector Opened', {
          label: 'filter',
          filter: title,
        });
      }
    },
    [title, setterFunction]
  );

  return [currentValue, enhancedSetterFunction];
};

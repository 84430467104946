import {FunctionComponent, useMemo} from 'react';
import {Helmet} from 'react-helmet';
import {FiSearch as SearchIcon} from 'react-icons/fi';
import {LoadingSpinner} from '../../components/animations';
import {Input} from '../../components/form';
import {NoResults} from '../../components/no-results';
import {PageHeader} from '../../components/page-header';
import {useTableSearch} from '../../components/table';
import {TabLinks} from '../../components/tabs';
import {useTeamMembers} from '../../hooks';
import {AppLayout} from '../../layouts';
import {AddMembersButton} from './AddMembersButton';
import {MembersListItem, MembersPageTabs} from './types';
import {useTabsConfig} from './useTabsConfig';
import {searchInMemberListItem, useSortedMembers} from './utils';

type Props = {
  activeTab: MembersPageTabs;
  ListComponent: FunctionComponent<{members: MembersListItem[]}>;
};

export const TeamMembersPage = ({activeTab, ListComponent}: Props) => {
  const {searchQuery, onChangeSearchQuery, onClearSearchQuery} = useTableSearch();
  const {members, isLoading, refetchInvites} = useTeamMembers();
  const sortedMembers = useSortedMembers(members);
  const tabsConfig = useTabsConfig(members);

  const matchingMembers = useMemo(
    () => sortedMembers.filter(member => searchInMemberListItem(member, searchQuery)),
    [sortedMembers, searchQuery]
  );

  let content = <ListComponent members={matchingMembers} />;

  if (isLoading) {
    content = (
      <div className="my-5 mt-20 flex w-full justify-center">
        <LoadingSpinner size="small" color="black" centered />
      </div>
    );
  }

  if (!isLoading && !matchingMembers.length) {
    content = (
      <NoResults
        heading="Sorry, no results found for this search."
        subheading="Please try again with different search phrase."
        page="results"
      ></NoResults>
    );
  }

  return (
    <>
      <Helmet>
        <title>Members - The Swarm</title>
      </Helmet>
      <AppLayout
        header={
          <PageHeader
            title={members.length ? `Members (${members.length})` : 'Members'}
            rightSlot={<AddMembersButton onSuccess={refetchInvites} />}
          >
            <Input
              icon={SearchIcon}
              type="text"
              value={searchQuery}
              onChange={e => onChangeSearchQuery(e.target.value)}
              onClear={onClearSearchQuery}
              className="max-w-sm"
              placeholder="Search for name or email"
              intercomTarget="search"
            />
          </PageHeader>
        }
        subHeader={<TabLinks tabs={tabsConfig} activeTab={activeTab} />}
      >
        {content}
      </AppLayout>
    </>
  );
};

import {Crm} from '../../types';
import {integrationNameMap} from '../../utils';
import {CrmIcon} from '../crm-icon';
import {Tooltip} from '../tooltip';

type Props = {
  crms: Crm[];
};

export const VcardIntegrationsList = ({crms}: Props) => {
  if (!crms.length) {
    return null;
  }

  return (
    <div className="ml-2 inline-flex items-center justify-center gap-1">
      {crms.map((crm, index) => (
        <Tooltip content={`Synchronized with ${integrationNameMap[crm]} CRM`} key={index}>
          <CrmIcon crm={crm} />
        </Tooltip>
      ))}
    </div>
  );
};

import {Info as InfoIcon} from 'phosphor-react';
import {useCallback, useState} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {useMutationSendNetworkSharingInvitation, useQueryProfilesCount} from '../../api';
import {useCurrentUser} from '../../hooks';
import {SendInvitationRequestError} from '../../types';
import {getUserFullName, segmentTrack} from '../../utils';
import {AddInvitationNote} from '../add-invitation-note';
import {COLOR, LoadingSpinner} from '../animations';
import {Button} from '../button';
import {Card} from '../card';
import {Textarea} from '../form';
import {FormWrapper} from '../form-wrapper';
import {notify} from '../notifications';
import {Tabs} from '../tabs';
import {InviteByLink} from './InviteByLink';
import Styles from './ManageConnectionsAccess.module.scss';
import {divideEmails} from './utils';

// TODO change to SendInvitationRequest
type ManageConnectionsAccessFormState = {
  note: string;
  inviteeEmails: string; // TODO: change to array and handle with dedicated component for emails list
};

type Props = {
  goBack: () => void;
};

export const ManageConnectionsAccess = ({goBack}: Props) => {
  const [note, setNote] = useState('');
  const {data: profilesCount, isFetched: isProfilesCountFetched} = useQueryProfilesCount();
  const currentUser = useCurrentUser();
  const sender = getUserFullName(currentUser);

  const {
    formState: {isSubmitting, errors},
    control,
    handleSubmit,
    setError,
  } = useForm<ManageConnectionsAccessFormState>({
    defaultValues: {
      inviteeEmails: '',
      note: '',
    },
  });

  const addNoteContent = isProfilesCountFetched ? (
    <p>
      {sender} has invited you to see and search through their {String(profilesCount)} connections and send
      intro requests!
      <br />
      <br />
      Join them on{' '}
      <a href="https://theswarm.com" target="_blank" className="underline" rel="noreferrer">
        The Swarm
      </a>{' '}
      and start combining multiple networks into one (free up to five members).
    </p>
  ) : (
    <LoadingSpinner className="pb-8" size="small" color={COLOR.BLUE} centered />
  );

  const onSuccess = useCallback(() => {
    notify('The invitation has been sent.');

    segmentTrack('Notification Viewed', {
      message: 'invitation was sent',
      type: 'confirmation',
      process: 'network sharing',
    });

    goBack();
  }, [goBack]);

  const mutationSendNetworkSharingInvitation = useMutationSendNetworkSharingInvitation({
    onSuccess,
    onError: e => onError(e.response?.data),
  });

  const onError = useCallback(
    (e: SendInvitationRequestError | undefined) => {
      if (!e?.errors?.length) {
        setError('inviteeEmails', {message: 'Something went wrong. Please try again later.'});
        return;
      }

      const error = e.errors[0];

      if (error.title) {
        setError('inviteeEmails', {message: error.title});
      } else {
        setError('inviteeEmails', {message: 'Check email addresses you have provided'});
      }
    },
    [setError]
  );

  const onSubmit = useCallback(
    (formState: ManageConnectionsAccessFormState) => {
      const invitees = divideEmails(formState.inviteeEmails).filter(Boolean);

      segmentTrack('Form Submitted', {
        form: 'network sharing',
        recipients_count: invitees.length,
      });

      mutationSendNetworkSharingInvitation.mutate({
        inviteeEmails: invitees,
        note,
      });
    },
    [mutationSendNetworkSharingInvitation, note]
  );

  const handleCancel = useCallback(() => {
    segmentTrack('Button Clicked', {
      label: 'cancel',
      location: 'manage access',
    });
    goBack();
  }, [goBack]);

  const addByEmailView = (
    <>
      <p className="mb-4">
        You can send invitations to multiple people separating them with a comma, space or newline.
      </p>

      <Controller
        control={control}
        name="inviteeEmails"
        render={({field: {onChange, value}}) => (
          <Textarea
            value={value}
            onChange={e => {
              onChange(e.target.value);
            }}
            isInvalid={Boolean(errors.inviteeEmails)}
            message={errors.inviteeEmails?.message}
            placeholder="someone@acme.com, someone-else@corporate.com"
            rows={4}
          />
        )}
      />

      <div className={Styles.actionsBar}>
        <Button onClick={handleSubmit(onSubmit)} variant="primary" loading={isSubmitting}>
          Send now
        </Button>

        <Button onClick={handleCancel} variant="tertiary">
          Cancel
        </Button>

        <AddInvitationNote
          note={note}
          setNote={setNote}
          content={addNoteContent}
          subject={`${sender} has shared their network with you on The Swarm`}
          title={`${sender} has shared their network with you`}
        />
      </div>
    </>
  );

  const addByLinkView = (
    <>
      <p className={Styles.addByLinkViewCaption}>Share the link below to grant access to your network.</p>

      <InviteByLink className={Styles.addByLinkViewForm} />

      <p className={Styles.addByLinkViewCaption}>
        <InfoIcon size="16px" color="black" />
        Invite link will expire automatically in 14 days.
      </p>
    </>
  );

  return (
    <div>
      <FormWrapper heading="Share my connections externally" fullWidth>
        <div className="text-swarm-gray-700">
          <p className="mb-4">
            Give access to your network outside of your current team. Each invitee will start their own free
            team and you’ll automatically be added.
          </p>

          <Tabs
            tabs={[
              {
                title: 'Invite by email',
                content: addByEmailView,
              },
              {
                title: 'Invite by link',
                content: addByLinkView,
              },
            ]}
          />
        </div>
      </FormWrapper>

      <Card className="mt-4 bg-swarm-gray-50 p-5">
        <div className="flex flex-row items-center gap-4 text-swarm-gray-700">
          <InfoIcon size="20px" color="black" weight="fill" />
          <p>
            You’re about to invite people to see only your connections. <br />
            To add members to your existing team, invite them from the{' '}
            <a target="_blank" href="/members" className="text-swarm-black underline">
              Members
            </a>{' '}
            tab.
          </p>
        </div>
      </Card>
    </div>
  );
};

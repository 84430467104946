import {ImportStrategy, TaskDataProvider} from '../../../../types';
import {CsvIcon, GoogleIcon, LinkedinIcon, WorkOverlapsIcon} from '../../../icons';
import {ReactElement} from 'react';

type TypeSetting = {
  title: string;
  description: string;
  icon: ReactElement;
  strategy: ImportStrategy;
};

export const taskSettings: Record<TaskDataProvider, TypeSetting> = {
  [TaskDataProvider.Google]: {
    title: 'Importing from Google',
    description: 'We’re adding the people you’ve emailed or met with. It’ll take a few minutes to complete.',
    icon: <GoogleIcon />,
    strategy: 'google',
  },
  [TaskDataProvider.LinkedInCSV]: {
    title: 'Importing your CSV',
    description: 'We’re adding new connections from your CSV file. It’ll take a few minutes to complete.',
    icon: <CsvIcon />,
    strategy: 'csv',
  },
  [TaskDataProvider.LinkedInPlugin]: {
    title: 'Syncing your LinkedIn',
    description:
      'We’re syncing your first-degree LinkedIn connections. It’ll take a few minutes to complete.',
    icon: <LinkedinIcon />,
    strategy: 'plugin',
  },
  [TaskDataProvider.WorkOverlaps]: {
    title: 'Finding work overlaps',
    description: 'We’re jumpstarting your network by adding people you may know from your work experience!',
    icon: <WorkOverlapsIcon />,
    strategy: 'overlaps',
  },
  [TaskDataProvider.UserProfile]: {
    title: 'Importing your profile',
    description: 'Processing your connections... It’ll take a few minutes to complete.',
    icon: <LinkedinIcon />,
    strategy: 'user profile',
  },
};

import {Profile, ProfilesListRow, TagSimple} from '../../../types';
import {isProfilesListRow} from '../../../types/bffTypes.guard';
import {BulkTags} from './BulkTags';

type Props = {
  selectedProfiles: Profile[] | ProfilesListRow[];
  onAssignSuccess?: (tag: TagSimple) => void;
  onUnassignSuccess?: (tagId: string) => void;
};

export const BulkProfilesTags = ({selectedProfiles, onAssignSuccess, onUnassignSuccess}: Props) => {
  const isBffProfiles = isProfilesListRow(selectedProfiles[0]);

  const selectedEntitiesIds = selectedProfiles.map(
    entity => (isBffProfiles ? (entity as ProfilesListRow).id : (entity as Profile).profile_info.id) // TODO clean this up after remove old fashioned profiles view
  );
  const selectedTags = selectedProfiles.map(entity => entity.tags.map(tag => tag.id));
  return (
    <BulkTags
      targetType="profile"
      selectedEntitiesIds={selectedEntitiesIds}
      selectedTags={selectedTags}
      onAssignSuccess={onAssignSuccess}
      onUnassignSuccess={onUnassignSuccess}
    />
  );
};

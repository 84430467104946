import {User} from '../../../types';
import {getUserFullName} from '../../../utils';
import {Avatar, AvatarCollection} from '../../avatar';
import {Tooltip} from '../../tooltip';
import Styles from './ConnectedMembersCellContent.module.scss';

type Props = {
  connectedMembers: User[];
  showAllOnTooltip?: boolean;
};

export const ConnectedMembersCellContent = ({connectedMembers = [], showAllOnTooltip}: Props) => {
  const fullNames = connectedMembers.map(getUserFullName);
  const membersCount = fullNames.length;
  const displayLimit = 4;
  return (
    <div className={Styles.connectedMembersContainer}>
      <Tooltip
        disabled={membersCount <= 1}
        content={<TooltipContent members={fullNames} limit={showAllOnTooltip ? undefined : displayLimit} />}
      >
        <div className={Styles.stackedConnectedMembers}>
          <AvatarCollection limit={displayLimit} size="xs">
            {fullNames.map(fullName => (
              <Avatar fullname={fullName} size="xs" key={fullName} />
            ))}
          </AvatarCollection>
          <div className={Styles.namesList}>
            <span className={Styles.connectedMember}>
              {membersCount > 1 ? `${membersCount} members` : fullNames[0]}
            </span>
          </div>
        </div>
      </Tooltip>
    </div>
  );
};

type TooltipContentProps = {
  members: string[];
  limit?: number;
};

const TooltipContent = ({members, limit}: TooltipContentProps) => {
  const diff = limit ? members.length - limit : 0;
  const membersToDisplay = limit ? members.slice(0, limit) : members;
  return (
    <div className={Styles.connectedMembersList}>
      {membersToDisplay.map((fullName, idx) => (
        <div key={idx} className={Styles.connectedMember}>
          <Avatar fullname={fullName} size="xs" />
          <span className={Styles.connectedMember}>{fullName}</span>
        </div>
      ))}
      {diff > 0 && (
        <span className={Styles.moreMembers}>
          +{diff} more {diff > 1 ? 'members' : 'member'}
        </span>
      )}
    </div>
  );
};

import {useCallback, useState} from 'react';
import {useForm} from 'react-hook-form';
import {CreateTeamResult, startTrial, useMutationCreateTeam} from '../../../../../../api';
import {Message} from '../../../../../../components/form';
import {ShareNetworkInvitations} from '../../../../../../components/share-network-invitations';
import {ShareNetworkContextProvider, useShareNetworkContext} from '../../../../../../contexts';
import {useTrialInUrlHelper} from '../../../../../../hooks';
import {Questionnaire} from './form-stages/Questionnaire';
import {TeamName} from './form-stages/TeamName';

type Props = {
  onFinish: (newTeamId: CreateTeamResult) => void;
  teamName?: string;
};

export type CreateTeamFormState = {
  teamName: string;
  mainUseCase: string;
  howDidYouHear: string;
  interestedInOurImplementationServices: string;
};

enum FormSteps {
  TeamName = 'TeamName',
  Questionnaire = 'Questionnaire',
}

export const ManuallyCreateTeam = ({onFinish, teamName}: Props) => {
  const [formStep, setFormStep] = useState(FormSteps.TeamName);
  const [globalError, setGlobalError] = useState<string>();
  const {refetchShareNetworkInvitations} = useShareNetworkContext();
  const setTrialStartedInUrl = useTrialInUrlHelper();

  const goToQuestionnaire = useCallback(() => setFormStep(FormSteps.Questionnaire), []);

  const {
    formState: {isSubmitting, errors},
    register,
    control,
    handleSubmit,
    trigger,
  } = useForm<CreateTeamFormState>({
    defaultValues: {
      teamName: teamName || '',
    },
  });

  const mutationCreateTeam = useMutationCreateTeam();

  const onSubmit = useCallback(
    ({teamName, mainUseCase, howDidYouHear, interestedInOurImplementationServices}: CreateTeamFormState) => {
      setGlobalError(undefined);
      mutationCreateTeam.mutate(
        {
          name: teamName,
          additional_registration_data: {
            main_use_case: mainUseCase,
            how_did_you_hear: howDidYouHear,
            interested_in_our_implementation_services: interestedInOurImplementationServices,
          },
        },
        {
          onSuccess: teamId => {
            startTrial(teamId);
            setTrialStartedInUrl('true');
            refetchShareNetworkInvitations();
            onFinish(teamId);
          },
          onError: () => setGlobalError('Ooops, something went wrong. Try again'),
        }
      );
    },
    [mutationCreateTeam, setGlobalError, setTrialStartedInUrl, refetchShareNetworkInvitations, onFinish]
  );

  return (
    <ShareNetworkContextProvider>
      <form onSubmit={handleSubmit(onSubmit)}>
        {formStep === FormSteps.TeamName && (
          <TeamName
            register={register}
            error={errors.teamName}
            onContinue={goToQuestionnaire}
            trigger={trigger}
          />
        )}
        {formStep === FormSteps.Questionnaire && (
          <Questionnaire control={control} errors={errors} isSubmitting={isSubmitting} />
        )}
      </form>
      {globalError && <Message message={globalError} hasError={true} className="pt-1" />}
      <ShareNetworkInvitations />
    </ShareNetworkContextProvider>
  );
};

import {Dialog} from '@headlessui/react';
import {useEffect} from 'react';
import {useQueryTasks} from '../../../api/tasks';
import {EnrichmentProcessing} from '../../../components/animations';
import {useImportTasks} from '../../../contexts';
import {LoadingProgressBar} from '../../animations';
import {Button} from '../../button';

const REFETCH_INTERVAL = 1_000;

type Props = {
  onClose: () => unknown;
};

export const ImportConnectionsSuccess = ({onClose}: Props) => {
  const {hasPendingTasks} = useImportTasks();

  useEffect(() => {
    if (hasPendingTasks) {
      onClose();
    }
  }, [hasPendingTasks, onClose]);

  // just for accelerate refetching interval for `useImportTasks` hook when this screen is active
  useQueryTasks({
    keepPreviousData: true,
    refetchOnMount: true,
    refetchInterval: REFETCH_INTERVAL,
    refetchIntervalInBackground: true,
  });

  return (
    <>
      <Dialog.Title as="h3" className="text-3xl font-bold text-swarm-black">
        Awesome, you're almost there!
      </Dialog.Title>
      <Dialog.Description className="my-4 font-normal">
        We’re in the process of mapping and enriching your connections. It’ll take a few minutes to build your
        network.
      </Dialog.Description>

      <div className="text-center">
        <EnrichmentProcessing className="mx-2 inline-block w-96" />
      </div>

      <LoadingProgressBar className="mb-6 mt-4" />

      <Dialog.Description className="my-4 items-center text-center font-normal">
        You can close this window. <br />
        Your connections are being added in the background.
      </Dialog.Description>

      <Button onClick={onClose} variant="tertiary" outline className="mx-auto">
        Close
      </Button>
    </>
  );
};

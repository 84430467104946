import {createContext, ReactNode, useCallback, useContext, useMemo} from 'react';
import {useOnboardingStatus} from '../api';
import {Authorized} from '../containers';
import {useCurrentUserSession} from '../hooks';
import {OnboardingPage} from '../pages';
import {OnboardingStep, OnboardingStepRule} from '../types';
import {getConfig} from '../utils';

type ContextValue = {
  currentStep?: OnboardingStep;
  onFinishStep: () => void;
};

const OnboardingContext = createContext<ContextValue>({
  onFinishStep: () => {},
});
OnboardingContext.displayName = 'SwarmOnboardingContext';

type Props = {
  children: ReactNode;
};

const {newOnboardingEnabled} = getConfig();

export const OnboardingContextProvider = ({children}: Props) => {
  const {refresh: refreshSession} = useCurrentUserSession();

  const {data: onboardingStatus, isLoading, refetch: refetchOnboardingStatus} = useOnboardingStatus();

  const refresh = useCallback(async () => {
    await refreshSession();
    refetchOnboardingStatus();
  }, [refreshSession, refetchOnboardingStatus]);

  const onboardingStepsRules: OnboardingStepRule[] = useMemo(
    () => [
      {
        condition: isLoading || !onboardingStatus,
        step: OnboardingStep.Loading,
      },
      {
        condition: Boolean(!onboardingStatus?.hasFirstLastName),
        step: OnboardingStep.FirstLastName,
      },
      {
        condition: Boolean(!onboardingStatus?.linkedinUrl && !onboardingStatus?.hasSkippedLinkedin),
        step: OnboardingStep.WorkExperience,
      },
      {
        condition: Boolean(!onboardingStatus?.hasTeam && onboardingStatus?.hasPendingTeamInvitations),
        step: OnboardingStep.TeamWithInvitations,
      },
      {
        condition: Boolean(!onboardingStatus?.hasTeam && !onboardingStatus?.hasPendingTeamInvitations),
        step: OnboardingStep.TeamWithoutInvitations,
      },
      {
        condition: Boolean(
          onboardingStatus?.hasTeam &&
            !onboardingStatus.hasSkippedCreatingSharedNetwork &&
            onboardingStatus.shouldInviteMembers &&
            !newOnboardingEnabled
        ),
        step: OnboardingStep.InviteMembersManually,
      },
    ],
    [isLoading, onboardingStatus]
  );

  const firstUnsatisfiedStep = useMemo(
    () => onboardingStepsRules.find(({condition}) => condition),
    [onboardingStepsRules]
  );

  return (
    <OnboardingContext.Provider
      value={{
        currentStep: firstUnsatisfiedStep ? firstUnsatisfiedStep.step : undefined,
        onFinishStep: refresh,
      }}
    >
      {firstUnsatisfiedStep ? (
        <Authorized bypassTeamCheck>
          <OnboardingPage />
        </Authorized>
      ) : (
        children
      )}
    </OnboardingContext.Provider>
  );
};

export const useOnboardingContext = () => useContext(OnboardingContext);

export const pluralizedConnection = (count: number) => {
  return count === 1 ? 'connection' : 'connections';
};

export const pluralizedProfile = (count: number) => {
  return count === 1 ? 'profile' : 'profiles';
};

export const pluralizedCompany = (count: number) => {
  return count === 1 ? 'company' : 'companies';
};

import {StringListFilterValue, TokenizedStringFilterValue} from '../components/filters';
import {RangeString} from './common';

export enum MyConnectionsFilterKeys {
  Scope = 'scope',
  ProfileTags = 'tags',
  ConnectedTo = 'connected_to',
  ConnectionsStrength = 'connections_strength',
  CurrentJobTitle = 'current_job_title',
  RecentJobTitle = 'recent_job_title',
  AllTimeJobTitle = 'all_time_job_title',
  Location = 'location_name',
  ConnectionsSource = 'connections_source',
  CurrentJobCompanyName = 'current_job_company_name',
  CurrentJobCompanyLocationName = 'current_job_company_location_name',
  CurrentJobCompanySize = 'current_job_company_size',
  CurrentJobCompanyIndustry = 'current_job_company_industry',
  CurrentJobCompanyId = 'current_job_company_id',
  CompanyList = 'company_list',
  OutPipeline = 'out_pipeline',
  InPipeline = 'in_pipeline',
  PipelineStatus = 'pipeline_status',
  CRMSource = 'crms',
}

export type MyConnectionsFilter = {
  [MyConnectionsFilterKeys.Scope]?: StringListFilterValue;
  [MyConnectionsFilterKeys.ProfileTags]?: StringListFilterValue;
  [MyConnectionsFilterKeys.ConnectedTo]?: StringListFilterValue;
  [MyConnectionsFilterKeys.ConnectionsStrength]?: StringListFilterValue;
  [MyConnectionsFilterKeys.CurrentJobTitle]?: StringListFilterValue;
  [MyConnectionsFilterKeys.RecentJobTitle]?: StringListFilterValue;
  [MyConnectionsFilterKeys.AllTimeJobTitle]?: StringListFilterValue;
  [MyConnectionsFilterKeys.Location]?: StringListFilterValue;
  [MyConnectionsFilterKeys.ConnectionsSource]?: StringListFilterValue;
  [MyConnectionsFilterKeys.CurrentJobCompanyName]?: StringListFilterValue;
  [MyConnectionsFilterKeys.CurrentJobCompanyLocationName]?: StringListFilterValue;
  [MyConnectionsFilterKeys.CurrentJobCompanySize]?: RangeString[];
  [MyConnectionsFilterKeys.CurrentJobCompanyIndustry]?: StringListFilterValue;
  [MyConnectionsFilterKeys.CurrentJobCompanyId]?: StringListFilterValue;
  [MyConnectionsFilterKeys.CompanyList]?: TokenizedStringFilterValue;
  [MyConnectionsFilterKeys.OutPipeline]?: StringListFilterValue;
  [MyConnectionsFilterKeys.InPipeline]?: StringListFilterValue;
  [MyConnectionsFilterKeys.PipelineStatus]?: StringListFilterValue;
  [MyConnectionsFilterKeys.CRMSource]?: StringListFilterValue;
};

export type MyConnectionTableFilter = {
  filters: MyConnectionsFilter;
  filtersWithoutDefaults: MyConnectionsFilter;
  hasFiltersSet: boolean;
  clearFilter: (filterName: MyConnectionsFilterKeys) => void;
  clearFilters: () => void;
  setFilter: <Key extends MyConnectionsFilterKeys>(
    filterName: Key,
    filterValue: MyConnectionsFilter[Key]
  ) => void;
  getFilterValue: <Key extends MyConnectionsFilterKeys>(filterName: Key) => MyConnectionsFilter[Key];
};

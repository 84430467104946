import {Dialog, Transition} from '@headlessui/react';
import cs from 'classnames';
import {X} from 'phosphor-react';
import {Fragment, ReactNode} from 'react';
import {Button} from '../button';
import Styles from './Modal.module.scss';

type Props = {
  visible: boolean;
  onClose?: () => void;
  children: ReactNode;
  className?: string;
  closeButtonVisible?: boolean;
  closeOnOutsideClick?: boolean;
};
const voidFnc = () => {};
export const Modal = ({
  visible,
  onClose = voidFnc,
  children,
  className,
  closeButtonVisible,
  closeOnOutsideClick,
}: Props) => {
  return (
    <Transition appear show={visible} as={Fragment}>
      <Dialog as="div" className={Styles.wrapper} onClose={closeOnOutsideClick ? onClose : voidFnc}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className={Styles.curtain} />
        </Transition.Child>

        <div className={Styles.scrollArea}>
          <div className={Styles.container}>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className={cs(Styles.panel, className)}>
                {children}
                {closeButtonVisible && (
                  <Button
                    onClick={onClose}
                    className={Styles.closeButton}
                    variant="tertiary"
                    iconOnly
                    icon={<X />}
                  />
                )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

import cs from 'classnames';
import {FiBook as BookIcon} from 'react-icons/fi';
import {PiMonitorPlayBold as MonitorIcon} from 'react-icons/pi';
import {useLayoutContext} from '../../../contexts';
import {segmentTrack} from '../../../utils';
import {TruncateWithTooltip} from '../../truncate-with-tooltip';
import Styles from './Links.module.scss';

const links = [
  {
    url: 'https://help.theswarm.com',
    icon: <BookIcon size={20} />,
    label: 'Help Center',
  },
  {
    url: 'https://www.theswarm.com/plays',
    icon: <MonitorIcon size={20} />,
    label: 'Swarm Plays',
  },
];

export const Links = () => {
  const {sidebarCollapsed} = useLayoutContext();

  const handleTracking = (label: string) => {
    segmentTrack('Button Clicked', {
      label: label.toLowerCase(),
      location: 'sidebar',
    });
  };

  return (
    <div className={Styles.linksWrapper}>
      {links.map(({url, icon, label}, index) => (
        <TruncateWithTooltip content={label} placement="right-center" key={index}>
          <a
            href={url}
            className={cs(Styles.link, {[Styles.linkIconOnly]: sidebarCollapsed})}
            data-intercom-target={label}
            target="_blank"
            rel="noreferrer"
            onClick={() => handleTracking(label)}
          >
            <span className={Styles.icon}>{icon}</span>
            {sidebarCollapsed ? null : label}
          </a>
        </TruncateWithTooltip>
      ))}
    </div>
  );
};
